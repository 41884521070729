.accountSwitcher{
    position: relative;
    height: 220px;
    border-radius: 0;
    overflow: hidden;
    width: 100%;
}

.accountSwitcher,
.accountSwitcher *{
    -webkit-tap-highlight-color: transparent;
}

.accountSwitcherButtons{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0.7em;
    margin: auto 0;
    pointer-events: none;
}

.accountSwitcherButtons button{
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 50%;
    color: #597373;
    background: #b4d8d8;
    box-shadow: 0;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
    outline: none;
    pointer-events: auto;
}

.accountSwitcherButtons button svg{
    fill: none;
    width: 40px;
    height: 40px;
}

.accountSwitcherButtons button:active{
    transform: scale(0.96);
}

.accountSwitcherButtons button:disabled{
    background-color: #6ca1a1;
    box-shadow: -1px 0px 3px rgba(0, 0, 0, 0.3);
    color: #577b7b;
    pointer-events: none;
}

.accountSwitcher .handle {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    border-radius: 0;
    display: flex;
    will-change: transform;
}

.accountSwitcher.animate-switch .handle{
    transition: transform 0.2s ease-out;
}

.accountSwitcher .handle .card {
    width: 100vw;
    flex-shrink: 0;
    position: relative;
    height: 100%;
    padding: 0 4vw;
}

.accountSwitcher .handle .cardContent {
    position: relative;
    padding: 2rem 2.4rem;
    height: 100%;
    border-radius: 15px;
    box-shadow: 3px 3px 23px rgba(0, 0, 0, 0.2);
    background: rgb(238,238,238);
    background: linear-gradient(172deg, rgba(238,238,238,1) 0%, rgba(203,201,201,1) 44%, rgba(170,170,170,1) 68%);
    background: rgb(45,160,161);
    background: linear-gradient(137deg, rgba(45,160,161,1) 0%, rgba(100,178,179,1) 44%, rgba(63,151,152,1) 68%);
    
    overflow: hidden;
    transform-origin: right;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.2s ease-out;
}

.accountSwitcher .cardContent:before{
    content: '';
    position: absolute;
    bottom: -20%;
    right: -20%;
    border: 20px solid #54a2a3;
    width: 120px;
    height: 120px;
    border-radius: 50%;
}

.accountSwitcher .handle .card:not(.active) .cardContent{
    transform: scale(0.9);
}

.accountSwitcher .handle .card:not(.active) .cardContent > *{
    opacity: 0;
}

.accountSwitcher .handle .card.active .cardContent {
    background: rgb(45,160,161);
    background: linear-gradient(137deg, rgba(45,160,161,1) 0%, rgba(100,178,179,1) 44%, rgba(63,151,152,1) 68%);
}

.accountSwitcher .accountName{
    margin-bottom: 0.2rem;
    font-size: 1.4rem;
    font-weight: 100;
    letter-spacing: 0.03em;
    color: #d1feff;
    font-weight: bold;
    text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.accountSwitcher .accountInterest{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: #0d2d2d;
    color: #2e5c5d;
    letter-spacing: 0.03em;
    margin-bottom: 1rem;
}

.accountBalanceInfo.total .for-available,
.accountBalanceInfo.available .for-total{
    display: none;
}

.accountSwitcher .accountBalance{
    display: block;
    width: 100%;
    font-size: 1.5rem;
    color: #d1feff;
    padding: 0 0.3rem;
    letter-spacing: 0.02em;
    text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.accountBalance + .accountBalance{
    margin-top: 0.6rem;
    margin-bottom: -0.3rem;
}

.accountSwitcher .accountBalance span{
    color: #2e5c5d;
    display: inline-block;
    margin-left: 0.5rem;
    transform: translateY(-0.2rem);
    font-size: 0.93rem;
    text-shadow: none;
}

.accountSwitcher .accountBalance small{
    margin-bottom: 0.85rem;
    display: block;
    color: #a6f8f9;
    font-weight: bold;
    font-size: 1.03rem;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    text-shadow: none;
}

.accountBalanceTypeSwitcher{
    display: block;
    margin-top: 1.2rem;
    font-size: 0.68rem;
    line-height: 1.5em;
    letter-spacing: 0.04em;
    text-shadow: none;
}

.accountBalanceTypeSwitcher button{
    background: transparent;
    border: none;
}

@media all and (max-width: 400px){
    .accountSwitcher {
        height: 175px;
    }

    .accountSwitcher .handle .cardContent {
        padding: 1.8rem 2.1rem;
    }

    .accountSwitcher .cardContent:before{
        border-width: 12px;
        right: -30%;
        bottom: -30%;
    }

    .accountSwitcher .accountName {
        font-size: 1em;
    }

    .accountSwitcher .accountInterest {
        font-size: 0.8rem;
    }

    .accountSwitcher .accountBalance {
        font-size: 1.1rem;
    }
    
    .accountSwitcher .accountBalance small{
        font-size: 0.8rem;
        margin-bottom: 0.7rem;
    }
    
    .accountSwitcher .accountBalance span{
        font-size: 0.7rem;
    }
}