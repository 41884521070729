.recepient-type-picker{
    display: flex;
    justify-content: center;
    margin-top: -1em;
    margin-bottom: 1.6em;
}

.recepient-types{
    width: 90vw;
    max-width: 330px;
    margin: 0 auto;
    display: inline-flex;
    align-items: center;
    border-radius: 50px;
    overflow: hidden;
    position: relative;
}

.recepient-types:after{
    content: '';
    position: absolute;
    left: 50%;
    top: 25%;
    bottom: 25%;
    width: 1px;
    background: #6b6b6b;
}

.recepient-type-choice{
    margin-top: 0 !important;
    padding: 0 1.6em;
    height: 53px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    border: none;
    background: #313030;
    width: 50%;
    flex-shrink: 0;
    text-align: center;
    color: #b2b1b1;
    font-weight: bold;
}

.recepient-type-choice span{
    margin-bottom: 0.2em;
}

.recepient-type-choice.selected{
    color: #9dd5d5;
}

@media all and (max-width: 400px){
    .recepient-type-picker {
        margin-top: -0.5em;
        margin-bottom: 1em;
    }

    .recepient-type-choice {
        font-size: 0.8em;
        height: 45px;
    }
}