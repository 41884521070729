.transactionsFragment{
    /* padding: 2.1em 1.3em; */
    padding: 0.5em 1em;
}

.transactionsFragment .transactionItem:first-child{
    border-top: none;
    border-bottom: none;
}

#filterFab{
    border-radius: 8px;
    width: auto;
    /* width: 136px; */
    height: auto;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.8em 1em;
    transition: transform 0.2s ease-out, opacity 0.15s ease-out;
}

#filterFab.hidden{
    transform: translateX(-50%) scaleX(0.6);
}

#filterFab span{
    margin-left: 0.5em;
    font-size: 1.1em;
    text-transform: uppercase;
    margin-right: 0.2em;
    letter-spacing: 0.12em;
    font-weight: 300;
}

@media all and (max-width: 400px){
    #filterFab{
        border-radius: 8px;
        padding: 0.5em 0.7em;
    }

    #filterFab svg{
        width: 23px;
        height: 23px;
    }
    
    #filterFab span{
        font-size: 1.15em;
    }
}