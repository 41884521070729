#newVersionBanner{
    padding: 0.4em 1.1em;
    padding-right: 0.8em;
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    z-index: 99;
    background: #cee7e8;
    color: #4f696a;
}

#newVersionBanner #updateIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background: #a6d0d1;
    margin-right: 0.8em;
    color: #445858;
}

#newVersionBanner strong{
    font-weight: normal;
    display: block;
    margin-bottom: 0.15em;
    font-size: 1.2em;
    color: #223232;
}

#newVersionBanner button{
    color: #0e3030;
}

#unreadNotificationButton span{
    background: #00f0f3;
    border: 3px solid #383837;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    top: 3px;
    right:0px;
}

.savingsSummaryPlaceholder .image-carousel{
    position: relative;
    border-radius: 0;
    overflow-x: hidden;
    width: 100%;
}

.savingsSummaryPlaceholder .image-carousel .handle {
    top: 0;
    left: 0;
    cursor: pointer;
    border-radius: 0;
}

.savingsSummaryPlaceholder .image-carousel .indicators {
    margin-top: 0.3em;
    margin-bottom: 0.5em;
    left: 50%;
    transform: translateX(-50%);
    display: inline-flex;
    align-items: center;
    z-index: 1;
}

.savingsSummaryPlaceholder .image-carousel .indicators span{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #636363;
    margin-right: 0.8em;
}

.savingsSummaryPlaceholder .image-carousel .indicators span.active{
    background: #aaa;
}

.savingsSummaryPlaceholder .image-carousel .handle .slide {
    float: left;
    position: relative;
    width: 100vw;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 0.7em;
    padding-top: 1em;
}

.savingsSummaryPlaceholder .image-carousel .handle .slide > div{
    display: flex;
    align-items: center;
    height: 230px;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 -3px 13px rgba(0, 0, 0, 0.3);
}

.savingsSummaryPlaceholder .image-carousel .handle .slide img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.savingsSummaryPlaceholder .image-carousel .handle .slide-text{
    position: relative;
    z-index: 1;
    padding: 0 1.8em;
}

.savingsSummaryPlaceholder .image-carousel .handle .slide-text > span,
.savingsSummaryPlaceholder .image-carousel .handle .slide-text .finlink-btn{
    color: #000 !important;
}

.savingsSummaryPlaceholder .image-carousel .handle .slide-text .finlink-btn{
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.06em;
    padding-left: 25px;
    padding-right: 25px;
    border-color: #51705e;
    border-color: #5b8d76;
    border-width: 1px;
}

.savingsSummaryPlaceholder .image-carousel .handle .slide-text > span{
    font-size: 1.6em;
    letter-spacing: 0;
    line-height: 1.4em;
    margin-bottom: 0.8em;
}

.savingsSummaryPlaceholder .image-carousel .handle .slide > div:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -2px;
    background:rgba(190, 255, 255, 0.72);
}

.savingsSummaryPlaceholder .cta{
    left: 50%;
    transform: translateX(-50%);
    bottom: 1.5em;
    margin: auto;
    margin-bottom: -1.5em;
    display: inline-block;
}

.savingsSummaryPlaceholder .cta:before{
    content: '';
    position: absolute;
    top: -14%;
    bottom: 0;
    left: -2.5%;
    right: -2.5%;
    border-radius: 25px;
    margin: auto;
    background: #383837;
    z-index: -1;
}

.savingsSummaryPlaceholder .cta::after{
    left: auto;
    right: -50px !important;
}

.savingsSummary{
    background: #474747;
    padding: 1.7em 1.4em;
    padding: 1em 1.4em;
    position: relative;
    margin-bottom: 1em;
}

.savingsSummary:before{
    content: '';
    position: absolute;
    margin-left: 2.5px;
    margin-top: 2.5px;
    background: #474747;
    width: 195px;
    height: 195px;
    border-radius: 50%;
    border: 33px solid #3f3f3f;
    box-sizing: border-box;
}

.chartist-bg-green{
    fill: #57c495;
}
.chartist-bg-dark{
    fill: #58595b;
}
.chartist-bg-dark-grey{
    fill: #707070;
}
.chartist-bg-grey{
    fill: #c8c8c8;
}

.savingsSummaryKey{
    position: absolute;
    top: 0;
    left: 258px;
    right: 0;
    bottom: 4.4em;
    bottom: 0;
    margin: auto 0;
    flex : 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.savingsSummaryKeyItem{
    padding: 0.8em 0 0.8em 1.4em;
    letter-spacing: 0.09em;
    font-size: 0.8em;
    color: #ddd;
    position: relative;
}

.savingsSummaryKeyItem:before{
    content: '';
    position: absolute;
    left: -0.35em;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 7px;
    height: 7px;
    border-radius: 5px;
}

.savingsSummaryKeyItem:nth-child(1):before{
    background: #57c495;
}
.savingsSummaryKeyItem:nth-child(2):before{
    background: #58595b;
}
.savingsSummaryKeyItem:nth-child(3):before{
    background: #707070;
    background: #c8c8c8;
}
.savingsSummaryKeyItem:nth-child(4):before{
    background: #c8c8c8;
}

.savingsSummaryBalance{
    padding-top: 1em;
    text-align: center;
    display: none;
}

.savingsSummaryBalance small{
    color: #ddd;
    display: block;
    letter-spacing: 0.14em;
    font-size: 0.98em;
    margin-bottom: 0.4em;
}

.savingsSummaryBalance strong{
    color: #61bf95;
    letter-spacing: 0.03em;
    font-size: 1.5em;
    display: inline-flex;
    align-items: center;
}

.savingsSummaryBalance strong span{
    font-size: 0.8em;
    display: inline-block;
    margin-right: 0.3em;
    letter-spacing: 0.1em;
}

.textualSummary,
.accountsSummary{
    padding: 0.7rem 1.3em;
    padding-bottom: 0.5em;
}

.accountsSummary{
    padding-top: 0.3rem;
}

.sectionTitle{
    font-size: 1.14em;
    font-weight: bold;
    color: #b7b7b7;
    letter-spacing: 0.035em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.1em;
}

.homeScene .sectionTitle{
    font-size: 1.05rem;
}

.sectionTitle button.flat{
    color: #4ee6a1;
    letter-spacing: 0.1em !important;
    margin-right: -0.3em;
}

.textualSummaryItem{
    padding: 0.9rem 0.75rem;
    border-radius: 4px;
    border: 1.5px solid #00f6f9;
    border: 1.5px solid #77afb0;
    margin-bottom: 0.5em;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.95rem; 
    letter-spacing: 0.03em;
}

.textualSummaryItem strong span{
    padding-left: 0.1em;
    letter-spacing: 0.04em;
    font-size: 1.1em;
}

.textualSummaryItem.negative strong span{
    color: red;
}

.accountsSummary .sectionTitle{
    margin-bottom: 0.4em;
}

.accountFetchLoading{
    color: #999;
}

.accountSummaryItem{
    padding: 0.75rem;
    border-radius: 4px;
    background: #474747;
    margin-bottom: 0.5em;
    color: #fff;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 1.1em;
    font-size: 0.98rem;
    font-weight: bold;
    letter-spacing: 0.03em;
}

.accountSummaryItem svg{
    fill: #b6b6b6;
    width: 22px;
    height: 22px;
    margin-top: -0.1em;
    margin-right: 0.7em;
}

.accountSummaryItem div{
    flex: 1;
}

.accountSummaryItem div span{
    color: #dfdfdf;
    display: block;
    font-size: 0.9em;
    font-weight: normal;
}

.accountSummaryItem div small{
    display: block;
    margin-top: 0.3em;
    color: #dfdede;
    font-size: 0.85em;
    font-weight: 300;
    text-transform: capitalize;
}

.accountSummaryItem strong{
    color: #d3d3d3;
    font-weight: normal;
    font-size: 0.95em;
    letter-spacing: 0.04em;
    text-transform: uppercase;
}

.accountSummaryItem strong span{
    letter-spacing: 0.04em;
    // font-size: 1.1em;
}

.accountSummaryItem + button{
    color: #2db87e;
    letter-spacing: 0.12em !important;
    display: block;
    margin: auto;
    left: 0;
    right: 0;
}

@media all and (max-width: 400px){
    .savingsSummaryPlaceholder .image-carousel .handle .slide > div{
        height: 180px;
        position: relative;
    }

    .savingsSummaryPlaceholder .image-carousel .handle .slide-text{
        padding: 0 1.4rem;
    }

    .savingsSummaryPlaceholder .image-carousel .handle .slide-text > span {
        font-size: 1.3rem;
    }

    .savingsSummaryPlaceholder .image-carousel .handle .slide-text .finlink-btn {
        font-size: 12px;
    }
}

@media all and (max-width: 330px){
    .savingsSummaryPlaceholder .image-carousel .handle .slide > div{
        height: 170px;
    }

    .savingsSummaryPlaceholder .image-carousel .handle .slide-text {
        padding: 0 1.5em;
    }

    .savingsSummaryPlaceholder .image-carousel .handle .slide-text > span {
        font-size: 1.3em;
        margin-bottom: 0.7em;
    }

    .savingsSummaryPlaceholder .image-carousel .handle .slide-text .finlink-btn {
        font-size: 11px;
        letter-spacing: 0.06em;
        padding: 6px 12px;
    }
    
    .savingsSummary:before {
        left: 0;
        right: 0;
        margin: auto;
        margin-top: 2.5px;
    }

    .ct-chart{
        display: flex;
        justify-content: center;
    }

    .savingsSummaryKey {
        position: relative;
        top: auto;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto 0;
        margin-top: 0.5em;
        flex: 1 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }

    .savingsSummaryKeyItem {
        padding: 0.8em 0 0.8em 1.4em;
        letter-spacing: 0.09em;
        font-size: 0.8em;
        color: #ddd;
        position: relative;
    }

    .savingsSummaryKeyItem:nth-child(odd) {
        margin-right: 1.8em;
    }
    
    .savingsSummaryPlaceholder .cta .finlink-btn {
        text-transform: none;
        padding: 0.6em 1.5em;
    }

    .sectionTitle {
        font-size: 0.85em;
        font-weight: bold;
        color: #b7b7b7;
        letter-spacing: 0.035em;
    }

    .sectionTitle button{
        text-transform: none;
    }

    .textualSummary {
        padding-top: 0.8em;
    }
    
    .textualSummaryItem {
        padding: 0.8em 0.65em;
        font-size: 0.95em;
        font-weight: normal;
    }

    .accountsSummary{
        padding-top: 0.45em;
    }
    
    .accountSummaryItem{
        padding: 0.8em 0.65em;
        border-radius: 2px;
        margin-bottom: 0.3em;
    }

    .accountSummaryItem svg{
        display: none;
    }

    .accountSummaryItem div span {
        font-size: 0.9em;
    }

    .accountSummaryItem div small {
        margin-top: 0.18em;
        font-size: 0.7em;
        font-weight: 300;
    }

    .accountSummaryItem strong {
        font-size: 0.83em;
        letter-spacing: 0.04em;
    }
}