#RequestLoan .finlink-modal-content{
    height: calc(100vh - 60px);
    padding: 0;
    overflow: hidden;
}

#RequestLoan #RequestLoanContent{
    height: 100%;
}

#RequestLoan .SaveFab{
    position: fixed;
    z-index: 90;
    bottom: 20px;
    right: 20px;
    top: auto;
}

#RequestLoan .SaveFab svg{
    fill: none;
}

#RequestLoan #RequestLoanContent .RequestLoanSection{
    padding: 1.2em;
    padding-top: 2.5em;
}

#RequestLoan .loan-request-section-title{
    font-size: 1.86em;
    line-height: 1.5;
    letter-spacing: 0.01rem;
    font-weight: normal;
    margin-bottom: 2.5rem;
    color: #e8e8e8;
}

#RequestLoan .loan-request-section-title small{
    font-size: 0.7em;
    color: #9d9d9d;
    letter-spacing: 0.07em;
}

#loanGuarantorsSection .loan-request-section-title small{
    letter-spacing: 0.01em;
    display: block;
    margin-top: 0.3em;
    line-height: 1.7;
    color: #acacac;
    font-size: 0.7em;
}

#RequestLoan .finlink-form-control:not(.focused).has-errors {
    border-bottom-color: #eda1a1;
}

#RequestLoan .finlink-form-control.has-errors .bar:before, 
#RequestLoan .finlink-form-control.has-errors .bar:after {
    background: #fd9c9c;
}

#RequestLoan .finlink-form-control.focused label,
#RequestLoan .finlink-form-control.filled label, 
#RequestLoan .finlink-form-control.has-prepend label, 
#RequestLoan .finlink-form-control input[placeholder] ~ label {
    top: -24px;
}

#loanAmountSection .finlink-form-control input{
    background: transparent !important;
    font-size: 2.6em;
    letter-spacing: 0.02em;
}

#loanAmountSection .finlink-form-control input::placeholder{
    font-size: 0.7em;
}

#loanAmountSection .finlink-form-control input:focus::placeholder{
    opacity: 0;
}

#RequestLoan .finlink-radio-button-group-wrapper{
    margin-top: -10px;
}

#RequestLoan .finlink-radio-button-group > label {
    margin-bottom: 8px;
}

#RequestLoan .finlink-form-control-wrapper .form-control-error {
    font-size: 1rem;
    color: #fba4a4;
    letter-spacing: 0.01em;
    font-weight: 100;
    font-family: verdana;
}

#loanGuarantorsSection .ListItem{
    margin-top: -0.7rem;
    border: 2px solid #5d5d5d;
    background: #4e4e4e;
}

#loanGuarantorsSection .ListItem + .ListItem{
    margin-top: 1.3em;
    margin-bottom: -1em;
}

#loanGuarantorsSection > .OutlineButton{
    margin-top: 1.5em;
    color: #aad5d2 !important;
    border-color: #828d8c !important;
}

#RequestLoan #AddLoanGuarantor .loan-request-section-title {
    margin-top: -0.6rem;
    margin-bottom: 1.1rem;
}

#AddLoanGuarantor .finlink-form-control-wrapper{
    margin-top: 0.8em;
    margin-bottom: 2em !important;
}

#AddLoanGuarantor .finlink-form-control input{
    background: transparent !important;
    font-size: 2em;
    letter-spacing: 0.02em;
}

#AddLoanGuarantor .finlink-form-control .prepend{
    font-size: 2em;
}

#AddLoanGuarantor .finlink-form-control.has-prepend.filled {
    background: transparent;
    padding-left: 0
}

#AddLoanGuarantor .finlink-form-control input::placeholder{
    opacity: 0.5;
}

#AddLoanGuarantor .RequestLoanSection > .finlink-btn.primary{
    font-size: 1.2rem;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
    border-radius: 0;
}

.MoreGuarantorsAlert p{
    text-align: center;
    font-size: 1.3em;
    line-height: 1.6em;
    color: #fff;
    margin-bottom: 1em;
    letter-spacing: 0.03em;
}

.MoreGuarantorsAlert .finlink-btn{
    margin: auto;
    font-size: 1.2em;
    text-transform: none;
    letter-spacing: 0.03em;
    padding: 0.8em 1.2em;
    border-radius: 5px;
}

.MoreGuarantorsAlert .finlink-btn.primary{
    border: 2px solid #6a7878;
    background: #5c6161;
    color: #abfeff;
    text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.14);
}

.MoreGuarantorsAlert .finlink-btn:not(.primary){
    color: #d2d5d5;
}

.MoreGuarantorsAlert .finlink-btn:not(.primary) span:after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: -2px;
    right: -2px;
    height: 1px;
    background: currentColor;
    opacity: 0.7;
}

#LoanRequestRepaymentPlan .finlink-radio-button-group-wrapper{
    margin-top: -15px;
    margin-bottom: 2rem;
}

#LoanRequestRepaymentPlan .finlink-radio-button {
    margin-right: 1rem;
}

#LoanRequestRepaymentPlan .finlink-radio-button-group > label {
    text-transform: none;
    font-size: 1.4rem;
    line-height: 1.65em;
    letter-spacing: 0.03em;
    margin-bottom: 1rem;
}

#installmentCount > div{
    position: relative;
    background: #4e4d4d;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 1rem 0;
    padding-top: 0.5rem;
}

#installmentCount > div:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.07);
    z-index: -1;
}

#installmentCount > *{
    position: relative;
    z-index: 1;
}

#installmentCount > h3{
    max-width: 90%;
    text-align: center;
    color: #d0d0d0;
    font-size: 1.6em;
    line-height: 1.6;
    letter-spacing: 0.01rem;
    font-weight: normal;
    margin: 0 auto;
    margin-bottom: 1.1rem;
}

#installmentCount .RangeInput{
    margin-top: 1.3rem;
    margin-bottom: 0.4rem;
}

#installmentCount .RangeInput [data-reach-slider-handle][aria-orientation="horizontal"]{
    background: #aaa;
}

#installmentCount .RangeInput [data-reach-slider-track][data-orientation="horizontal"] {
    background: rgba(0, 0, 0, 0.1);
}

#installmentCount #installmentCountNumber{
    margin-right: 1rem;
    margin-top: 0.7rem;
}

#installmentCount #installmentCountNumber h3{
    text-align: center;
    color: #ccc;
    // color: #b2f3f3;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    line-height: 1.65em;
    letter-spacing: 0.01em;
    font-size: 1.85rem;
    margin: 0 3rem;
}

#installmentCount #installmentCountNumber button{
    font-size: 1.85rem;
    color: #888;
    // color: rgba(178, 243, 243, 0.52);
    width: 50px;
    height: 36px;
    padding: 0;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
}

#installmentCount #installmentCountNumber button:active{
    transform: scale(0.8);
}

#installmentCount #installmentCountNumber button:disabled{
    opacity: 0.25;
    pointer-events: none;
}

#installmentCount #installmentCountNumber button svg{
    height: 36px;
    width: 36px;
}

#LoanRequestRepaymentPlan .finlink-radio-button-group.has-errors > label,
#LoanRequestRepaymentPlan .finlink-radio-button-group-wrapper .form-control-error{
    color: #fba4a4;
}

@media all and (max-width: 330px){
    #RequestLoan #RequestLoanContent .RequestLoanSection{
        padding-top: 1.8em;
    }

    #RequestLoan .loan-request-section-title {
        font-size: 1.4em;
        margin-bottom: 1.8rem;
    }

    #LoanRequestRepaymentPlan .finlink-radio-button-group-wrapper {
        margin-bottom: 1rem;
    }

    #LoanRequestRepaymentPlan .finlink-radio-button-group > label {
        font-size: 1.15rem;
        line-height: 1.4em;
        letter-spacing: 0.01em;
        margin-bottom: 0.7rem;
    }

    #LoanRequestRepaymentPlan .finlink-radio-button-group-wrapper:last-of-type .finlink-radio-button label{
        font-size: 1em;
        padding: 0.3em 0.6em;
        padding-right: 0.7em;
    }

    #loanAmountSection .finlink-form-control input{
        font-size: 2.2em;
    }

    #AddLoanGuarantor .finlink-form-control .prepend,
    #AddLoanGuarantor .finlink-form-control input {
        font-size: 1.45em;
    }

    #AddLoanGuarantor .RequestLoanSection > .finlink-btn.primary {
        font-size: 1rem;
    }

    .OutlineButton span svg{
        height: 20px;
        width: 20px;
    }

    #installmentCount > h3{
        font-size: 1.2rem;
        line-height: 1.65em;
        margin-bottom: 0.8rem;
    }

    #installmentCount > div {
        background: #4e4d4d;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.14);
        border-radius: 6px;
        padding: 1rem 0;
        padding-top: 0.4rem;
    }

    #installmentCount #installmentCountNumber {
        margin-right: 0.8rem;
        margin-top: 0.5rem;
    }

    #installmentCount #installmentCountNumber h3 {
        letter-spacing: 0.01em;
        font-size: 1.3rem;
        margin: 0 1.7rem;
    }

    #installmentCount #installmentCountNumber button {
        width: 45px;
        height: 26px;
    }

    #installmentCount #installmentCountNumber button svg {
        height: 26px;
        width: 26px;
    }
}