.finlink-radio-button-group-wrapper{
  margin-bottom:45px;
}

.finlink-radio-button-group-wrapper .form-control-errors{
  // margin-top: 0.5em;
}

.finlink-radio-button-group-wrapper .form-control-error{
  font-size: 0.8em;
  color: #ca7777;
  text-transform: lowercase;
  line-height: 1.3em;
  list-style-type: disc;
}

.finlink-radio-button-group{
  position:relative; 
}

.finlink-radio-button-group > div{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.finlink-radio-button-group.align-center > div{
  justify-content: center;
}

.finlink-radio-button-group .finlink-radio-button{
  margin-right: 0.5em;
  margin-bottom: 1em; 
}

.finlink-radio-button-group > label {
  color: #999;
  font-size: 18px;
  font-size: 14px;
  color: #ccc;
  margin-bottom: 10px;
  margin-bottom: 14px;
  
  display: block;
  letter-spacing: 1px;
  font-weight: normal;
  transition: 0.2s ease all;
  text-transform: uppercase;
  outline: none;
}

.finlink-radio-button-group.has-errors > label {
  color: #ca7777;
}

@media (max-width: 400px){
  .finlink-radio-button-group-wrapper{
    margin-bottom:20px;
  }
}