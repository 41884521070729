.finlink-form-control-wrapper{
  margin-bottom:50px;
}

.finlink-form-control-wrapper.solo{
  margin-bottom: 0 !important;
}

.finlink-form-control-wrapper .form-control-errors{
  margin-top: 0.5em;
}

.finlink-form-control-wrapper .form-control-error{
  font-size: 0.8em;
  color: #ca7777;
  text-transform: lowercase;
  line-height: 1.3em;
  list-style-type: disc;
}

.finlink-form-control-wrapper .form-control-hint:before{
  content: 'HINT:';
  font-weight: bold;
  margin-right: 0.3em;
  letter-spacing: 0.06em;
  color: #bbb;
}

.finlink-form-control-wrapper .form-control-hint{
  display: block;
  margin-top: 0.5em;
  color: #999;
  line-height: 1.3em;
  letter-spacing: 0.03em
}

.finlink-form-control{
  position:relative;
  border-bottom: 1px solid transparent;
}

.finlink-form-control.disabled{
    pointer-events: none;
    opacity: 0.6;
}

.finlink-form-control:not(.focused){
  border-bottom-color: #757575;
}

.finlink-form-control:not(.focused).has-errors{
  border-bottom-color: #ca7777;
}

.finlink-form-control.has-prepend{
  display: flex;
  align-items: center;
}

.finlink-form-control.has-prepend.filled{
  background: #474747;
  padding-left: 7px;
}

.finlink-form-control.has-prepend.filled.has-errors{
  background: #625a5a;
}

.finlink-form-control .prepend{
    position: relative;
    align-items: center;
    color: #3aa678;
    font-size: 1em;
    padding: 0.1em 0;
    padding-right: 0.5em;
    margin-right: 0.5em;
    border-right: 1px solid #777;
    // height: 20px;
    line-height: 0.8em;
}

.finlink-form-control input {
  font-size: 1.1em;
  padding: 10px 10px 10px 0;
  display: block;
  width: 100%;
  border: none;
  background: transparent;
  color: #aaa;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.finlink-form-control.text-center input{
  text-align: center;
}

.finlink-form-control:not(.has-prepend).filled input {
  background: #474747;
  padding: 10px;
}

.finlink-form-control:not(.has-prepend).filled.has-errors input {
  background: #625a5a;
}

.finlink-form-control input::selection{
  background: #e0fff2;
  color: #4d4d4d;
}

.finlink-form-control input:focus {
  outline: none;
}

.finlink-form-control label {
  color: #ccc;
  font-size: 18px;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: normal;
  // margin-bottom: 0.3rem;
  display: block;
  // position: absolute;
  // pointer-events: none;
  // left: 0;
  // right: 0;
  // top: 10px;
  transition: 0.2s ease all;
  text-transform: uppercase;
  outline: none;
}

/* active state */
.finlink-form-control.focused label,
.finlink-form-control.filled label,
.finlink-form-control.has-prepend label,
.finlink-form-control input[placeholder] ~ label {
  // top: -18px;
  font-size: 14px;
  color: #ccc;
}

.finlink-form-control.has-errors.focused label,
.finlink-form-control.has-errors.filled label,
.finlink-form-control.has-errors.has-prepend label,
.finlink-form-control.has-errors input[placeholder] ~ label {
  color: #ca7777 !important;
}

.finlink-form-control .bar {
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
}

.finlink-form-control.has-prepend .bar {
  bottom: -1.5px;
}

.finlink-form-control .bar:before,
.finlink-form-control .bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #ccc;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.finlink-form-control.has-errors .bar:before,
.finlink-form-control.has-errors .bar:after {
  background: #ca7777;
}

.finlink-form-control .bar:before {
  left: 50%;
}
.finlink-form-control .bar:after {
  right: 50%;
}

.finlink-form-control input:focus ~ .bar:before,
.finlink-form-control input:focus ~ .bar:after {
  width: 50%;
}

@media (max-width: 400px){
  .finlink-form-control-wrapper{
    margin-bottom: 38px;
  }
  .finlink-form-control label {
    font-size: 14px;
    top: 14px;
  }

  .finlink-form-control.focused label,
  .finlink-form-control.filled label,
  .finlink-form-control.has-prepend label,
  .finlink-form-control input[placeholder] ~ label {
    top: -14px;
    font-size: 11px;
    color: #ccc;
  }
}

@media (max-width: 330px){
  // .finlink-form-control label {
    
  // }

  // .finlink-form-control.focused label,
  // .finlink-form-control.filled label,
  // .finlink-form-control.has-prepend label,
  // .finlink-form-control input[placeholder] ~ label {
  //   top: -18px;
  //   font-size: 14px;
  //   color: #ccc;
  // }
}