.mainScene{
    background: #393837;
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.mainFragment{
    position: fixed;
    top: 59px;
    right: 0;
    left: 0;
    bottom: 0;
    overflow-y: auto;
}

.homeScene{
    padding-bottom: 60px;
    top: 50px;
}

.mainFragment:not(.homeScene),
.mainScene:not(.at-home) .ot-header{
    z-index: 20;
}

.mainFragment:not(.homeScene){
    background-color: #393837;
}

@media all and (max-width: 400px){
    .mainFragment{
        top: 52px;
    }
}

@media all and (max-width: 330px){
    .mainFragment{
        top: 47px;
    }
}