.accountChoiceItem{
    width: 100%;
    padding: 1em 0.85em;
    padding: 0.75em 0.85em;
    padding-left: 1.1em;
    border-radius: 4px;
    background: #474747;
    color: #cecece;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2em;
    letter-spacing: 0.04em;
    user-select: none;
    margin: 1em 0;
    border: none;
}

.accountChoiceItem:active{
    transform: scale(0.99);
}

.accountChoiceItem svg{
    width: 24px;
    height: 24px;
    fill: #636262;
    display: block !important;
}

.accountChoiceItem.selected svg{
    fill: #2fb980;
}

.accountChoiceItem .currency{
    font-size: 0.75em;
    margin-top: 0.1em;
}

.accountChoiceItem .amount{
    font-size: 0.85em;
}

@media all and (max-width: 400px){
    .accountChoiceItem{
        padding: 0.8em 0.9em;
        border-radius: 2px;
        margin-bottom: 0.3em;
        font-size: 1.1em;
    }
}

@media (max-width: 330px) {
    .accountChoiceItem {
        margin-bottom: 0.7em;
    }

    .TransferModal .finlink-btn.rounded {
        padding: 10px 30px;
    }
}