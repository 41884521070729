.RangeInput{
    position: relative;
    padding: 0 22px;
}

.RangeInput [data-reach-slider-input][data-orientation="horizontal"] {
    width: 350px;
    max-width: 100%;
    height: 4px;
    padding-top: 0;
    padding-bottom: 20px;
}

.RangeInput [data-reach-slider-track][data-orientation="horizontal"]{
    // background: #4ea2a3;
    // background: linear-gradient(137deg, rgba(45,160,161,1) 0%, rgba(100,178,179,1) 44%, rgba(63,151,152,1) 68%);
    background: #444;
    box-shadow: inset -1px 1px 3px rgba(0,0,0,0.2);
    border-radius: 60px;
    height: 16px;
}

.RangeInput [data-reach-slider-handle][aria-orientation="horizontal"]{
    margin-top: -1px;
    height: 22px;
    width: 22px;
    background: #d5d5d5;
    // background: #4ea2a3;
    // background: linear-gradient(137deg, rgba(45,160,161,1) 0%, rgba(100,178,179,1) 44%, rgba(63,151,152,1) 68%);
    border-radius: 50%;
    border: none;
    box-shadow: 3px 3px 1px  rgba(0,0,0,0.1);
    outline: none;
}

.RangeInput.animate-handle [data-reach-slider-handle][aria-orientation="horizontal"]{
    transition: 0.2s ease-out;
}

@media all and (max-width: 330px){
    .RangeInput{
        padding: 0 18px;
    }
    
    .RangeInput [data-reach-slider-input][data-orientation="horizontal"] {
        height: 4px;
        padding-top: 0;
        padding-bottom: 16px;
    }
    
    .RangeInput [data-reach-slider-track][data-orientation="horizontal"]{
        border-radius: 60px;
        height: 10px;
    }
    
    .RangeInput [data-reach-slider-handle][aria-orientation="horizontal"]{
        margin-top: -1px;
        height: 16px;
        width: 16px;
    }
    
    .RangeInput.animate-handle [data-reach-slider-handle][aria-orientation="horizontal"]{
        transition: 0.2s ease-out;
    }
}