.transfer-to{
    z-index: 5;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.transfer-to-backdrop{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(56, 56, 56, 0.94);
    will-change: opacity;
    transition: opacity 0.15s ease-out;
}

.transfer-to:not(.visible) .transfer-to-backdrop{
    opacity: 0;
    transition: opacity 0.2s ease-out;
}

.transfer-to-content{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #444;
    box-shadow: 0 -25px 50px rgba(0, 0, 0, 0.08);
    max-width: 408px;
    margin: auto;
    max-height: 100vh;
    overflow-y: auto;
    will-change: opacity, transform;
    transition: transform 0.25s ease-out, opacity 0.1s ease-out;
}

.transfer-to:not(.visible) .transfer-to-content{
    transform: translateY(60%);
    opacity: 0;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
}

.transfer-to h3{
    padding: 1rem 1.2rem;
    margin-bottom: -0.4rem;
    font-size: 1.1rem;
    color: #aaa;
    text-align: center;
    letter-spacing: 0.01em;
}

.transfer-to h5{
    padding: 0.3em 1em;
    font-size: 1.2em;
    font-weight: 500;
    color: #7f7f7f;
    letter-spacing: 0.04em;
    text-transform: uppercase;
}

.transfer-to-content .transfer-to-choice{
    padding: 0.8rem;
    padding-right: 0em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2rem;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    border: none;
    background: transparent;
    outline: none;
    color: #bbb;
    user-select: none;
    touch-action: none;
    width: 100%;
    text-align-last: left;
    border-bottom: 1px solid #4e4e4e;
}

.transfer-to-content .transfer-to-choice.selected{
    color: #efefef;
}

.transfer-to-content .transfer-to-choice:last-of-type{
    border-bottom-color: transparent;
}

.transfer-to-choice .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    border: 2px solid #919191;
    border-radius: 50%;
    color: #545454;
    margin-right: 0.6em;
    font-style: normal;
    background: transparent;
}

.transfer-to-choice .icon svg{
    width: 12px;
    height: 12px;
    stroke-width: 3;
    stroke: #dbdbdb;
    opacity: 0;
}

.transfer-to-content .transfer-to-choice.selected .icon{
    background: rgba(126, 126, 126, 0.35);
    border-color: #aaa;
}

.transfer-to-content .transfer-to-choice.selected .icon svg{
    opacity: 1;
    stroke: #eee;
}

.transfer-to-actions{
    padding: 0.6rem 0;
    background: #444444;
    border-top: 1px solid #4e4e4e;
}

.transfer-to-actions .finlink-btn{
    text-transform: none;
    font-size: 1.1rem;
}

.transfer-to-actions .finlink-btn.rounded{
    background: #535353;
    background: transparent;
    border-color: #686868;
    border: none;
    padding: 0.5em 0.8em;
    letter-spacing: 0.05em;
    color: #bbb;
}

.transfer-to-actions .finlink-btn.rounded:last-child{
    color: #00f0f3;
}