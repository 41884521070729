.finlink-alert{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.finlink-alert-content{
    position: relative;
    background: #fff;
    background: #515151;
    border-radius: 8px;
    padding: 2em 1.5em;
    pointer-events: auto;
    margin: 0 1.5em;
    flex: 1;
    box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.4);
}

.finlink-alert-content h2{
    font-size: 1.4em;
    line-height: 1.2em;
    margin-bottom: 0.6em;
    margin-top: 1em;
    margin-top: 0.25em;
    color: #d8d8d8;
}

.finlink-alert-content p.alert-body{
    font-size: 0.98em;
    line-height: 1.5em;
    /* margin-top: 1em; */
    margin-bottom: 0.95em;
    letter-spacing: 0.02em;
    color: #c2c2c2;
    // color: #d8d8d8;
}

.finlink-alert-content h2 + p.alert-body{
    font-size: 1em;
    line-height: 1.4em;
    color: #c2c2c2;
    margin-top: 0;
}

.finlink-alert-buttons{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 0.85em;
    margin-bottom: -0.6em;
}

.finlink-alert-buttons button {
    letter-spacing: 0.1em !important;
    font-size: 1.2em !important;
    text-transform: none;
}

.finlink-alert-buttons button:not(:last-child){
    margin-right: 1.5em;
    margin-right: 0.8em;
}

.finlink-alert-buttons button:not(.primary){
    color: #bcbcbc;
}

@media (max-width: 400px){
    .finlink-alert-content{
        margin: 0 1em;
    }

    .finlink-alert-content h2{
        font-size: 1.2em;
        margin-bottom: 0.55em;
    }
    
    .finlink-alert-content p.alert-body{
        font-size: 0.98em;
    }
    
    .finlink-alert-content h2 + p.alert-body{
        font-size: 0.85em;
    }

    .finlink-alert-buttons{
        margin-right: -0.5em;
        margin-top: 0;
        margin-bottom: -0.9em;
    }
    
    .finlink-alert-buttons button{
        font-size: 0.93em !important;
        letter-spacing: 0.05em !important;
    }
}