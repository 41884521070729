.muno-switch input{
    display: none;
}

.muno-switch.loading{
    opacity: 0.8;
    pointer-events: none;
}

.muno-switch label {
    position: relative;
    display: inline-flex;
    align-items: center;
    border-radius: 50px;
    overflow: hidden;
    outline: none;
    padding: 1px;
    width: 46px;
}

.muno-switch label:before,
.muno-switch label:after{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #a7a7a7;
    background: #676767;
    border-radius: inherit;
}

.muno-switch label:after{
    z-index: 1;
    background: #79afaf;
    transition: opacity 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.muno-switch:not(.checked) label:after{
    opacity: 0;
}

.muno-switch .muno-switch-knob {
    position: relative;
    z-index: 2;
    color: #fff;
    width: 25px;
    height: 25px;
    transition: transform 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.muno-switch .muno-switch-knob svg:first-child{
    position: absolute;
    width: 100%;
    height: 100%;
}

.muno-switch svg:last-child{
    position: absolute;
    top: 6px;
    left: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    fill: #222;
    opacity: 0;
    pointer-events: none;
}

.muno-switch.loading .muno-switch-knob svg:last-child{
    opacity: 1;
    animation: spin 0.6s linear infinite;
}

@keyframes spin{
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

.muno-switch.checked .muno-switch-knob{
    transform: translateX(calc(100% - 6px));
}

@media all and (max-width: 330px){
    .muno-switch label {
        font-size: 0.97em;
        width: 34px;
    }

    .muno-switch .muno-switch-knob {
        width: 18px;
        height: 18px;
    }

    .muno-switch.checked .muno-switch-knob {
        transform: translateX(calc(100% - 5px));
    }
    
    .muno-switch svg {
        width: 18px;
        height: 18px;
        margin-right: 0.3em;
    }
}