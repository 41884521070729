.TransferModal {
    // background: #252525;
    // background: linear-gradient(to bottom, #3e3e3f 0%, #323132 83%);
    // padding: 2em;
}

#TransferMoney{
    padding-top: 20px;
}

#TransferMoney .finlink-modal{
    padding-top: 0;
}

#TransferMoney .finlink-modal-header{
    background: transparent;
    border-bottom-color: transparent;
}

#TransferMoney .finlink-modal-title{
    color: transparent;
}

.TransferModal .logoImage {
    display: block;
    height: 70px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 1.5em;
}

.TransferModal.center .logoImage {
    margin-top: 0 !important;
}

.TransferModal .logoImage.circle {
    width: 70px;
}

.TransferModal .logoImage {
    height: 70px;
    border-radius: 50%;
    background: #bbb;
    color: #3c3c3d;
    font-size: 1.5em;
    letter-spacing: 0.04em;
    display: flex;
}

.TransferModal .logoImage svg {
    width: 35px;
    height: 35px;
    fill: unset;
}

.TransferModal .form {
    margin: 0 auto;
    margin-bottom: 20px;
}

.TransferModal .formTitle {
    font-size: 1.65em;
    margin-top: 1em;
    margin-bottom: 1.3em;
    color: #c5c5c5;
    letter-spacing: 0.06em;
    text-align: center;
    font-weight: bold;
    text-transform: none;
}

.TransferModal .formTitle small{
    font-weight: normal;
    font-size: 0.75em;
    color: #a5a5a5;
    margin-right: 0.25em;
}

.TransferModal .formTitle strong{
    font-weight: normal;
    font-size: 0.75em;
    color: #2db87e;
    display: inline-block;
    margin-right: 0.2em;
    text-transform: none;
}

.transferToAccounts .form label {
    font-size: 1.2em;
    margin-bottom: 1.4em;
    display: block;
    text-align: center;
    letter-spacing: 0.03em;
}

.TransferModal .form > .finlink-btn {
    margin-top: 1.1em !important;
    outline: none;
    font-size: 1.1em;
}

@media all and (max-width: 400px){
    .TransferModal {
        padding: 2em 0;
        margin-top: -2em;
    }

    .TransferModal .logoImage {
        height: 60px !important;
        width: 60px !important;
    }

    .TransferModal .logoImage svg {
        width: 28px;
        height: 28px;
    }

    .TransferModal .formTitle {
        font-size: 1.1em;
    }
}

@media (max-width: 330px) {
    .TransferModal {
        padding: 0.5em 0;
    }

    .TransferModal .formTitle {
        font-size: 1.3em;
        margin-bottom: 0.95em;
    }

    .finlink-modal-content{
        padding: 1.5em 0.7em;
    }

    .transferToAccounts .form label {
        font-size: 0.9em;
        margin-bottom: 0.9em;
    }
    .TransferModal .finlink-btn.rounded {
        padding: 10px 30px;
    }
}