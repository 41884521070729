$red: #d04437;

.bailOutButton{
  position: fixed;
  top: 1em;
  right: 1em;
  width: 36px;
  height: 36px;
  background-color: rgba(255, 255, 255, 0.25) !important;
}

.authScene {
  background: #252525;
  background: linear-gradient(to bottom, #3e3e3f 0%, #323132 83%);
  padding: 2em;
  height: 100vh;
  overflow-y: auto;
}

.logoImage {
  display: block;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 1.5em;
  text-transform: uppercase;
}

.authScene.center .logoImage {
  margin-top: 0 !important;
}

.logoImage.circle{
  width: 70px;
}

.confirmScene .logoImage{
  height: 70px;
  border-radius: 50%;
  background: #bbb;
  color: #3c3c3d;
  font-size: 1.5em;
  letter-spacing: 0.04em;
  display: flex;
}

.logoImage svg{
  width: 35px;
  height: 35px;
  fill: #bbb;
  stroke: #777;
}

.logoImage.circle.success{
  background: #e2f3eb;
}

.logoImage.circle.success svg{
  fill: transparent;
  stroke: #2db87e;
}

.authScene .form {
  margin: 0 auto;
  margin-bottom: 20px;
}

.formTitle {
  font-size: 1.4em;
  margin-top: 1em;
  margin-bottom: 2em;
  color: #c5c5c5;
  letter-spacing: 0.1em;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}

.authScene .form p{
  font-size: 1.13em;
  line-height: 1.5em;
  margin-bottom: 2.9em;
  color: #bbbbbb;
  text-align: center;
  letter-spacing: 0.05em;
}

.authScene .form hr{
  border-width: 1px 0 0 0;
  width: 50%;
  max-width: 170px;
  margin: auto;
  border-color: #adadad;
}

.authScene .form p + hr{
  margin-top: -0.35em;
  margin-bottom: 0.8em;
}

.authScene .form button:not(.flat){
  margin-top: -0.6em;
  outline: none;
}

.forgotPwd {
  display: block;
  margin-top: 1.4em;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.9em;
  letter-spacing: 0.08em;
  color: #3aa777;
}

.siblingPageLink {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.5em;
  /* text-transform: uppercase; */
  font-size: 1em;
  letter-spacing: 0.04em;
  color: #ddd;
}

.siblingPageLink a {
  color: #3be299;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  font-weight: bold;
}

.siblingPageLink button.flat {
  // text-transform: uppercase;
  letter-spacing: 0.08em;
  font-size: 0.9em;
}

.confirmScene .finlink-radio-button-group-wrapper{
  margin-bottom: 27px;
}

.forgotScene .formTitle {
  margin-bottom: 1.1em;
}

@media all and (max-width: 400px){
  .authScene {
    padding: 2em 1.5em;
  }
  .logoImage {
    height: 50px;
  }

  .formTitle {
    font-size: 1.1em;
    margin-bottom: 3em;
  }

  .forgotScene .formTitle {
    margin-bottom: 0.8em;
  }

  .authScene .form p {
    font-size: 0.95em;
    line-height: 1.45em;
    color: #a1a1a1;
    letter-spacing: 0.02em;
  }

  .siblingPageLink a {
    color: #3be299;
    letter-spacing: 0.04em;
    font-weight: bold;
    font-size: 0.9em;
  }
}

@media (max-width: 400px){
  .confirmScene {
    padding: 0.5em 1.5em;
  }

  .confirmScene .formTitle {
    font-size: 1.1em;
    margin-bottom: 1.1em;
  }

  .confirmScene .form p {
    margin-bottom: 2.3em;
  }

  .confirmScene .finlink-radio-button-group-wrapper {
    margin-bottom: 38px;
  }
  
  .confirmScene .finlink-form-control-wrapper {
    margin-bottom: 45px;
  }

  .authScene .form .finlink-form-control.has-prepend label, 
  .authScene .form .finlink-form-control input[placeholder] ~ label {
    top: -10px !important;
  }
}

@media (max-width: 330px){
  .confirmScene {
    padding: 0.5em 1.5em;
  }

  .confirmScene .formTitle {
    font-size: 1.1em;
    margin-bottom: 0.95em;
  }

  .confirmScene .finlink-radio-button-group-wrapper {
    margin-bottom: 35px;
  }
  
  .confirmScene .finlink-form-control-wrapper {
    margin-bottom: 35px;
  }

  .authScene .form p {
    margin-bottom: 1.8em;
  }
}