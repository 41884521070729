#GuaranteeDetail{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-color: #383837;
    overflow: hidden;
}

#GuaranteeDetailContent{
    overflow-y: auto;
}

.guarantee-request-details{
    padding: 0.8rem 1.2rem;
}

#guaranteeSummary{
    background: #4a4a49;
    padding: 1.6rem;
    margin-top: 1rem;
    margin-bottom: 1.4rem;
    border-radius: 8px;
}

.detail-text{
    margin-bottom: 1.6rem;
}

#guaranteeSummary .detail-text:last-of-type{
    margin-bottom: 0;
}

.detail-text label{
    color: #bbb;
    font-size: 0.86em;
    display: block;
    margin-bottom: 0.35rem;
    letter-spacing: 0.03em;
    text-transform: uppercase;
}

.detail-text strong{
    font-size: 1.1rem;
    line-height: 1.55em;
    color: #eee;
    letter-spacing: 0.05em;
}

#guaranteeSummary .detail-text strong{
    display: block;
    margin-top: 0.4rem;
    font-size: 1.35rem;
}

.detail-text strong.pending-amount{
    font-size: 2rem;
    font-size: 1.45rem;
}

.detail-text strong small{
    font-weight: normal;
    font-size: 0.75em;
    margin-right: 0.4rem;
    margin-bottom: 0.13rem;
}

#noGuarantorsMessage{
    font-size: 1rem;
    margin-top: 1rem;
    padding: 1.2rem;
    background: #404040;
    color: #afafaf;
}

.guarantor-item:first-of-type{
    margin-top: 1.3rem;
}

.guarantor-item{
    margin-bottom: 0.8rem;
    color: #5d5a58;
}

.guarantor-item .ListItem{
    text-align: left;
    display: flex;
    padding: 0.8rem;
    width: 100%;
    border: 1px solid #5d5d5a;
    border-radius: 6px;
    background: #444443;
    outline: none;
    -webkit-tap-highlight-color: transparent;
}

.ListItem .icon, .ListItem .image {
    width: 36px;
    height: 36px;
    margin-right: 0.6rem;
}

.guarantor-item .ListItem h5 {
    color: #c6c6c6;
    font-size: 1.15rem;
}

.guarantor-item .ListItem h5 span{
    color: #d6d6d6;
    margin-left: auto;
}

#loanGuaranteeActions{
    padding: 1rem 1.2rem;
    background-color: #4d4d4d;
}

#loanGuaranteeActions .finlink-btn.rounded{
    min-width: 150px;
    width: 48%;
    padding-top: 8px;
    padding-bottom: 10px;
    letter-spacing: 0.06rem;
    font-weight: bold;
}

#loanGuaranteeActions .finlink-btn.rounded:not(.primary):not(.reject){
    color: #d9d9d9;
    border-color: #757575;
}

#loanGuaranteeActions .finlink-btn.rounded.reject{
    color: #ffa2a2;
    border-color: rgba(255, 157, 157, 0.61);
    background-color: rgba(232, 153, 153, 0.1);
}

#loanGuaranteeActions .finlink-btn.rounded.primary{
    color: #000
}

#loanGuaranteeActions .finlink-btn.disabled{
    opacity: 0.85;
}

@media all and (max-width: 330px){
    .guarantee-request-details{
        padding: 1rem 1.2rem;
        margin-bottom: 0.3rem;
    }
    
    .guarantee-request-details h3{
        font-size: 1.2rem;
        margin-bottom: 0.4rem;
    }
    
    .guarantee-request-details h5{
        font-size: 1.2rem;
        line-height: 1.55em;
        margin-bottom: 0.7rem;
    }
    
    .guarantee-request-details h5 small{
        font-size: 0.75em;
    }
    
    .guarantee-request-details span{
        font-size: 0.7rem;
        padding: 0.35rem 0.7rem;
        padding-top: 0.2rem;
        margin-bottom: 0.9rem;
    }
    
    .detail-text{
        font-size: 0.9rem;
    }
    
    #GuaranteeProgressTitle{
        font-size: 1rem;
    }
    
    #GuaranteeTimeLine{
        border-color: #5f5f5f;;
    }
    
    .guarantor-item{
        padding-left: 2.6rem;
        padding-right: 0.7rem;
        margin: 1.5rem 0;
    }
    
    .guarantor-item:before{
        content: "";
        position: absolute;
        top: -97px;
        height: 100%;
        left: 1.2rem;
        border-left: 3px dashed currentColor;
    }
    
    .guarantor-item:after{
        top: 4px;
        left: 14px;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background: currentColor;
    }
    
    .guarantor-item .ListItem{
        border-width: 1px;
        background:#474645;
    }
}