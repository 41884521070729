.date-filter-wrapper{
    /* position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #444;
    box-shadow: 0 -25px 50px rgba(0, 0, 0, 0.08);
    max-width: 408px;
    margin: auto;
    will-change: opacity, transform;
    transition: transform 0.25s ease-out, opacity 0.1s ease-out; */
}

.transaction-filter:not(.visible) .date-filter-wrapper{
    /* transform: translateY(60%);
    opacity: 0;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out; */
}

.date-filter-wrapper #title {
    padding: 0 1em;
    min-height: 33px;
    font-size: 1.15em;
    color: #9b9b9b;
    letter-spacing: 0.01em;
    margin-top: 0.35em;
    margin-bottom: -0.3em;
}

.date-filter-wrapper #title.not-empty{
    margin-bottom: 1.7em;
    padding-top: 1.4em;
}

.date-filter-wrapper #title > span:first-child{
    position: absolute;
    top: 0.25em;
    transition: transform 0.2s ease-out;
    color: #9e9d9d;
}

.date-filter-wrapper #title.not-empty > span:first-child{
    /* position: relative; */
    /* margin-bottom: 2em; */
    /* transform-origin: left;
    transform: scale(0.8) translateY(-1.1em);
    color: inherit; */
}

.date-filter-wrapper #title svg{
    stroke: #959595;
    width: 30px;
    height: 30px;
    margin: 0 0.4em;
}

.date-filter-wrapper #title strong{
    font-size: 0.85em;
    padding: 0.5em 1.1em;
    border-radius: 1.5em;
    background: #4f4f4f;
    border: 1px solid #646363;
}

.date-filter-wrapper #title strong,
.date-filter-wrapper #title svg{
    opacity: 0;
    top: 0.85em;
}

.date-filter-wrapper #title.not-empty strong,
.date-filter-wrapper #title.not-empty svg{
    opacity: 1;
    transition: opacity 0.35s ease-out 0.12s;
}

.date-filter-wrapper #input {
    font-size: 2.3em;
    width: 100vw;
    padding: 0 0.8em;
    margin-bottom: 0.5em;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #4e4e4e;
    color: #8d8d8d;
    letter-spacing: 0.02em;
    position: relative;
    min-height: 66px;
    display: none;
}

#deleteBtn {
    position: absolute;
    right: 1em;
    top: 0;
    bottom: 0;
    margin: auto 0;
    padding: 0.5em;
    background: rgba(0, 0, 0, 0.05);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    user-select: none;
}

#deleteBtn svg{
    width: 28px;
    height: 28px;
}

.date-filter-wrapper #numbers{
    margin-top: 1.2em;
    display: flex;
    flex-wrap: wrap;
}

.date-filter-wrapper #title + #numbers{
    margin-top: 0;
}

.date-filter-wrapper #numbers button{
    width: 33vw;
    max-width: 136px;
    height: 20vw;
    max-height: 83px;
    font-size: 1.2em;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: transparent;
    outline: none;
    color: #959595;
    user-select: none;
}

.date-filter-wrapper #numbers button.inactive{
    color: #5a5a5a;
    pointer-events: none;
}

.date-filter-wrapper #numbers button.active{
    background: #4b4b4b;
    border-color: #535353 !important;
}

.date-filter-wrapper #numbers button:not(:nth-child(3n)){
    border-right: 1px solid #4c4b4b;
}

.date-filter-wrapper #numbers button:nth-child(-n + 9){
    border-bottom: 1px solid #4c4b4b;
}

#submitButton{
    margin: 1em 0;
    background: #535353;
    border-color: #686868;
    color: #b0b0b0;
}