/*** Start Vanilla CSS 1.0.2 */ 
/* h1,h2,h3,h4,h5,h6,b,strong{font-weight:bold;}h1 {display: block;font-size: 2em;margin-block-start: 0.67em;margin-block-end: 0.67em;margin-inline-start: 0px;margin-inline-end: 0px;font-weight: bold;}em,i,dfn{font-style:italic;}dfn{font-weight:bold;}p,code,pre,kbd{margin:0 0 1.5em 0;}blockquote{margin:0 1.5em 1.5em 1.5em;}cite{font-style:italic;}li ul,li ol{margin:0 1.5em;}ul,ol{margin:0 1.5em 1.5em 1.5em;}ul{list-style-type:disc;}ol{list-style-type:decimal;}ol ol{list-style:upper-alpha;}ol ol ol{list-style:lower-roman;}ol ol ol ol{list-style:lower-alpha;}dl{margin:0 0 1.5em 0;}dl dt{font-weight:bold;}dd{margin-left:1.5em;}table{margin-bottom:1.4em;width:100%;}th{font-weight:bold;}th,td,caption{padding:4px 10px 4px 5px;}tfoot{font-style:italic;}sup,sub{line-height:0;}abbr,acronym{border-bottom:1px dotted;}address{margin:0 0 1.5em;font-style:italic;}del{text-decoration:line-through;}pre{margin:1.5em 0;white-space:pre;}img.centered,.aligncenter,div.aligncenter{display:block;margin-left:auto;margin-right:auto;}img.alignright{display:inline;}img.alignleft{display:inline;}.alignright{float:right;margin-left:10px;}.alignleft{float:left;margin-right:10px;}img { max-width: 100%; }* html .clearfix{height:1%;}*+html .clearfix{display:inline-block;}.clearfix:after{content:”.”;display:block;height:0;clear:both;visibility:hidden;}* html .group{height:1%;}*+html .group{display:inline-block;}.group:after{content:”.”;display:block;height:0;clear:both;visibility:hidden;} */

#viewUpdate{
    padding-bottom: 60px;
}

#viewUpdate h1, 
#viewUpdate h2, 
#viewUpdate #releaseNotes{
    color: #999;
}

#viewUpdate h1, 
#viewUpdate h2{
    font-weight: 600;
    letter-spacing: 0.02em;
}

#viewUpdate h1{
    color: #ccc;
    font-size: 1.6em;
}

#viewUpdate > h1{
    margin-top: 0.1em;
    margin-bottom: 0.15em;
}

#viewUpdate > h1:nth-of-type(2),
#releaseNotes h1{
    margin-top: 1.2em;
    margin-bottom: 0.2em;
}

#viewUpdate > h1:nth-of-type(2){
    margin-bottom: 0;
}

#releaseNotes p, #releaseNotes li{
    font-size: 1.1em;
    font-weight: 500;
    letter-spacing: 0.03em;
}

#viewUpdate h2{
    font-size: 1.2em;
}

#viewUpdate ul li{
    list-style-type: disc;
}

#viewUpdate ol li{
    list-style-type: decimal;
}

#viewUpdate .finlink-btn{
    position: fixed;
    left: 0;
    top: auto;
    bottom: 0;
    width: 100%;
    border-radius: 0;
    height: 55px;
    line-height: 25px;
    font-size: 1.15em;
    z-index: 1;
}