.finlink-toast-notifications{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.finlink-toast{
    padding: 0.6em 1.3em;
    border: 1px solid #535353;
    background: #1b1b1b;
    color: #d2d2d2;
    background: #d6d6d6;
    color: #272727;
    background: #b7b7b7;
    color: #444444;
    border-radius: 20px;
    line-height: 1.1em;
    letter-spacing: 0.03em;
    max-width: 280px;
    animation: hideToast 0.2s ease-out 1.5s forwards;
}

@keyframes hideToast{
    from{opacity: 1}
    to{opacity: 0}
}