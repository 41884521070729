.accountDetailFragment{
    background-color: #3e3e3e;
    background: #272726;
    background-color: #424242 !important;
    padding-top: 275px;
}

.account-detail-nav{
    top: 0;
    border-bottom: none;
    background: #383837;
}

.stickyHeader{
    background: #383837;
    position: fixed;
    top: 59px;
    left: 0;
    right: 0;
    z-index: 1;
}

.accountDetailSection{
    padding: 1.5em 1.3em;
}

.accountDetailSection .transactionItem:first-of-type{
    border-top: none !important;
}

.accountDetailSection .sectionTitle{
    font-size: 1.24em;
    font-weight: 100;
    color: #b7b7b7;
    letter-spacing: 0.035em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.4em;
}

.accountActions{
    left: 50%;
    transform: translateX(-50%) translateY(0.5em);
    max-width: 330px;
    width: 100%;
    margin-top: 0.7em;
    margin-bottom: -1.2em;
    display: inline-block;
    border-radius: 60px;
    background-color: #505554;
    border: 1px solid #868c8c;
    padding: 0 0.6em;
    padding-right: 0.7em;
}

.action-item{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.8em 0;
    flex-shrink: 0;
    color: #d0cece;
    text-align: center;
    letter-spacing: 0.06em;
    background: transparent;
    border: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    font-size: 0.9rem;
    position: relative;
    width: 50%;
    flex-shrink: 0;
}

.accountActions.main .action-item{
    width: 33.333%;
}

.action-item:not(:last-child):after{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1px;
    width: 1px;
    background: #777777;
}

.action-item:last-child{
    padding-right: 0.3em;
}

.action-item .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 0.35em;
    border: 1px solid #767575;
    background: #2e2d2d;
}

.action-item.deposit .icon{
    background-color: #7eaca9;
    border-color: #354848;
}

.action-item .icon svg{
    stroke: #888888;
    width: 18px;
    height: 18px;
}

.action-item.deposit svg{
    stroke: #404847;
}

.action-item.withdrawal svg{
    stroke: #da4d4d;
}

@media all and (max-width: 400px){
    .stickyHeader{
        top: 52px;
    }

    .accountDetailFragment{
        padding-top: 240px;
    }

    .accountSwitcherButtons button {
        width: 40px;
        height: 40px;
    }

    .accountSwitcherButtons button svg {
        width: 26px;
        height: 26px;
    }

    .accountDetailSection {
        padding: 1.5em 0.7em;
    }

    .accountDetailSection .sectionTitle {
        margin-bottom: 0em;
    }
}

@media all and (max-width: 330px){
    .stickyHeader{
        top: 47px;
    }

    .accountActions{
        width: calc(100% - 2em);
    }
    
    .action-item{
        padding: 0.8em 0;
        font-size: 0.83em;
    }
}