.account-picker{
    z-index: 5;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.account-picker-backdrop{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(56, 56, 56, 0.94);
    will-change: opacity;
    transition: opacity 0.15s ease-out;
}

.account-picker:not(.visible) .account-picker-backdrop{
    opacity: 0;
    transition: opacity 0.2s ease-out;
}

.account-picker-content{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #444;
    box-shadow: 0 -25px 50px rgba(0, 0, 0, 0.08);
    max-width: 408px;
    margin: auto;
    padding-bottom: 5em;
    max-height: 100vh;
    overflow-y: auto;
    will-change: opacity, transform;
    transition: transform 0.25s ease-out, opacity 0.1s ease-out;
}

.account-picker:not(.visible) .account-picker-content{
    transform: translateY(60%);
    opacity: 0;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
}

.account-picker h3{
    padding: 1em 1.2em;
    font-size: 1.4em;
    color: #a6a6a6;
    text-align: center;
    letter-spacing: 0.03em;
    border-bottom: 1px solid #4e4e4e;
}

.account-picker h5{
    padding: 0.3em 1em;
    font-size: 1.2em;
    font-weight: 500;
    color: #7f7f7f;
    letter-spacing: 0.04em;
    text-transform: uppercase;
}

.account-picker-content #accounts{
    
}

.account-picker-content .account-choice{
    padding: 1em 0.9em;
    padding-right: 0em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.3em;
    letter-spacing: 0.08em;
    text-transform: capitalize;
    border: none;
    background: transparent;
    outline: none;
    color: #aeaeae;
    user-select: none;
    touch-action: none;
    width: 100%;
    text-align-last: left;
    border-bottom: 1px solid #4e4e4e;
}

.account-picker-content .account-choice:last-of-type{
    border-bottom-color: transparent;
}

.account-choice .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border: 2px solid #919191;
    border-radius: 50%;
    color: #545454;
    margin-right: 0.6em;
    font-style: normal;
    background: transparent;
}

.account-choice .icon svg{
    width: 16px;
    height: 16px;
    stroke: #dbdbdb;
    opacity: 0;
}

.account-picker-content .account-choice.selected .icon{
    background: rgba(126, 126, 126, 0.57);
}

.account-picker-content .account-choice.selected .icon svg{
    opacity: 1;
}

.account-picker-actions{
    padding: 0.7em 0;
    padding-left: 0.3em;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #444444;
    border-top: 1px solid #4e4e4e;
}

.account-picker-actions .finlink-btn.rounded{
    background: #535353;
    background: transparent;
    border-color: #686868;
    border: none;
    color: #b0b0b0;
    font-size: 1em;
    padding: 0.5em 0.8em;
    letter-spacing: 0.1em;
    color: #cfcfcf;
}

.account-picker-actions .finlink-btn.rounded:last-child{
    color: #00f0f3;
}