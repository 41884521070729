.ListItem{
    text-align: left;
    display: flex;
    padding: 0.8rem 1.1rem;
    min-height: 65px;
    width: 100%;
    border: 2px solid #7b7b7b;
    border-radius: 4px;
    background: transparent;
    outline: none;
    -webkit-tap-highlight-color: transparent;
}

.ListItem:not(.two-line){
    align-items: center;
}

.ListItem:active{
    background: #4a4c4c;
    border-color: #788382;
}

.ListItem.selected{
    background: #4a4c4c;
    border-color: #788382;
}

.ListItem .icon,
.ListItem .image{
    width: 39px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-right: 0.9rem;
    margin-left: -0.15rem;
    color: #e0e0e0;
    flex-shrink: 0;
}

.ListItem .icon svg{
    width: 39px;
    height: 39px;
}

.ListItem .image{
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
}

.ListItem .image img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ListItem .text{
    flex: 1;
    min-width: 0;
}

.ListItem h5{
    color: #e6e6e6;
    font-weight: 500;
    font-size: 1.35rem;
    line-height: 1.4;
    margin: 0;
}

.ListItem.selected h5{
    color: #bfeae5;
}

.ListItem:not(.two-line) h5{
    // margin-top: 0.1em;
}

.ListItem p{
    color: #b8b8b8;
    font-size: 1.1rem;
    line-height: 1.5em;
    margin: 0;
}

.ListItem .choice-indicator{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    flex-shrink: 0;
    align-self: center;
    margin-top: 0.2rem;
    margin-left: 1rem;
    border: 2px solid currentColor;
    color: #dedede;
}

.ListItem.selected .choice-indicator{
    color: #b8dad6
}

.ListItem .choice-indicator svg{
    width: 13px;
    height: 13px;
    stroke-width: 5px;
    margin-top: 1px;
}

.ListItem:not(.selected) .choice-indicator svg{
    opacity: 0;
}

.OptionPicker .action-buttons{
    padding: 0 1em;
    margin-top: 0.8rem;
    margin-bottom: -0.5rem;
    overflow: hidden;
    justify-content: space-around;
}

.ListItem .finlink-btn.action{
    background: rgb(104, 104, 104);
    color: #dedede;
}

@media screen and (max-width: 400px){
    .ListItem .image,
    .ListItem .icon {
        width: 32px;
        height: 32px;
        margin-right: 1.2rem;
    }

    .ListItem h5 {
        font-size: 1.3rem;
    }

    .ListItem:not(.two-line) h5 {
        margin-top: 0.15rem;
    }
    
    .ListItem p {
        font-size: 1rem;
    }

    .ListItem .choice-indicator {
        width: 25px;
        height: 25px;
    }

    .ListItem .choice-indicator svg {
        width: 15px;
        height: 15px;
    }

    .OptionPicker .action-buttons{
        margin-top: 1.3rem;
        margin-bottom: 0;
        line-height: 1;
    }

    .OptionPicker .action-button {
        font-size: 1.25rem;
        padding: 0.6rem 1rem;
        min-width: 110px;
    }
}

@media screen and (max-width: 330px){
    .OptionPicker h3 {
        font-size: 1.5rem;
        padding: 0 0.7rem;
    }

    .ListItem {
        min-height: 50px;
        padding: 0.3rem 0.8rem;
    }
    
    .ListItem .icon,
    .ListItem .image {
        width: 26px;
        height: 26px;
        margin-right: 1.2rem;
    }
    
    .ListItem .icon svg{
        width: 26px;
        height: 26px;
    }
    
    .ListItem h5 {
        font-size: 1rem;
    }

    .ListItem:not(.two-line) h5 {
        margin-top: 0.15rem;
    }
    
    .ListItem p {
        font-size: 1rem;
    }

    .ListItem .choice-indicator,
    .ListItem .finlink-btn.action {
        width: 20px;
        height: 20px;
    }

    .ListItem .choice-indicator svg {
        width: 10px;
        height: 10px;
        margin-top: 0;
    }

    .ListItem .finlink-btn.action svg {
        width: 14px;
        height: 14px;
    }
}