.sectionTitle{
    font-size: 1.24em;
    font-weight: 100;
    color: #b7b7b7;
    letter-spacing: 0.035em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.9em;
}

.transactionsLoading{
    display: block;
    color: #777;
    letter-spacing: 0.03em;
    margin-top: 1rem;
}

.transactionItem{
    padding: 1em 0;
    padding-top: 1.2em;
    border-radius: 4px;
    // background: #474747;
    border-top: 1px solid #565656;
    // margin-bottom: 0.5em;
    color: #fff;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 1.1em;
    font-weight: bold;
    letter-spacing: 0.03em;
}

.transactionItem:first-child{
    border-bottom: 1px solid #4a4a4a;
}

.transactionItem > svg{
    stroke: #b6b6b6;
    margin-top: -0.1em;
    margin-right: 0.7em;
}

.transactionItem.deposit > svg{
    stroke: #49ae49;
}

.transactionItem.withdrawal > svg,
.transactionItem.transfer-out > svg{
    stroke: #da4d4d;
}

.transactionItem.transfer > svg{
    
}


.transactionItem > div{
    flex: 1;
    padding-left: 0.5em;
}

.transactionItem > div div{
    font-size: 1.1em;
}

.transactionItem > div div div{
    color: #9e9e9e;
    font-size: 1em;
    text-transform: lowercase;
}

.transactionItem div h3{
    margin-bottom: 0.5em;
    color: #e1e1e1;
    font-weight: 500;
    font-size: 1.05em;
}

.transactionItem div span{
    color: #d2d2d2;
    display: block;
    font-size: 0.85em;
    font-weight: normal;
    text-transform: capitalize;
    letter-spacing: 0.05em;
}

.transactionItem div h3 + span{
    margin-bottom: 0.5em;
}

.transactionItem small{
    color: #a0a0a0;
    font-size: 0.92em;
    font-weight: 400;
    letter-spacing: 0.05em;
    position: absolute;
    right: 0;
}

.transactionItem strong{
    color: #9e9c9c;
    font-weight: normal;
    font-size: 0.95em;
    letter-spacing: 0.04em;
}

.transactionItem strong span{
    letter-spacing: 0.04em;
    // font-size: 1.1em;
}

@media all and (max-width: 400px){
    .accountDetailSection .sectionTitle {
        margin: 0 0.4rem;
        font-size: 0.85em;
        font-weight: bold;
        color: #b7b7b7;
        letter-spacing: 0.035em;
    }
       
    .transactionItem{
        padding: 0.8em 0;
        border-radius: 2px;
        margin-bottom: 0;
        padding-top: 1.2em;
    }

    .transactionItem > div div {
        font-size: 0.9em;
    }

    .transactionItem > svg{
        display: none;
    }

    .transactionItem div h3 {
        font-size: 0.9em;
        margin-bottom: 0.7rem;
    }

    .transactionItem div span {
        font-size: 0.9rem;
        opacity: 0.85;
    }

    .transactionItem div h3 + span {
        margin-bottom: 0.2em;
    }

    .transactionItem small {
        margin-top: 0.18em;
        font-size: 0.85rem;
    }

    .transactionItem strong {
        font-size: 0.83em;
        letter-spacing: 0.04em;
    }
}