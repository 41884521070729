.ot-mobile-nav{
    position: fixed;
    bottom: 0;
    background: #4e4d4d;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    width: 100vw;
    z-index: 10;
    padding: 0.5rem 0;
    padding-top: 0.65rem;
    transition: all 0.35s ease-out;
}

.bottom-nav-wrapper:not(.visible) .ot-mobile-nav{
    transform: translateY(100%);
    opacity: 0;
}

.ot-mobile-nav button{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    flex-shrink: 0;
    color: #c3c3c3;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    margin-right: 0.1em;
    border: none;
    background: transparent;
    letter-spacing: 0.04em;
    font-size: 0.8rem;
}

.ot-mobile-nav button .icon{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.ot-mobile-nav button svg{
    width:24px;
    height:24px;
    fill:none;
    stroke-width:2;
    stroke-linecap:round;
    stroke-linejoin:round;
    margin-bottom: 0.3em;
    position: relative;
}

@media all and (max-width: 400px){
    .ot-mobile-nav button{
        font-size: 12px;
    }
    
    .ot-mobile-nav button svg{
        width:20px;
        height:20px;
        margin-bottom: 0.2em;
    }
}

@media all and (max-width: 330px){
    .ot-mobile-nav button{
        font-size: 11px;
    }
    
    .ot-mobile-nav button svg{
        width:17px;
        height:17px;
    }
}