.profileScene {
    
}

#pageTitle{
    padding: 1.1rem;
    padding-top: 1.3rem;
    border-bottom: 1px solid #555;
    // margin-bottom: 1.1rem;
}

#pageTitle h2{
    color: #cecece;
    color: #fff;
    font-size: 1.8em;
    font-weight: normal;
    margin-left: 0.3rem;
    margin-bottom: 1rem;
}

#pageTitle small{
    color: #cecece;
    font-size: 1.4rem;
    display: block;
    margin-bottom: 0.5rem;
}

#pageTitle .finlink-btn{
    color: #add;
}

#dp{
    width: 95px;
    height: 95px;
    border-radius: 50%;
    background: #555;
    border: 2px solid #888;
    margin-left: auto;
    margin-right: -0.2rem;
    margin-top: -1.1rem;
    color: #fff;
    font-size: 1.8rem;
    letter-spacing: 0.1em;
}

.profile-section{
    padding: 1.1rem;
    border-bottom: 1px solid #555;
}

.profile-section:not(:last-of-type){
    // margin-bottom: 0.8rem;
}

.profile-section-title{
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: normal;
    color:#aaa;
    letter-spacing: 0.02em;
    margin-bottom: 0.75rem;
}

.profile-detail{
    color: #777;
    padding: 0.7em 0;
}

.profile-detail small{
    font-size: 1.32rem;
    font-weight: 300;
    color: #fff;
    letter-spacing: 0.03em;
}

#preferences small{
    line-height: 1.4em;
    font-weight: 300;
    margin: 0.2rem 0;
}

.profile-detail small + h2{
    margin-top: 0.4rem;
}

.profile-detail h2{
    color: #cecece;
    font-size: 1.3rem;
    font-weight: 300;
}

.profile-section .muno-switch{
    margin-left: 1rem;
}

.profile-section .finlink-btn{
    font-size: 16px;
    color: #add;
    padding-left: 0;
}

.profile-section .payment-method-icon{
    width: 50px;
    height: 50px;
    background-color: #515151;
    border-radius: 50%;
    margin-right: 0.5rem;
    color: #ccc;
    font-size: 24px;
}

#logoutBtn{
    font-size: 19px;
    border-color: rgb(76, 75, 74);
    padding: 0.7em 30px;
}