.finlink-fab{
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00f0f3;
    box-shadow: none;
    padding: 0;
    outline: none;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    position: fixed;
    right: 16px;
    bottom: 16px;
    z-index: 50;
    border: none;
    transition: transform 0.35s ease-out, opacity 0.2s ease-out;
}

.finlink-fab.centered{
    left: 0;
    right: 0;
    margin: 0 auto;
}

.finlink-fab.hidden{
    pointer-events: none;
    opacity: 0;
    transform: scale(0) rotate(20deg);
}

.finlink-fab svg{
    width: 24px;
    height: 24px;
    fill: #133334;
}