.finlink-dialpad{
    z-index: 1;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.finlink-dialpad-backdrop{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(56, 56, 56, 0.94);
    will-change: opacity;
    transition: opacity 0.15s ease-out;
}

.finlink-dialpad:not(.visible) .finlink-dialpad-backdrop{
    opacity: 0;
    transition: opacity 0.2s ease-out;
}

.finlink-dialpad-content{
    /* position: fixed; */
    /* bottom: 0; */
    /* top: 0; */
    left: 0;
    right: 0;
    background: #444;
    box-shadow: 0 -25px 50px rgba(0, 0, 0, 0.08);
    width: 100%;
    max-width: 408px;
    margin: auto;
    padding: 1.2rem;
    will-change: opacity, transform;
    transition: transform 0.25s ease-out, opacity 0.1s ease-out;
}

.finlink-dialpad:not(.visible) .finlink-dialpad-content{
    transform: translateY(60%);
    opacity: 0;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
}

.finlink-dialpad-content #title {
    /* padding: 0 1.3em; */
    margin-top: 0.6em;
    margin-bottom: 0.1em;
    font-size: 1.35em;
    color: #9b9b9b;
    letter-spacing: 0.01em;
}

.finlink-dialpad-content .finlink-btn {
    margin-top: 1rem;
}

.finlink-dialpad-content #input {
    font-size: 2.3em;
    width: 100vw;
    padding: 0 0.8em;
    margin-bottom: 0.5em;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #4e4e4e;
    color: #8d8d8d;
    letter-spacing: 0.02em;
    position: relative;
    min-height: 66px;
}

#deleteBtn {
    position: absolute;
    right: 1em;
    top: 0;
    bottom: 0;
    margin: auto 0;
    padding: 0.5em;
    background: rgba(0, 0, 0, 0.05);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    user-select: none;
    touch-action: none;
}

#deleteBtn svg{
    width: 28px;
    height: 28px;
}

.finlink-dialpad-content #numbers{
    display: flex;
    flex-wrap: wrap;
}

.finlink-dialpad-content #numbers button{
    width: 33vw;
    max-width: 136px;
    height: 20vw;
    max-height: 83px;
    font-size: 1.3em;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: transparent;
    outline: none;
    color: #959595;
    user-select: none;
    touch-action: none;
}

.finlink-dialpad-content #numbers button:not(:nth-child(10)):not(:nth-child(12)){
    font-size: 2em;
}