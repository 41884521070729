.finlink-btn{
    white-space: nowrap;
    top: 0px;
    border-radius: 4px;
    position: relative;
    border: none;
    background: #ddd;
    cursor: pointer;
    font-size: 0.93rem;
    font-weight: normal;
    color: #777;
    border-bottom: 1px solid #5b6566;
    box-shadow: 0px 0px 0px 0px #b8b8b8;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    padding: 0.6rem 1.5rem;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
}

.finlink-btn span{
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.finlink-btn[disabled] span,
.finlink-btn.disabled span{
    opacity: 0.5;
    pointer-events: none;
}

.finlink-btn[loading] span,
.finlink-btn.loading span{
    opacity: 0;
}

.finlink-btn.action svg,
.finlink-btn.fab svg,
.finlink-btn svg.loader-icon{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.finlink-btn.disabled{
    pointer-events: none;
}

.finlink-btn svg.loader-icon{
    transform: scale(0.8)
}

.finlink-btn svg.loader-icon circle{
    stroke-width: 6;
}

.finlink-btn.primary{
    background: #00f0f3;
    color: #133334;
}

.finlink-btn:not(.rounded).primary.disabled,
.finlink-btn:not(.rounded).primary.loading{
    background: #65abac;
}

.finlink-btn.block{
    width: 100%;
}

.finlink-btn.large{
    font-size: 1em;
}

.finlink-btn[disabled],
.finlink-btn.disabled,
.finlink-btn[loading],
.finlink-btn.loading{
    pointer-events: none;
}

.finlink-btn.flat{
    background: transparent;
    border: none;
    box-shadow: none;
    padding: 8px;
    font-size: 14px;
    letter-spacing: 0.03em;
    color: #999;
}

.finlink-btn.action{
    padding: 0;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.1);
    box-shadow: none;
    outline: none;
    width: 28px;
    height: 28px;
    font-size: 1px;
}

.finlink-btn.action.disabled{
    opacity: 0.5;
}

.finlink-btn.action svg{
    width: 20px;
    height: 20px;
}

.finlink-btn.fab{
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--accent-color);
    box-shadow: none;
    padding: 0;
    outline: none;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    position: fixed;
    right: 16px;
    bottom: 16px;
    z-index: 50;

    transition: transform 0.35s ease-out;
}

.finlink-btn.fab svg{
    width: 24px;
    height: 24px;
    fill: #fff;
}

.finlink-btn.inverse{
    background: #464646;
    border-color: #3a3a3a;
    color: #b3b3b3;
}

.finlink-btn.inverse svg circle{
    stroke-width: 5;
    stroke: #747474;
}

.finlink-btn.flat.primary{
    color: #00f0f3;
}

.finlink-btn.flat.accent{
    color: #3be299;
    border-color: #33a073 !important;
}

.finlink-btn.rounded{
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    border: 2px solid transparent;
}

.finlink-btn.rounded.flat:not(.primary){
    border-color: #777;
}

.finlink-btn.rounded.flat.primary{
    color: #00f0f3;
    border-color: #47c2c4;
}

.finlink-btn:not(.flat).rounded.primary[disabled],
.finlink-btn:not(.flat).rounded.primary.disabled,
.finlink-btn:not(.flat).rounded.primary[loading],
.finlink-btn:not(.flat).rounded.primary.loading{
    background-color: #65abac;
    border-color: #65abac;
}

.finlink-btn.rounded.flat.primary[disabled],
.finlink-btn.rounded.flat.primary.disabled,
.finlink-btn.rounded.flat.primary[loading],
.finlink-btn.rounded.flat.primary.loading{
    background: rgba(255, 255, 255, 0.06);
    border-color: #698889;
}

.finlink-btn.rounded.flat.primary svg circle{
    stroke-width: 6;
    stroke: #97abab;
}

@media all and (max-width: 400px){
    .finlink-btn{
        padding: 0.6rem 2rem;
    }
}