.OutlineButton{
    font-size: 18px;
    border: 2px solid #636363 !important;
    color: #bebebe !important;
    background: transparent !important;
    background: #464746 !important;
    box-shadow: none;
    padding: 0.95em 2em;
}

@media all and (max-width: 330px){
    .OutlineButton {
        font-size: 14px;;
        padding: 0.6em 2em;
    }
}