.TransferModal .form label {
    font-size: 1.2em;
    margin-bottom: 1.4em;
    display: block;
    // text-align: center;
    letter-spacing: 0.03em;
}

@media (max-width: 330px) {
    .TransferModal .form label {
        font-size: 0.9em;
        margin-bottom: 0.9em;
    }
}