#LoanDetail{
    overflow: hidden;
}

#LoanDetailContent{
    overflow-y: auto;
}

.loan-request-details{
    padding: 1rem 1.2rem;
    margin-bottom: 1.1rem;
}

.loan-request-details h3{
    font-weight: normal;
    font-size: 1.6rem;
    color: #fff;
    line-height: 1.7em;
    letter-spacing: 0.03em;
    margin-bottom: 0.7rem;
}

.loan-request-details h5{
    font-size: 1.5rem;
    line-height: 1.55em;
    color: #c6c6c6;
    letter-spacing: 0.015em;
    margin-bottom: 1rem;
}

.loan-request-details h5 small{
    font-weight: normal;
    font-size: 0.75em;
    margin-right: 0.4rem;
    margin-bottom: 0.13rem;
}

.loan-request-details span{
    display: inline-block;
    font-size: 0.9rem;
    font-weight: bold;
    letter-spacing: 0.12em;
    padding: 0.5rem 0.7rem;
    padding-top: 0.283rem;
    color: #e5e5e5;
    border: 1px solid #999;
    border-radius: 20px;
    margin-bottom: 1.25rem;
}

.loan-request-details.rejected span{
    border-color: #ffc2c2;
    color: #ffc2c2;
}

.loan-request-details.approved span{
    background-color: #99e3c4;
    border-color: #95e6c4;
    color: #042819
}

.loan-request-details span small{
    font-size: 2.3rem;
    line-height: 0;
    margin-right: 0.1rem;
    margin-top: 0.07rem;
}

.detail-text{
    color: #e3e3e3;
    font-size: 1.2rem;
}

.detail-text label{
    color: #c9c9c9;
    font-size: 0.9em;
    display: inline-block;
    margin-right: 0.8rem;
    letter-spacing: 0.02em;
}

#LoanProgressTitle{
    color: #ddd;
    font-size: 1.3rem;
    padding-left: 1.2rem;
    margin-bottom: 1.3rem;
    font-weight: normal;
    letter-spacing: 0.03rem;
}

#LoanTimeLine{
    border-top: 1px solid #797877;
    position: relative;
    padding-bottom: 0.1rem;
    overflow: hidden;
}

.loan-timeline-step{
    position: relative;
    padding-left: 3rem;
    padding-right: 0.8rem;
    margin: 2.5rem 0;
    color: #5d5a58;
}

.loan-timeline-step.complete{
    color: rgba(138, 226, 189, 0.949);
}

.loan-timeline-step.complete.error{
    color: #ffc2c2;
}

.loan-timeline-step:before{
    content: "";
    position: absolute;
    top: -120px;
    height: 100%;
    left: 1.2rem;
    border-left: 3px dashed currentColor;
}

.loan-timeline-step:after{
    content: "";
    position: absolute;
    top: 4px;
    left: 14px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: currentColor;
    z-index: 1;
}

.loan-timeline-step .ListItem{
    border-width: 1px;
    background:#474645;
}

.loan-timeline-step .ListItem h5{
    text-transform: capitalize;
}

@media all and (max-width: 330px){
    .loan-request-details{
        padding: 1rem 1.2rem;
        margin-bottom: 0.3rem;
    }
    
    .loan-request-details h3{
        font-size: 1.2rem;
        margin-bottom: 0.4rem;
    }
    
    .loan-request-details h5{
        font-size: 1.2rem;
        line-height: 1.55em;
        margin-bottom: 0.7rem;
    }
    
    .loan-request-details h5 small{
        font-size: 0.75em;
    }
    
    .loan-request-details span{
        font-size: 0.7rem;
        padding: 0.35rem 0.7rem;
        padding-top: 0.2rem;
        margin-bottom: 0.9rem;
    }
    
    .detail-text{
        font-size: 0.9rem;
    }
    
    #LoanProgressTitle{
        font-size: 1rem;
    }
    
    #LoanTimeLine{
        border-color: #5f5f5f;;
    }
    
    .loan-timeline-step{
        padding-left: 2.6rem;
        padding-right: 0.7rem;
        margin: 1.5rem 0;
    }
    
    .loan-timeline-step:before{
        content: "";
        position: absolute;
        top: -97px;
        height: 100%;
        left: 1.2rem;
        border-left: 3px dashed currentColor;
    }
    
    .loan-timeline-step:after{
        top: 4px;
        left: 14px;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background: currentColor;
    }
    
    .loan-timeline-step .ListItem{
        border-width: 1px;
        background:#474645;
    }
}