.DepositModal {
    // background: #252525;
    // background: linear-gradient(to bottom, #3e3e3f 0%, #323132 83%);
    // padding: 2em;
}

#DepositMoney{
    padding-top: 20px;
}

#DepositMoney > .finlink-modal{
    padding-top: 0;
}

#DepositMoney > .finlink-modal > .finlink-modal-header{
    background: transparent;
    border-bottom-color: transparent;
}

#DepositMoney > .finlink-modal > .finlink-modal-header .finlink-modal-title{
    color: transparent;
}

#AddMobileWallet .finlink-modal-content{
    padding-top: 3.5em;
}

.DepositModal .logoImage {
    display: block;
    height: 70px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 1.5em;
}

.DepositModal.center .logoImage {
    margin-top: 0 !important;
}

.DepositModal .logoImage.circle {
    width: 70px;
}

.DepositModal .logoImage {
    height: 70px;
    border-radius: 50%;
    background: #bbb;
    color: #3c3c3d;
    font-size: 1.5em;
    letter-spacing: 0.04em;
    display: flex;
}

.DepositModal .logoImage svg {
    width: 35px;
    height: 35px;
    fill: unset;
}

.DepositModal .form {
    margin: 0 auto;
    margin-bottom: 20px;
}

.DepositModal .formTitle {
    font-size: 1.4em;
    margin-top: 1em;
    margin-bottom: 1.3em;
    color: #c5c5c5;
    letter-spacing: 0.1em;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
}

.DepositModal .formTitle small{
    font-weight: normal;
    font-size: 0.75em;
    color: #a5a5a5;
    margin-right: 0.25em;
}

.DepositModal .formTitle strong{
    font-weight: normal;
    font-size: 0.75em;
    color: #2db87e;
    display: inline-block;
    margin-right: 0.2em;
    text-transform: none;
}

.DepositModal .form label {
    // font-size: 1.2em;
    // margin-bottom: 1em;
    // display: block;
    // letter-spacing: 0.03em;
}

#pickPaymentMethodType label {
    // font-size: 1.2em;
    // margin-bottom: 1em;
    // display: block;
    text-align: center;
}

.DepositModal .accountChoiceItem{
    width: 100%;
    padding: 1em 0.85em;
    padding: 1em 0.85em;
    padding-left: 1.1em;
    border-radius: 4px;
    background: #474747;
    color: #bababa;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2em;
    letter-spacing: 0.04em;
    user-select: none;
    margin: 1em 0;
    border: none;
}

.accountChoiceItem:disabled{
    opacity: 0.45;
}

.accountChoiceItem:active{
    transform: scale(0.99);
}

.accountChoiceItem svg{
    width: 24px;
    height: 24px;
}

.accountChoiceItem svg.circle circle{
    cx: 12;
    cy: 12;
    r: 10;
    fill: transparent;
    stroke: #aaa;
    stroke-width: 2;
}

.accountChoiceItem.selected svg.circle,
.accountChoiceItem:not(.selected) svg:not(.circle){
    display: none;
}

.accountChoiceItem .currency{
    font-size: 0.75em;
    margin-top: 0.1em;
}

.accountChoiceItem .amount{
    font-size: 0.85em;
    // margin-left: -1.5em;
    // margin-top: 0.1em;
}

.DepositModal .form .finlink-btn {
    // margin-top: 1.1em !important;
    // outline: none;
    // font-size: 1.1em;
}

.DepositModal .accountChoiceTitle{
    margin: 0;
    font-size: 1.25em;
    font-weight: normal;
    letter-spacing: 0.03em;
    margin-bottom: 0.4em;
}

.DepositModal .accountChoice{
    box-sizing: border-box;
    font-size: 1.3em;
    letter-spacing: 0.05em;
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    padding: 0.6em 0;
    text-align: left;
    color: #b5b3b3;
}

.DepositModal .accountChoice .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #7e7e7e;
    color: #545454;
    margin-right: 0.6em;
    font-style: normal;
}

.DepositModal .accountChoice:first-of-type .icon{
    border: 1px solid #919191;
    background-color: #5d5d5d;
    color: #b6b6b6;
}

.DepositModal .accountChoice .icon svg{
    width: 24px;
    height: 24px;
}

.DepositModal .accountChoice.selected .icon{
    background: #999;
    color: #272626;
}

.DepositModal .accountChoice small{
    display: block;
    font-size: 0.75em;
    color: #8b8b8b;
}

.DepositModal form > .accountChoice small{
    margin-top: -0.2em;
}

.DepositModal form > label{
    // margin-top: 0.8em;
    // margin-bottom: 0.1em;
    color: #8a8a8a;
}

.DepositModal form > .accountChoice{
    padding: 0.2em 0.5em;
    background: #4a4a4a;
    margin-bottom: 0.7em;
}

.DepositModal form > .accountChoice:last-of-type{
    padding: 0.8em 0.5em;
}

.DepositModal form > .accountChoice:last-of-type span{
    font-size: 1.1em;
}

.DepositModal form > .accountChoice button{
    padding: 0.1em 0.3em;
    border: none;
    background: transparent;
    color: silver;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-size: 0.65em;
}

.DepositModal form > .finlink-btn{
    font-size: 0.93em !important;
    margin-top: 1.3em !important;
}

#addPaymentMethod .finlink-form-control-wrapper,
#addPaymentMethod .finlink-form-control.focused label, 
#addPaymentMethod .finlink-form-control.filled label, 
#addPaymentMethod .finlink-form-control.has-prepend label, 
#addPaymentMethod .finlink-form-control input[placeholder] ~ label {
    // top: -22px !important;
}

#addPaymentMethod .finlink-form-control-wrapper {
    margin-bottom: 45px !important;
}

#addPaymentMethod .finlink-form-control-wrapper + button{
    margin-top: -30px;
}

#addPaymentMethod .finlink-radio-button-group-wrapper {
    margin-bottom: 34px !important;
}

#addPaymentMethod .finlink-radio-button-group > label {
    // margin-bottom: 0px;
}

@media all and (max-width: 400px){
    .DepositModal {
        padding: 2em 0;
    }

    #DepositMoney .finlink-modal-content {
        padding: 1.5em 1em;
    }

    .DepositModal .logoImage {
        height: 50px;
    }

    .DepositModal .formTitle {
        font-size: 1.1em;
        // margin-bottom: 3em;
    }

    .DepositModal .form label {
        // font-size: 0.98em;
    }
    
    #pickPaymentMethodType label {
        // font-size: 0.98em;
    }

    .DepositModal .accountChoiceItem {
        font-size: 1em;
    }

    .DepositModal .accountChoiceTitle {
        font-size: 0.95em;
    }

    .DepositModal .accountChoice {
        font-size: 1.05em;
    }

    .DepositModal .accountChoice .icon {
        width: 33px;
        height: 33px;
    }

    .DepositModal .accountChoice .icon svg{
        width: 20px;
        height: 20px;
    }
}

@media (max-width: 330px) {
    .DepositModal {
        padding: 0.5em 0;
    }

    .DepositModal .formTitle {
        font-size: 1.1em;
        margin-bottom: 0.95em;
    }
}