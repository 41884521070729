#transferToInvestorForm .finlink-form-control,
#transferToInvestorForm .finlink-form-control input{
    background: transparent !important;
}

#transferToInvestorForm .finlink-form-control,
#transferToInvestorForm .finlink-form-control input{
    padding-left: 0;
}

#transferToInvestorForm .finlink-form-control label{
    font-size: 0.9rem;
    top: -17px !important;
}

#transferToInvestorForm .finlink-form-control.filled label {
    opacity: 0.6;
}

#transferToInvestorForm #mainAccountAvailableBalance{
    margin-top: -0.7rem;
    margin-bottom: 0.7rem;
    font-size: 1rem;
    letter-spacing: 0.04em;
    opacity: 0.85;
}

#SetTransferInvestor form{
    margin: 0 -0.4rem;
    padding-top: 4.5rem;
}

#SetTransferInvestor .finlink-modal-header .finlink-btn.action{
    margin-top: 0.2rem !important;
    outline: none;
}

#SetTransferInvestor .finlink-modal-title {
    color: #ddd
}

#SetTransferInvestor .finlink-form-control-wrapper{
    // margin-top: 0.8em;
    // margin-bottom: 2em !important;
}

#SetTransferInvestor .finlink-form-control,
#SetTransferInvestor .finlink-form-control input{
    padding-left: 0 !important;
    background: transparent !important;
    font-size: 1.2rem;
    letter-spacing: 0.02em;
}

#SetTransferInvestor .finlink-form-control label{
    top: -18px !important;
    font-size: 0.95rem;
}

#SetTransferInvestor .finlink-form-control .prepend{
    font-size: 1.2rem;
}

#SetTransferInvestor .finlink-form-control input::placeholder{
    // opacity: 0.5;
}

#SetTransferInvestor .RequestLoanSection > .finlink-btn.primary{
    font-size: 1.2rem;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
    border-radius: 0;
}


@media all and (max-width: 330px){
    #SetTransferInvestor .finlink-form-control .prepend,
    #SetTransferInvestor .finlink-form-control input {
        font-size: 1.45em;
    }

    #SetTransferInvestor .RequestLoanSection > .finlink-btn.primary {
        font-size: 1rem;
    }
}