.ot-header{
    width: 100vw;
    background: #383837;
    z-index: 5;
    padding: 0 0.5em;
    border-bottom: 1px solid #676767;
}

.ot-header.fixed{
    position: fixed;
    top: -2px;
}

.ot-header.raised{
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.14);
}

.ot-header.no-border{
    padding: 0;
    box-shadow: none;
    border: none;
}

.ot-header.no-border, 
.ot-header.no-border .top-bar{
    padding: 0;
}

.ot-header, 
.ot-header .top-bar{
    min-height: 60px;
}

.ot-header .top-bar{
    width: 100%;
}

.ot-header .ot-logo{
    height: 25px;
    margin-right: 0.4em;
}

.ot-header #backButton{
    margin-left: 13px;
    margin-right: -54px;
    width: 34px;
    height: 34px;
    position: relative;
    z-index: 1;
}

.ot-header #backButton svg{
    height: 30px !important;
    width: 30px !important;
    stroke: #ddd;
}

.ot-header button.action{
    background: transparent;
}

.ot-header button.action:not(#backButton){
    margin-left: -30px;
}

.ot-header button.action:not(#backButton) svg{
    height: 25px !important;
    width: 25px !important;
    fill: #bbb;
}

.ot-header button.action:not(#backButton):last-of-type{
    margin-right: -14px;
}

.ot-header-title{
    font-size: 1.35em;
    letter-spacing: 0.01em;
    font-weight: bold;
    color: #ddd;
    flex: 1;
    text-transform: capitalize;
}

.ot-header.has-back .ot-header-title{
    padding-left: 72px !important;
}

.ot-header.no-border .ot-header-title{
    padding-left: 0.8em;
}

#ot-header-tabs{
    width: 100%;
    padding: 0.8em 0;
    padding: 0.3em 0;
    padding-bottom: 1em;
    padding-left: 1em;
    border-bottom: 1px solid #444;
}

#ot-header-tabs:not(.center-center){
    padding-left: 1.4em;
}

#ot-header-tabs .ot-btn.flat{
    font-size: 0.95em;
    text-align: center;
    min-width: 0;
    padding: 8px 0;
    margin-right: 1.5em;
    color: #999;
    letter-spacing: 0.1em;
    background: transparent;
    border: none;
}

#ot-header-tabs .ot-btn.flat.active{
    color: #eaeaea;
}

#ot-header-tabs .separator{
    width: 6px;
    height: 6px;
    background: #aaa;
    border-radius: 50%;
    margin-right: 1.5em;
}

@media all and (max-width: 400px){
    .ot-header, .ot-header .top-bar {
        min-height: 52px;
    }

    .ot-header-title{
        font-size: 1.25em;
    }

    .ot-header .ot-logo{
        height: 28px;
    }
}

@media all and (max-width: 330px){
    .ot-header:not(.no-border){
        border-color: #4c4c4c;
    }

    .ot-header, .ot-header .top-bar {
        min-height: 47px;
    }

    .ot-header-title{
        font-size: 1.02rem;
    }

    .ot-header .ot-logo{
        height: 20px;
    }

    .ot-header #backButton{
        margin-left: 4px;
        margin-right: -60px;
        width: 24px;
        height: 24px;
        position: relative;
        z-index: 1;
    }
    
    .ot-header #backButton svg{
        height: 20px !important;
        width: 20px !important;
        stroke: #ddd;
    }
}