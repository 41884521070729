#GuarantorPool{
    overflow: hidden;
}

#GuarantorPool.balance-set .mainFragment{
    top: 65px;
}

#GuarantorPoolContent{
    padding: 1.3em 1em;
    overflow-y: auto;
}

#GuarantorPoolContent > h5{
    color: #ddd;
    margin-top: 0.6rem;
    margin-bottom: 1.2rem;
    font-size: 1.13rem;
    letter-spacing: 0.05em;
    font-weight: normal;
}

.guaranteeRequestsScreenMessage{
    color: #aaa;
    font-size: 1.3rem;
    padding: 0.5rem 0;
    text-align: center;
}

#GuarantorPoolContent > h5:not(:first-child){
    margin-top: 2.3rem;
}

#GuarantorPool.balance-set > .ot-header .top-bar{
    padding-bottom: 28px;
}

#GuarantorPool.balance-set > .ot-header, 
#GuarantorPool.balance-set > .ot-header .top-bar {
    min-height: 66px;
}

#GuarantorPool > .ot-header .top-bar .pos-a{
    font-size: 0.88rem;
    font-weight: 100;
    bottom: -23px;
}

#GuarantorPoolContent .loan-request-item{
    width: 100%;
    padding-top: 1.3rem;
    margin-bottom: 1.1rem;
    border-radius: 6px;
}

#GuarantorPoolContent .loan-request-item span.pos-a{
    border: none;
    padding: 0;
}

.guarantor-request-item{
    padding: 1.6rem;
}

.guarantor-request-item:not(:last-child){
    border-bottom: 1px solid #565555;
}

.guarantor-request-item h3{
    font-size: 1.36rem;
    color: #dfdfdf;
    letter-spacing: 0.03em;
    margin-bottom: 0.8rem;
}

.guarantor-request-item h3 small{
    font-size: 0.7em;
    margin-right: 0.4rem;
    // margin-bottom: 0.1rem;
}

.guarantor-request-item h5{
    font-size: 1.15rem;
    line-height: 1.55em;
    color: #bbb;
    font-weight: normal;
    letter-spacing: 0.015em;
}

.guarantor-request-item .pos-a{
    top: .45rem;
    right: 0.6rem;
}

.guarantor-request-item .finlink-btn.flat.rounded{
    font-size: 0.9rem;
    letter-spacing: 0.1em;
    padding: 0.5rem 0.85rem;
    padding-top: 0.4rem;
    color: #95e6c4;
    border: 1px solid #6e8f7f;
    background:#404340;
    margin-top: 1.1rem;
    text-transform: none;
}

.guarantor-request-item .finlink-btn.flat.rounded small{
    font-size: 2.3rem;
    line-height: 0;
    margin-right: 0.1rem;
    margin-top: 0.07rem;
}

#EnterGuaranteeAmount .loan-request-section-title{
    text-align: center;
    font-size: 1.5em;
    line-height: 1.6em;
    letter-spacing: 0.01rem;
    font-weight: normal;
    margin-bottom: 1.2rem;
    color: #e8e8e8;
}

#EnterGuaranteeAmount .finlink-form-control input{
    background: transparent !important;
    font-size: 2.3em;
    letter-spacing: 0.02em;
}

#EnterGuaranteeAmount .finlink-form-control input::placeholder{
    font-size: 0.7em;
}

#EnterGuaranteeAmount .finlink-form-control input:focus::placeholder{
    opacity: 0;
}

#EnterGuaranteeAmount .finlink-form-control-wrapper .form-control-error {
    text-align: center;
    list-style: none;
    padding: 0.4rem 0;
}

#EnterGuaranteeAmount form > p{
    font-size: 1.25rem;
    margin-top: -1.3rem;
    letter-spacing: 0.02em;
    color: #999;
}

#EnterGuaranteeAmount form > p#availableBalance{
    margin-top: 0.2rem;
    margin-bottom: 1rem;
}

#EnterGuaranteeAmount .finlink-btn.primary{
    font-size: 1.1rem;
    border-radius: 0;
}

@media all and (max-width: 330px){
    .guarantor-request-item{
        padding: 1.3rem 1rem;
        border-color: #4a4a4a !important;
    }

    .guarantor-request-item h3{
        font-size: 1.1rem;
        margin-bottom: 0.45rem;
    }

    .guarantor-request-item h3 small{
        font-size: 0.7em;
        margin-bottom: 0.05rem;
    }

    .guarantor-request-item h5{
        font-size: 0.85rem;
        line-height: 1.45em;
    }

    .guarantor-request-item .pos-a{
        top: 0.17rem;
        right: 0.6rem;
    }

    .guarantor-request-item .finlink-btn.flat.rounded{
        font-size: 0.7rem;
        padding: 0.4rem 0.65rem;
        padding-top: 0.25rem;
    }
}