.editProfile {
  // background: #252525;
  // background: linear-gradient(to bottom, #3e3e3f 0%, #323132 83%);
  padding: 2em;
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 4em;
}

.editProfile .form {
  margin: 0 auto;
  margin-bottom: 20px;
}

.editProfile .form p{
  font-size: 1.13em;
  line-height: 1.5em;
  margin-bottom: 2.9em;
  color: #bbbbbb;
  text-align: center;
  letter-spacing: 0.05em;
}

.editProfile .form button:not(.flat){
  margin-top: -0.6em;
  outline: none;
}

.editProfile .logoImage {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin-top: 0 !important;
  margin-bottom: 1.3em;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background: #bbb;
  color: #3c3c3d;
  font-size: 1.5em;
  letter-spacing: 0.04em;
  padding-top: 0.2em;
}

.editProfile .form {
  margin: 0 auto;
  margin-bottom: 20px;
}

.editProfile .formTitle {
  font-size: 1.4em;
  margin-top: 1em;
  margin-bottom: 2.5em;
  color: #9e9e9e;
  letter-spacing: 0.1em;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}

@media all and (max-width: 400px){
  .editProfile .editProfile {
    padding: 2em 1.5em;
  }
}

@media (max-width: 400px){
}

@media (max-width: 330px){
  .editProfile .form p {
    margin-bottom: 1.8em;
  }
}