.addAcountForm{
    padding-top: 22px;
}

.addAcountForm .finlink-form-control.focused label, 
.addAcountForm .finlink-form-control.filled label, 
.addAcountForm .finlink-form-control.has-prepend label, 
.addAcountForm .finlink-form-control input[placeholder] ~ label {
    top: -24px;
}

.addAcountForm .finlink-radio-button-group{
    margin-top: -10px;
}

.addAcountForm .finlink-radio-button-group + .finlink-radio-button-group{
    margin-top: -30px;
    margin-bottom: 25px;
}

.addAcountForm .finlink-radio-button-group > label {
    margin-bottom: 5px;
}

.addAcountForm .finlink-btn.block{
    margin-top: -15px;
}