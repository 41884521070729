.loan-preview-section:first-of-type{
    margin-top: -1rem;
}

.loan-preview-section h3{
    text-transform: uppercase;
    font-size: 1.14rem;
    margin-bottom: 0.4rem;
    letter-spacing: 0.07rem;
    color: #a4dfdb;
    color: #a8a8a8;
    font-weight: 100;
}

.loan-preview-section ul{
    margin: 0;
    padding: 0;
}

.loan-preview-section:not(:last-of-type) ul{
    margin-bottom: 2em;
}

.loan-preview-section ul.loanInterestRate{
    margin-bottom: 1.3em;
}

.loan-preview-section li{
    list-style: none;
    padding: 0.75rem 0;
    margin-bottom: 0.3rem;
}

.loan-preview-section h5{
    color: #e4e4e4;
    font-size: 1.45rem;
    line-height: 1.45rem;
    font-weight: normal;
    margin-bottom: 0.1rem;
}

.loan-preview-section p{
    font-size: 1.25rem;
    line-height: 1.8rem;
    font-weight: 300;
    margin-bottom: 0;
    color: #bababa;
    margin-top: 0.3rem;
    letter-spacing: 0.02rem;
}

.loan-preview-section .OutlineButton {
    text-transform: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.75rem;
    padding-right: 0.8rem;
    padding-bottom: 0.1rem;
    height: 35px;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.07rem;
    background: transparent;
    margin-right: -0.4rem;
    color: #aad5d2 !important;
    /* border: none !important; */
    background: transparent !important;
    border-color: #828d8c5e !important;
}

.loan-preview-section ul + .OutlineButton{
    margin-top: 0.6rem;
    margin-bottom: 1.6rem;
}

.loan-preview-section .OutlineButton svg{
    width: 14px;
    height: 14px;
    margin-right: 0.45rem;
    fill: currentColor;
    position: relative;
}

#Profile .OutlineButtony{
    margin: 0.3rem 0;
    margin-right: 0.5rem;
    padding: 0.35rrem 0.8rrem;
    height: auto;
    border-width: 1px;
    font-size: 0.95rrem;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.03rem;
    border-radius: 6px;
    border-width: 2px;
}

@media all and (max-width: 330px){
    .loan-preview-section {
        padding: 1.5rem 1.3rem;
        padding-bottom: 1rem;
    }

    .loan-preview-section h3{
        font-size: 0.95rem;
    }
    
    .loan-preview-section h5{
        font-size: 1.3rem;
        line-height: 1.45rem;
    }

    .loan-preview-section:first-of-type h5 {
        font-size: 1.65rem;
    }

    .loan-preview-section p {
        font-size: 1.15rem;
    }

    .loan-preview-section .OutlineButtony{
        padding: 0 0.85rem;
        padding-right: 1rem;
        height: 28px;
        min-width: 0;
        font-size: 12px;
        line-height: 1;
        margin-top: 0.5rem;
        margin-bottom: 0.8rem;
    }
    
    .loan-preview-section .OutlineButtony svg{
        width: 12px;
        height: 12px;
    }
}