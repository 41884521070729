.finlink-modal{
    position: fixed;
    z-index: 1000;
    background: #fff;
    background: #383837;
    background: #3e3e3d;
    color: #bbb;
    line-height: 1.8em;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    overflow-y: auto;
    padding-top: 60px;
}

.finlink-modal-header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    padding: 1em 1.5em;
    padding-right: 1em;
    z-index: 1;
    background: #fff;
    border-bottom: 1px solid #ddd;
    background: #252525;
    border-bottom: 1px solid #525252;
    background: #383837;
    color: #bbb;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.finlink-modal-title{
    font-size: 1.5em;
    letter-spacing: 0.03em;
    color: #fff;
}

.finlink-modal .finlink-modal-header .finlink-btn.action{
    width: 32px;
    height: 32px;
    background: rgba(255, 255, 255, 0.18) !important;
    color: #dadada;
}

.finlink-modal .finlink-modal-header .finlink-btn.action svg:not(.stroked){
    fill: #dadada !important;
}

.finlink-modal-content{
    padding: 1.5em;
}

#saveBtn{
    font-size: 1em;
    border-radius: 6px;
    text-transform: none;
    padding: 0.3em;
    padding-left: 12px;
    padding-right: 12px;
}

#closeBtn + .finlink-modal-title {
    margin-left: 1em;
}

@media all and (max-width: 400px){
    .finlink-modal-header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 60px;
        padding: 1em;
        padding-right: 0.8em;
    }

    #saveBtn {
        font-size: 0.8em;
    }
}

@media (max-width: 330px){
    .finlink-modal{
        padding-top: 50px;
    }

    .finlink-modal-header {
        height: 50px;
    }
    
    .finlink-modal-title{
        font-size: 1.1rem;
    }

    .finlink-modal .finlink-modal-header .finlink-btn.action {
        width: 24px;
        height: 24px;
    }

    .finlink-modal .finlink-modal-header .finlink-btn.action svg:not(.stroked){
        width: 18px;
        height: 18px;
    }
}