.finlink-checkbox{
  position:relative; 
  margin-bottom:45px;
  border-bottom: 1px solid transparent;
  padding-left: 40px;
}

.finlink-checkbox input{
  display: none;
}

.finlink-checkbox:before,
.finlink-checkbox label:first-of-type{
  // content: '';
  position: absolute;
  left: 0;
  top: 0.3em;
  width: 40px;
  height: 40px;
  background-position: top left;
  background-size: 30px;
  background-repeat: no-repeat;
}

.finlink-checkbox label {
  color: #aaa;
  font-size: 1.1em;
  line-height: 1.5em;
}

.finlink-checkbox label a{
  color: #3aa678;
}

@media all and (max-width: 400px){
  .finlink-checkbox label {
    color: #aaa;
    font-size: 0.95em;
    line-height: 1.35em;
  }
}