.finlink-radio-button input{
  display: none;
}

.finlink-radio-button label {
  display: inline-flex;
  align-items: center;
  color: #aaa;
  font-size: 1.1em;
  line-height: 1em;
  border: 1px solid #aaa;
  border-radius: 1.2em;
  padding: 0.3em 0.4em;
  padding-right: 0.8em;
  outline: none;
}

.finlink-radio-button.checked label,
.finlink-radio-button label:focus{
  border-color: #fff;
  background: #444444;
}

.finlink-radio-button svg {
  width: 24px;
  height: 24px;
  margin-right: 0.3em;
}

.finlink-radio-button svg circle{
  cx: 12;
  cy: 12;
  r: 10;
  fill: transparent;
  stroke: #aaa;
  stroke-width: 2;
}

.finlink-radio-button.checked svg circle{
  fill: #aaa;
}

@media all and (max-width: 400px){
  .finlink-radio-button label {
    font-size: 0.9em;
  }

  .finlink-radio-button svg {
    width: 20px;
    height: 20px;
    margin-right: 0.3em;
  }
}

@media all and (max-width: 330px){
  .finlink-radio-button label {
    font-size: 0.9em;
    letter-spacing: 0.03em;
    line-height: 1em;
    border: 1px solid #aaa;
    border-radius: 1.2em;
    padding: 0.5em 0.6em;
    padding-right: 0.8em;
  }

  .finlink-radio-button.checked label{
    color: #cecece;
  }
  
  .finlink-radio-button svg {
    width: 18px;
    height: 18px;
    width: 13px;
    height: 13px;
    margin-right: 0.3em;
  }
}