#Notifications{
    overflow: hidden;
}

#NotificationsContent{
    overflow-y: auto;
}

.notification-item{
    padding: 1.2rem;
}

.notification-item.unread{
    background: #424242;
}

.notification-item + .notification-item{
    border-top: 1px solid #4d4d4d;
    border-top: 1px solid #565656;
}

.notification-item .icon{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    flex-shrink: 0;
    background: #555;
    margin-top: 0.3rem;
    margin-right: 1.2rem;
    color: #fff;
}

.notification-item .icon svg{
    width: 22px;
    height: 22px;
    fill: currentColor;
}

.notification-item h5{
    font-size: 1.15rem;
    line-height: 1.55em;
    color: #bfbfbf;
}

.notification-item span{
    top: 1.57rem;
    right: 0.9rem;
    font-size: 0.8rem;
    letter-spacing: 0.12em;
    padding: 0.3rem 0.7rem;
    padding-bottom: 0.5rem;
    color: #c8c8c8;
    border: 1px solid #555;
    border: 1px solid #999;
    border-radius: 20px;
}

.notification-item.rejected span{
    color: #ffafaf;
}

.notification-item.approved span{
    color: #95e6c4;
}

.notification-item span small{
    font-size: 2.3rem;
    line-height: 0;
    margin-right: 0.1rem;
    margin-top: 0.07rem;
}

@media all and (max-width: 330px){
    #NotificationsContent{
        padding: 0.8rem;
    }

    .notification-item{
        padding: 1rem 0;
    }
    
    .notification-item + .notification-item{
        border-top: 1px solid #4d4d4d;
    }
    
    .notification-item .icon{
        width: 40px;
        height: 40px;
        margin-top: 0.15rem;
        margin-right: 0.8rem;
    }
    
    .notification-item .icon svg{
        width: 19px;
        height: 19px;
    }

    .notification-item h5{
        font-size: 1rem;
        line-height: 1.45em;
    }

    .notification-item span{
        top: 0.9rem;
        right: 0.6rem;
        font-size: 0.6rem;
        color: #aaa;
        border: 1px solid #777;
    }

    .notification-item span small{
        display: none;
    } 
}