#Slides{
    height: 100vh;
    width: 100vw;
}

#SlidesProgress{
    top: 0;
    left: 0;
    height: 2px;
    background: #000;
}

#SlidesProgress:before,
#SlidesProgress:after{
    content: '';
    position: absolute;
    height: inherit;
    background: inherit;
}

#SlidesProgress:before{
    width: 100%;
    background: #ccc;
}

#SlidesProgress:after{
    z-index: 1;
    left: 0;
    right: 0;
    transition: width 0.35s ease-out;
}

#SlidesCurrentStepLabel{
    text-transform: uppercase;
    top: 0;
    left: 0;
    right: 0;
    font-size: 1rem;
    letter-spacing: 0.05em;
    color: #ccc;
    color: #b3dcd9;
    background: #3e3e3d;
    padding: 0.85rem 1.4rem;
    z-index: 10;
    transition: opacity 0.2s ease-in;
}

#SlidesCurrentStepLabel:not(.visible){
    opacity: 0;
    transition: opacity 0.1s ease-out;
}

#SlidesCurrentStepLabel.centered{
    // left: 50%;
    // transform: translateX(-50%);
    text-align: center;
}

#SlidesContent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    bottom: 106px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 20px;
}

#Slides.no-actions:not(.show-submit-button) #SlidesContent{
    bottom: 50px;
}

#Slides:not(.no-slide-animation) #SlidesContent > div{
    transition: transform 0.35s ease-out;
}

#SlidesContent > div > *{
    flex-shrink: 0;
    width: 100vw;
}

#SlidesActions{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 56px;
    padding: 0.4em 1em;
    background: #484846;
    will-change: transform;
    transition: transform 0.35s ease-out;
}

#Slides.no-actions #SlidesActions{
    transform: translateY(110%);
}

.SlideBackButton{
    color: #ebebeb !important;
}

.SlideBackButton span{
    display: flex;
    align-items: center;
}

.SlideBackButton svg{
    position: relative;
    fill: currentColor;
}

#SlidesActions button.disabled{
    opacity: 0.6;
    pointer-events: none;
}

#Slides .SubmitButton{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 56px;
}

#Slides .SubmitButton button{
    font-size: 1.2rem;
    border-radius: 0;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 330px){
    #SlidesCurrentStepLabel {
        font-size: 0.9rem;
        padding: 0.5rem 1.1rem;
    }

    #SlidesContent{
        bottom: 96px;
    }

    #SlidesActions {
        height: 46px;
        padding: 0 0.4em;
    }
}