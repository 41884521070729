#LoanRequests{
    overflow: hidden;
}

#LoanRequestContent{
    padding: 1rem 1.2rem;
    overflow-y: auto;
}

.loanRequestsScreenMessage{
    color: #aaa;
    font-size: 1.3rem;
    padding: 0.5rem 0;
    text-align: center;
}

.loan-request-item{
    background: #424241;
    border: 2px solid #717070;
    padding: 1.5rem;
    border-radius: 8px;
    margin-bottom: 0.85rem;
}

.loan-request-item h3{
    font-size: 1.36rem;
    color: #fff;
    letter-spacing: 0.03em;
    margin-bottom: 0.5rem;
}

.loan-request-item h3 small{
    font-size: 0.7em;
    margin-right: 0.4rem;
    margin-bottom: 0.13rem;
}

.loan-request-item h5{
    font-size: 1.15rem;
    line-height: 1.55em;
    color: #bfbfbf;
    letter-spacing: 0.015em;
}

.loan-request-item span{
    top: 1.57rem;
    right: 0.9rem;
    font-size: 0.8rem;
    letter-spacing: 0.12em;
    padding: 0.3rem 0.7rem;
    padding-bottom: 0.5rem;
    color: #c8c8c8;
    border: 1px solid #555;
    border: 1px solid #999;
    border-radius: 20px;
}

.loan-request-item.rejected span{
    color: #ffafaf;
}

.loan-request-item.approved span{
    color: #95e6c4;
}

.loan-request-item span small{
    font-size: 2.3rem;
    line-height: 0;
    margin-right: 0.1rem;
    margin-top: 0.07rem;
}

@media all and (max-width: 330px){
    #LoanRequestContent{
        padding: 0.8rem;
    }

    .loan-request-item{
        padding: 1rem;
        margin-bottom: 0.85rem;
    }

    .loan-request-item h3{
        font-size: 1.2rem;
        margin-bottom: 0.45rem;
    }

    .loan-request-item h3 small{
        font-size: 0.7em;
        margin-bottom: 0.05rem;
    }

    .loan-request-item h5{
        font-size: 1rem;
        line-height: 1.45em;
    }

    .loan-request-item span{
        top: 0.9rem;
        right: 0.6rem;
        font-size: 0.6rem;
        color: #aaa;
        border: 1px solid #777;
    }

    .loan-request-item span small{
        display: none;
    } 
}