.FAB{
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    padding: 0;
    outline: none;
}

.FAB.mini{
    width: 44px;
    height: 44px;
}

.FAB.raised{
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
}

.FAB svg{
    position: relative !important;
    width: 24px;
    height: 24px;
    fill: currentColor
}

.FAB.mini svg{
    width: 20px;
    height: 20px;
}

@media all and (max-width: 330px){
    .FAB.mini{
        width: 34px;
        height: 34px;
    }

    .FAB.mini svg{
        width: 16px;
        height: 16px;
    }
}