.finlink-toast-notifications {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center; }

.finlink-toast {
  padding: 0.6em 1.3em;
  border: 1px solid #535353;
  background: #1b1b1b;
  color: #d2d2d2;
  background: #d6d6d6;
  color: #272727;
  background: #b7b7b7;
  color: #444444;
  border-radius: 20px;
  line-height: 1.1em;
  letter-spacing: 0.03em;
  max-width: 280px;
  -webkit-animation: hideToast 0.2s ease-out 1.5s forwards;
          animation: hideToast 0.2s ease-out 1.5s forwards; }

@-webkit-keyframes hideToast {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes hideToast {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.finlink-alert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center; }

.finlink-alert-content {
  position: relative;
  background: #fff;
  background: #515151;
  border-radius: 8px;
  padding: 2em 1.5em;
  pointer-events: auto;
  margin: 0 1.5em;
  flex: 1 1;
  box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.4); }

.finlink-alert-content h2 {
  font-size: 1.4em;
  line-height: 1.2em;
  margin-bottom: 0.6em;
  margin-top: 1em;
  margin-top: 0.25em;
  color: #d8d8d8; }

.finlink-alert-content p.alert-body {
  font-size: 0.98em;
  line-height: 1.5em;
  /* margin-top: 1em; */
  margin-bottom: 0.95em;
  letter-spacing: 0.02em;
  color: #c2c2c2; }

.finlink-alert-content h2 + p.alert-body {
  font-size: 1em;
  line-height: 1.4em;
  color: #c2c2c2;
  margin-top: 0; }

.finlink-alert-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.85em;
  margin-bottom: -0.6em; }

.finlink-alert-buttons button {
  letter-spacing: 0.1em !important;
  font-size: 1.2em !important;
  text-transform: none; }

.finlink-alert-buttons button:not(:last-child) {
  margin-right: 1.5em;
  margin-right: 0.8em; }

.finlink-alert-buttons button:not(.primary) {
  color: #bcbcbc; }

@media (max-width: 400px) {
  .finlink-alert-content {
    margin: 0 1em; }
  .finlink-alert-content h2 {
    font-size: 1.2em;
    margin-bottom: 0.55em; }
  .finlink-alert-content p.alert-body {
    font-size: 0.98em; }
  .finlink-alert-content h2 + p.alert-body {
    font-size: 0.85em; }
  .finlink-alert-buttons {
    margin-right: -0.5em;
    margin-top: 0;
    margin-bottom: -0.9em; }
  .finlink-alert-buttons button {
    font-size: 0.93em !important;
    letter-spacing: 0.05em !important; } }

.finlink-btn{
    white-space: nowrap;
    top: 0px;
    border-radius: 4px;
    position: relative;
    border: none;
    background: #ddd;
    cursor: pointer;
    font-size: 0.93rem;
    font-weight: normal;
    color: #777;
    border-bottom: 1px solid #5b6566;
    box-shadow: 0px 0px 0px 0px #b8b8b8;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    padding: 0.6rem 1.5rem;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.finlink-btn span{
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.finlink-btn[disabled] span,
.finlink-btn.disabled span{
    opacity: 0.5;
    pointer-events: none;
}

.finlink-btn[loading] span,
.finlink-btn.loading span{
    opacity: 0;
}

.finlink-btn.action svg,
.finlink-btn.fab svg,
.finlink-btn svg.loader-icon{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.finlink-btn.disabled{
    pointer-events: none;
}

.finlink-btn svg.loader-icon{
    -webkit-transform: scale(0.8);
            transform: scale(0.8)
}

.finlink-btn svg.loader-icon circle{
    stroke-width: 6;
}

.finlink-btn.primary{
    background: #00f0f3;
    color: #133334;
}

.finlink-btn:not(.rounded).primary.disabled,
.finlink-btn:not(.rounded).primary.loading{
    background: #65abac;
}

.finlink-btn.block{
    width: 100%;
}

.finlink-btn.large{
    font-size: 1em;
}

.finlink-btn[disabled],
.finlink-btn.disabled,
.finlink-btn[loading],
.finlink-btn.loading{
    pointer-events: none;
}

.finlink-btn.flat{
    background: transparent;
    border: none;
    box-shadow: none;
    padding: 8px;
    font-size: 14px;
    letter-spacing: 0.03em;
    color: #999;
}

.finlink-btn.action{
    padding: 0;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.1);
    box-shadow: none;
    outline: none;
    width: 28px;
    height: 28px;
    font-size: 1px;
}

.finlink-btn.action.disabled{
    opacity: 0.5;
}

.finlink-btn.action svg{
    width: 20px;
    height: 20px;
}

.finlink-btn.fab{
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--accent-color);
    box-shadow: none;
    padding: 0;
    outline: none;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    position: fixed;
    right: 16px;
    bottom: 16px;
    z-index: 50;

    -webkit-transition: -webkit-transform 0.35s ease-out;

    transition: -webkit-transform 0.35s ease-out;

    transition: transform 0.35s ease-out;

    transition: transform 0.35s ease-out, -webkit-transform 0.35s ease-out;
}

.finlink-btn.fab svg{
    width: 24px;
    height: 24px;
    fill: #fff;
}

.finlink-btn.inverse{
    background: #464646;
    border-color: #3a3a3a;
    color: #b3b3b3;
}

.finlink-btn.inverse svg circle{
    stroke-width: 5;
    stroke: #747474;
}

.finlink-btn.flat.primary{
    color: #00f0f3;
}

.finlink-btn.flat.accent{
    color: #3be299;
    border-color: #33a073 !important;
}

.finlink-btn.rounded{
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    border: 2px solid transparent;
}

.finlink-btn.rounded.flat:not(.primary){
    border-color: #777;
}

.finlink-btn.rounded.flat.primary{
    color: #00f0f3;
    border-color: #47c2c4;
}

.finlink-btn:not(.flat).rounded.primary[disabled],
.finlink-btn:not(.flat).rounded.primary.disabled,
.finlink-btn:not(.flat).rounded.primary[loading],
.finlink-btn:not(.flat).rounded.primary.loading{
    background-color: #65abac;
    border-color: #65abac;
}

.finlink-btn.rounded.flat.primary[disabled],
.finlink-btn.rounded.flat.primary.disabled,
.finlink-btn.rounded.flat.primary[loading],
.finlink-btn.rounded.flat.primary.loading{
    background: rgba(255, 255, 255, 0.06);
    border-color: #698889;
}

.finlink-btn.rounded.flat.primary svg circle{
    stroke-width: 6;
    stroke: #97abab;
}

@media all and (max-width: 400px){
    .finlink-btn{
        padding: 0.6rem 2rem;
    }
}
.FAB {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  padding: 0;
  outline: none; }

.FAB.mini {
  width: 44px;
  height: 44px; }

.FAB.raised {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3); }

.FAB svg {
  position: relative !important;
  width: 24px;
  height: 24px;
  fill: currentColor; }

.FAB.mini svg {
  width: 20px;
  height: 20px; }

@media all and (max-width: 330px) {
  .FAB.mini {
    width: 34px;
    height: 34px; }
  .FAB.mini svg {
    width: 16px;
    height: 16px; } }

.OutlineButton {
  font-size: 18px;
  border: 2px solid #636363 !important;
  color: #bebebe !important;
  background: transparent !important;
  background: #464746 !important;
  box-shadow: none;
  padding: 0.95em 2em; }

@media all and (max-width: 330px) {
  .OutlineButton {
    font-size: 14px;
    padding: 0.6em 2em; } }

.finlink-notifications {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  pointer-events: none; }

.bailOutButton {
  position: fixed;
  top: 1em;
  right: 1em;
  width: 36px;
  height: 36px;
  background-color: rgba(255, 255, 255, 0.25) !important; }

.authScene {
  background: #252525;
  background: -webkit-gradient(linear, left top, left bottom, from(#3e3e3f), color-stop(83%, #323132));
  background: linear-gradient(to bottom, #3e3e3f 0%, #323132 83%);
  padding: 2em;
  height: 100vh;
  overflow-y: auto; }

.logoImage {
  display: block;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 1.5em;
  text-transform: uppercase; }

.authScene.center .logoImage {
  margin-top: 0 !important; }

.logoImage.circle {
  width: 70px; }

.confirmScene .logoImage {
  height: 70px;
  border-radius: 50%;
  background: #bbb;
  color: #3c3c3d;
  font-size: 1.5em;
  letter-spacing: 0.04em;
  display: flex; }

.logoImage svg {
  width: 35px;
  height: 35px;
  fill: #bbb;
  stroke: #777; }

.logoImage.circle.success {
  background: #e2f3eb; }

.logoImage.circle.success svg {
  fill: transparent;
  stroke: #2db87e; }

.authScene .form {
  margin: 0 auto;
  margin-bottom: 20px; }

.formTitle {
  font-size: 1.4em;
  margin-top: 1em;
  margin-bottom: 2em;
  color: #c5c5c5;
  letter-spacing: 0.1em;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase; }

.authScene .form p {
  font-size: 1.13em;
  line-height: 1.5em;
  margin-bottom: 2.9em;
  color: #bbbbbb;
  text-align: center;
  letter-spacing: 0.05em; }

.authScene .form hr {
  border-width: 1px 0 0 0;
  width: 50%;
  max-width: 170px;
  margin: auto;
  border-color: #adadad; }

.authScene .form p + hr {
  margin-top: -0.35em;
  margin-bottom: 0.8em; }

.authScene .form button:not(.flat) {
  margin-top: -0.6em;
  outline: none; }

.forgotPwd {
  display: block;
  margin-top: 1.4em;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.9em;
  letter-spacing: 0.08em;
  color: #3aa777; }

.siblingPageLink {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.5em;
  /* text-transform: uppercase; */
  font-size: 1em;
  letter-spacing: 0.04em;
  color: #ddd; }

.siblingPageLink a {
  color: #3be299;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  font-weight: bold; }

.siblingPageLink button.flat {
  letter-spacing: 0.08em;
  font-size: 0.9em; }

.confirmScene .finlink-radio-button-group-wrapper {
  margin-bottom: 27px; }

.forgotScene .formTitle {
  margin-bottom: 1.1em; }

@media all and (max-width: 400px) {
  .authScene {
    padding: 2em 1.5em; }
  .logoImage {
    height: 50px; }
  .formTitle {
    font-size: 1.1em;
    margin-bottom: 3em; }
  .forgotScene .formTitle {
    margin-bottom: 0.8em; }
  .authScene .form p {
    font-size: 0.95em;
    line-height: 1.45em;
    color: #a1a1a1;
    letter-spacing: 0.02em; }
  .siblingPageLink a {
    color: #3be299;
    letter-spacing: 0.04em;
    font-weight: bold;
    font-size: 0.9em; } }

@media (max-width: 400px) {
  .confirmScene {
    padding: 0.5em 1.5em; }
  .confirmScene .formTitle {
    font-size: 1.1em;
    margin-bottom: 1.1em; }
  .confirmScene .form p {
    margin-bottom: 2.3em; }
  .confirmScene .finlink-radio-button-group-wrapper {
    margin-bottom: 38px; }
  .confirmScene .finlink-form-control-wrapper {
    margin-bottom: 45px; }
  .authScene .form .finlink-form-control.has-prepend label,
  .authScene .form .finlink-form-control input[placeholder] ~ label {
    top: -10px !important; } }

@media (max-width: 330px) {
  .confirmScene {
    padding: 0.5em 1.5em; }
  .confirmScene .formTitle {
    font-size: 1.1em;
    margin-bottom: 0.95em; }
  .confirmScene .finlink-radio-button-group-wrapper {
    margin-bottom: 35px; }
  .confirmScene .finlink-form-control-wrapper {
    margin-bottom: 35px; }
  .authScene .form p {
    margin-bottom: 1.8em; } }

.finlink-form-control-wrapper {
  margin-bottom: 50px; }

.finlink-form-control-wrapper.solo {
  margin-bottom: 0 !important; }

.finlink-form-control-wrapper .form-control-errors {
  margin-top: 0.5em; }

.finlink-form-control-wrapper .form-control-error {
  font-size: 0.8em;
  color: #ca7777;
  text-transform: lowercase;
  line-height: 1.3em;
  list-style-type: disc; }

.finlink-form-control-wrapper .form-control-hint:before {
  content: 'HINT:';
  font-weight: bold;
  margin-right: 0.3em;
  letter-spacing: 0.06em;
  color: #bbb; }

.finlink-form-control-wrapper .form-control-hint {
  display: block;
  margin-top: 0.5em;
  color: #999;
  line-height: 1.3em;
  letter-spacing: 0.03em; }

.finlink-form-control {
  position: relative;
  border-bottom: 1px solid transparent; }

.finlink-form-control.disabled {
  pointer-events: none;
  opacity: 0.6; }

.finlink-form-control:not(.focused) {
  border-bottom-color: #757575; }

.finlink-form-control:not(.focused).has-errors {
  border-bottom-color: #ca7777; }

.finlink-form-control.has-prepend {
  display: flex;
  align-items: center; }

.finlink-form-control.has-prepend.filled {
  background: #474747;
  padding-left: 7px; }

.finlink-form-control.has-prepend.filled.has-errors {
  background: #625a5a; }

.finlink-form-control .prepend {
  position: relative;
  align-items: center;
  color: #3aa678;
  font-size: 1em;
  padding: 0.1em 0;
  padding-right: 0.5em;
  margin-right: 0.5em;
  border-right: 1px solid #777;
  line-height: 0.8em; }

.finlink-form-control input {
  font-size: 1.1em;
  padding: 10px 10px 10px 0;
  display: block;
  width: 100%;
  border: none;
  background: transparent;
  color: #aaa;
  outline: none;
  -webkit-tap-highlight-color: transparent; }

.finlink-form-control.text-center input {
  text-align: center; }

.finlink-form-control:not(.has-prepend).filled input {
  background: #474747;
  padding: 10px; }

.finlink-form-control:not(.has-prepend).filled.has-errors input {
  background: #625a5a; }

.finlink-form-control input::-moz-selection {
  background: #e0fff2;
  color: #4d4d4d; }

.finlink-form-control input::selection {
  background: #e0fff2;
  color: #4d4d4d; }

.finlink-form-control input:focus {
  outline: none; }

.finlink-form-control label {
  color: #ccc;
  font-size: 18px;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: normal;
  display: block;
  -webkit-transition: 0.2s ease all;
  transition: 0.2s ease all;
  text-transform: uppercase;
  outline: none; }

/* active state */
.finlink-form-control.focused label,
.finlink-form-control.filled label,
.finlink-form-control.has-prepend label,
.finlink-form-control input[placeholder] ~ label {
  font-size: 14px;
  color: #ccc; }

.finlink-form-control.has-errors.focused label,
.finlink-form-control.has-errors.filled label,
.finlink-form-control.has-errors.has-prepend label,
.finlink-form-control.has-errors input[placeholder] ~ label {
  color: #ca7777 !important; }

.finlink-form-control .bar {
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%; }

.finlink-form-control.has-prepend .bar {
  bottom: -1.5px; }

.finlink-form-control .bar:before,
.finlink-form-control .bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #ccc;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all; }

.finlink-form-control.has-errors .bar:before,
.finlink-form-control.has-errors .bar:after {
  background: #ca7777; }

.finlink-form-control .bar:before {
  left: 50%; }

.finlink-form-control .bar:after {
  right: 50%; }

.finlink-form-control input:focus ~ .bar:before,
.finlink-form-control input:focus ~ .bar:after {
  width: 50%; }

@media (max-width: 400px) {
  .finlink-form-control-wrapper {
    margin-bottom: 38px; }
  .finlink-form-control label {
    font-size: 14px;
    top: 14px; }
  .finlink-form-control.focused label,
  .finlink-form-control.filled label,
  .finlink-form-control.has-prepend label,
  .finlink-form-control input[placeholder] ~ label {
    top: -14px;
    font-size: 11px;
    color: #ccc; } }

.finlink-checkbox {
  position: relative;
  margin-bottom: 45px;
  border-bottom: 1px solid transparent;
  padding-left: 40px; }

.finlink-checkbox input {
  display: none; }

.finlink-checkbox:before,
.finlink-checkbox label:first-of-type {
  position: absolute;
  left: 0;
  top: 0.3em;
  width: 40px;
  height: 40px;
  background-position: top left;
  background-size: 30px;
  background-repeat: no-repeat; }

.finlink-checkbox label {
  color: #aaa;
  font-size: 1.1em;
  line-height: 1.5em; }

.finlink-checkbox label a {
  color: #3aa678; }

@media all and (max-width: 400px) {
  .finlink-checkbox label {
    color: #aaa;
    font-size: 0.95em;
    line-height: 1.35em; } }

.finlink-radio-button-group-wrapper {
  margin-bottom: 45px; }

.finlink-radio-button-group-wrapper .form-control-error {
  font-size: 0.8em;
  color: #ca7777;
  text-transform: lowercase;
  line-height: 1.3em;
  list-style-type: disc; }

.finlink-radio-button-group {
  position: relative; }

.finlink-radio-button-group > div {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.finlink-radio-button-group.align-center > div {
  justify-content: center; }

.finlink-radio-button-group .finlink-radio-button {
  margin-right: 0.5em;
  margin-bottom: 1em; }

.finlink-radio-button-group > label {
  color: #999;
  font-size: 18px;
  font-size: 14px;
  color: #ccc;
  margin-bottom: 10px;
  margin-bottom: 14px;
  display: block;
  letter-spacing: 1px;
  font-weight: normal;
  -webkit-transition: 0.2s ease all;
  transition: 0.2s ease all;
  text-transform: uppercase;
  outline: none; }

.finlink-radio-button-group.has-errors > label {
  color: #ca7777; }

@media (max-width: 400px) {
  .finlink-radio-button-group-wrapper {
    margin-bottom: 20px; } }

.finlink-radio-button input {
  display: none; }

.finlink-radio-button label {
  display: inline-flex;
  align-items: center;
  color: #aaa;
  font-size: 1.1em;
  line-height: 1em;
  border: 1px solid #aaa;
  border-radius: 1.2em;
  padding: 0.3em 0.4em;
  padding-right: 0.8em;
  outline: none; }

.finlink-radio-button.checked label,
.finlink-radio-button label:focus {
  border-color: #fff;
  background: #444444; }

.finlink-radio-button svg {
  width: 24px;
  height: 24px;
  margin-right: 0.3em; }

.finlink-radio-button svg circle {
  cx: 12;
  cy: 12;
  r: 10;
  fill: transparent;
  stroke: #aaa;
  stroke-width: 2; }

.finlink-radio-button.checked svg circle {
  fill: #aaa; }

@media all and (max-width: 400px) {
  .finlink-radio-button label {
    font-size: 0.9em; }
  .finlink-radio-button svg {
    width: 20px;
    height: 20px;
    margin-right: 0.3em; } }

@media all and (max-width: 330px) {
  .finlink-radio-button label {
    font-size: 0.9em;
    letter-spacing: 0.03em;
    line-height: 1em;
    border: 1px solid #aaa;
    border-radius: 1.2em;
    padding: 0.5em 0.6em;
    padding-right: 0.8em; }
  .finlink-radio-button.checked label {
    color: #cecece; }
  .finlink-radio-button svg {
    width: 18px;
    height: 18px;
    width: 13px;
    height: 13px;
    margin-right: 0.3em; } }

.finlink-modal {
  position: fixed;
  z-index: 1000;
  background: #fff;
  background: #383837;
  background: #3e3e3d;
  color: #bbb;
  line-height: 1.8em;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow-y: auto;
  padding-top: 60px; }

.finlink-modal-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  padding: 1em 1.5em;
  padding-right: 1em;
  z-index: 1;
  background: #fff;
  border-bottom: 1px solid #ddd;
  background: #252525;
  border-bottom: 1px solid #525252;
  background: #383837;
  color: #bbb;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.finlink-modal-title {
  font-size: 1.5em;
  letter-spacing: 0.03em;
  color: #fff; }

.finlink-modal .finlink-modal-header .finlink-btn.action {
  width: 32px;
  height: 32px;
  background: rgba(255, 255, 255, 0.18) !important;
  color: #dadada; }

.finlink-modal .finlink-modal-header .finlink-btn.action svg:not(.stroked) {
  fill: #dadada !important; }

.finlink-modal-content {
  padding: 1.5em; }

#saveBtn {
  font-size: 1em;
  border-radius: 6px;
  text-transform: none;
  padding: 0.3em;
  padding-left: 12px;
  padding-right: 12px; }

#closeBtn + .finlink-modal-title {
  margin-left: 1em; }

@media all and (max-width: 400px) {
  .finlink-modal-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    padding: 1em;
    padding-right: 0.8em; }
  #saveBtn {
    font-size: 0.8em; } }

@media (max-width: 330px) {
  .finlink-modal {
    padding-top: 50px; }
  .finlink-modal-header {
    height: 50px; }
  .finlink-modal-title {
    font-size: 1.1rem; }
  .finlink-modal .finlink-modal-header .finlink-btn.action {
    width: 24px;
    height: 24px; }
  .finlink-modal .finlink-modal-header .finlink-btn.action svg:not(.stroked) {
    width: 18px;
    height: 18px; } }

/*** Start Vanilla CSS 1.0.2 */ h1,h2,h3,h4,h5,h6,b,strong{font-weight:bold;}h1 {display: block;font-size: 2em;-webkit-margin-before: 0.67em;margin-block-start: 0.67em;-webkit-margin-after: 0.67em;margin-block-end: 0.67em;-webkit-margin-start: 0px;margin-inline-start: 0px;-webkit-margin-end: 0px;margin-inline-end: 0px;font-weight: bold;}em,i,dfn{font-style:italic;}dfn{font-weight:bold;}p,code,pre,kbd{margin:0 0 1.5em 0;}blockquote{margin:0 1.5em 1.5em 1.5em;}cite{font-style:italic;}li ul,li ol{margin:0 1.5em;}ul,ol{margin:0 1.5em 1.5em 1.5em;}ul{list-style-type:disc;}ol{list-style-type:decimal;}ol ol{list-style:upper-alpha;}ol ol ol{list-style:lower-roman;}ol ol ol ol{list-style:lower-alpha;}dl{margin:0 0 1.5em 0;}dl dt{font-weight:bold;}dd{margin-left:1.5em;}table{margin-bottom:1.4em;width:100%;}th{font-weight:bold;}th,td,caption{padding:4px 10px 4px 5px;}tfoot{font-style:italic;}sup,sub{line-height:0;}abbr,acronym{border-bottom:1px dotted;}address{margin:0 0 1.5em;font-style:italic;}del{text-decoration:line-through;}pre{margin:1.5em 0;white-space:pre;}img.centered,.aligncenter,div.aligncenter{display:block;margin-left:auto;margin-right:auto;}img.alignright{display:inline;}img.alignleft{display:inline;}.alignright{float:right;margin-left:10px;}.alignleft{float:left;margin-right:10px;}img { max-width: 100%; }* html .clearfix{height:1%;}*+html .clearfix{display:inline-block;}.clearfix:after{content:”.”;display:block;height:0;clear:both;visibility:hidden;}* html .group{height:1%;}*+html .group{display:inline-block;}.group:after{content:”.”;display:block;height:0;clear:both;visibility:hidden;}
.addAcountForm {
  padding-top: 22px; }

.addAcountForm .finlink-form-control.focused label,
.addAcountForm .finlink-form-control.filled label,
.addAcountForm .finlink-form-control.has-prepend label,
.addAcountForm .finlink-form-control input[placeholder] ~ label {
  top: -24px; }

.addAcountForm .finlink-radio-button-group {
  margin-top: -10px; }

.addAcountForm .finlink-radio-button-group + .finlink-radio-button-group {
  margin-top: -30px;
  margin-bottom: 25px; }

.addAcountForm .finlink-radio-button-group > label {
  margin-bottom: 5px; }

.addAcountForm .finlink-btn.block {
  margin-top: -15px; }

.ot-header{
    width: 100vw;
    background: #383837;
    z-index: 5;
    padding: 0 0.5em;
    border-bottom: 1px solid #676767;
}

.ot-header.fixed{
    position: fixed;
    top: -2px;
}

.ot-header.raised{
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.14);
}

.ot-header.no-border{
    padding: 0;
    box-shadow: none;
    border: none;
}

.ot-header.no-border, 
.ot-header.no-border .top-bar{
    padding: 0;
}

.ot-header, 
.ot-header .top-bar{
    min-height: 60px;
}

.ot-header .top-bar{
    width: 100%;
}

.ot-header .ot-logo{
    height: 25px;
    margin-right: 0.4em;
}

.ot-header #backButton{
    margin-left: 13px;
    margin-right: -54px;
    width: 34px;
    height: 34px;
    position: relative;
    z-index: 1;
}

.ot-header #backButton svg{
    height: 30px !important;
    width: 30px !important;
    stroke: #ddd;
}

.ot-header button.action{
    background: transparent;
}

.ot-header button.action:not(#backButton){
    margin-left: -30px;
}

.ot-header button.action:not(#backButton) svg{
    height: 25px !important;
    width: 25px !important;
    fill: #bbb;
}

.ot-header button.action:not(#backButton):last-of-type{
    margin-right: -14px;
}

.ot-header-title{
    font-size: 1.35em;
    letter-spacing: 0.01em;
    font-weight: bold;
    color: #ddd;
    flex: 1 1;
    text-transform: capitalize;
}

.ot-header.has-back .ot-header-title{
    padding-left: 72px !important;
}

.ot-header.no-border .ot-header-title{
    padding-left: 0.8em;
}

#ot-header-tabs{
    width: 100%;
    padding: 0.8em 0;
    padding: 0.3em 0;
    padding-bottom: 1em;
    padding-left: 1em;
    border-bottom: 1px solid #444;
}

#ot-header-tabs:not(.center-center){
    padding-left: 1.4em;
}

#ot-header-tabs .ot-btn.flat{
    font-size: 0.95em;
    text-align: center;
    min-width: 0;
    padding: 8px 0;
    margin-right: 1.5em;
    color: #999;
    letter-spacing: 0.1em;
    background: transparent;
    border: none;
}

#ot-header-tabs .ot-btn.flat.active{
    color: #eaeaea;
}

#ot-header-tabs .separator{
    width: 6px;
    height: 6px;
    background: #aaa;
    border-radius: 50%;
    margin-right: 1.5em;
}

@media all and (max-width: 400px){
    .ot-header, .ot-header .top-bar {
        min-height: 52px;
    }

    .ot-header-title{
        font-size: 1.25em;
    }

    .ot-header .ot-logo{
        height: 28px;
    }
}

@media all and (max-width: 330px){
    .ot-header:not(.no-border){
        border-color: #4c4c4c;
    }

    .ot-header, .ot-header .top-bar {
        min-height: 47px;
    }

    .ot-header-title{
        font-size: 1.02rem;
    }

    .ot-header .ot-logo{
        height: 20px;
    }

    .ot-header #backButton{
        margin-left: 4px;
        margin-right: -60px;
        width: 24px;
        height: 24px;
        position: relative;
        z-index: 1;
    }
    
    .ot-header #backButton svg{
        height: 20px !important;
        width: 20px !important;
        stroke: #ddd;
    }
}
#newVersionBanner {
  padding: 0.4em 1.1em;
  padding-right: 0.8em;
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 99;
  background: #cee7e8;
  color: #4f696a; }

#newVersionBanner #updateIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: #a6d0d1;
  margin-right: 0.8em;
  color: #445858; }

#newVersionBanner strong {
  font-weight: normal;
  display: block;
  margin-bottom: 0.15em;
  font-size: 1.2em;
  color: #223232; }

#newVersionBanner button {
  color: #0e3030; }

#unreadNotificationButton span {
  background: #00f0f3;
  border: 3px solid #383837;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  top: 3px;
  right: 0px; }

.savingsSummaryPlaceholder .image-carousel {
  position: relative;
  border-radius: 0;
  overflow-x: hidden;
  width: 100%; }

.savingsSummaryPlaceholder .image-carousel .handle {
  top: 0;
  left: 0;
  cursor: pointer;
  border-radius: 0; }

.savingsSummaryPlaceholder .image-carousel .indicators {
  margin-top: 0.3em;
  margin-bottom: 0.5em;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: inline-flex;
  align-items: center;
  z-index: 1; }

.savingsSummaryPlaceholder .image-carousel .indicators span {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #636363;
  margin-right: 0.8em; }

.savingsSummaryPlaceholder .image-carousel .indicators span.active {
  background: #aaa; }

.savingsSummaryPlaceholder .image-carousel .handle .slide {
  float: left;
  position: relative;
  width: 100vw;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 0.7em;
  padding-top: 1em; }

.savingsSummaryPlaceholder .image-carousel .handle .slide > div {
  display: flex;
  align-items: center;
  height: 230px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 -3px 13px rgba(0, 0, 0, 0.3); }

.savingsSummaryPlaceholder .image-carousel .handle .slide img {
  height: 100%;
  width: 100%;
  object-fit: cover; }

.savingsSummaryPlaceholder .image-carousel .handle .slide-text {
  position: relative;
  z-index: 1;
  padding: 0 1.8em; }

.savingsSummaryPlaceholder .image-carousel .handle .slide-text > span,
.savingsSummaryPlaceholder .image-carousel .handle .slide-text .finlink-btn {
  color: #000 !important; }

.savingsSummaryPlaceholder .image-carousel .handle .slide-text .finlink-btn {
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.06em;
  padding-left: 25px;
  padding-right: 25px;
  border-color: #51705e;
  border-color: #5b8d76;
  border-width: 1px; }

.savingsSummaryPlaceholder .image-carousel .handle .slide-text > span {
  font-size: 1.6em;
  letter-spacing: 0;
  line-height: 1.4em;
  margin-bottom: 0.8em; }

.savingsSummaryPlaceholder .image-carousel .handle .slide > div:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -2px;
  background: rgba(190, 255, 255, 0.72); }

.savingsSummaryPlaceholder .cta {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 1.5em;
  margin: auto;
  margin-bottom: -1.5em;
  display: inline-block; }

.savingsSummaryPlaceholder .cta:before {
  content: '';
  position: absolute;
  top: -14%;
  bottom: 0;
  left: -2.5%;
  right: -2.5%;
  border-radius: 25px;
  margin: auto;
  background: #383837;
  z-index: -1; }

.savingsSummaryPlaceholder .cta::after {
  left: auto;
  right: -50px !important; }

.savingsSummary {
  background: #474747;
  padding: 1.7em 1.4em;
  padding: 1em 1.4em;
  position: relative;
  margin-bottom: 1em; }

.savingsSummary:before {
  content: '';
  position: absolute;
  margin-left: 2.5px;
  margin-top: 2.5px;
  background: #474747;
  width: 195px;
  height: 195px;
  border-radius: 50%;
  border: 33px solid #3f3f3f;
  box-sizing: border-box; }

.chartist-bg-green {
  fill: #57c495; }

.chartist-bg-dark {
  fill: #58595b; }

.chartist-bg-dark-grey {
  fill: #707070; }

.chartist-bg-grey {
  fill: #c8c8c8; }

.savingsSummaryKey {
  position: absolute;
  top: 0;
  left: 258px;
  right: 0;
  bottom: 4.4em;
  bottom: 0;
  margin: auto 0;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.savingsSummaryKeyItem {
  padding: 0.8em 0 0.8em 1.4em;
  letter-spacing: 0.09em;
  font-size: 0.8em;
  color: #ddd;
  position: relative; }

.savingsSummaryKeyItem:before {
  content: '';
  position: absolute;
  left: -0.35em;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 7px;
  height: 7px;
  border-radius: 5px; }

.savingsSummaryKeyItem:nth-child(1):before {
  background: #57c495; }

.savingsSummaryKeyItem:nth-child(2):before {
  background: #58595b; }

.savingsSummaryKeyItem:nth-child(3):before {
  background: #707070;
  background: #c8c8c8; }

.savingsSummaryKeyItem:nth-child(4):before {
  background: #c8c8c8; }

.savingsSummaryBalance {
  padding-top: 1em;
  text-align: center;
  display: none; }

.savingsSummaryBalance small {
  color: #ddd;
  display: block;
  letter-spacing: 0.14em;
  font-size: 0.98em;
  margin-bottom: 0.4em; }

.savingsSummaryBalance strong {
  color: #61bf95;
  letter-spacing: 0.03em;
  font-size: 1.5em;
  display: inline-flex;
  align-items: center; }

.savingsSummaryBalance strong span {
  font-size: 0.8em;
  display: inline-block;
  margin-right: 0.3em;
  letter-spacing: 0.1em; }

.textualSummary,
.accountsSummary {
  padding: 0.7rem 1.3em;
  padding-bottom: 0.5em; }

.accountsSummary {
  padding-top: 0.3rem; }

.sectionTitle {
  font-size: 1.14em;
  font-weight: bold;
  color: #b7b7b7;
  letter-spacing: 0.035em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.1em; }

.homeScene .sectionTitle {
  font-size: 1.05rem; }

.sectionTitle button.flat {
  color: #4ee6a1;
  letter-spacing: 0.1em !important;
  margin-right: -0.3em; }

.textualSummaryItem {
  padding: 0.9rem 0.75rem;
  border-radius: 4px;
  border: 1.5px solid #00f6f9;
  border: 1.5px solid #77afb0;
  margin-bottom: 0.5em;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.95rem;
  letter-spacing: 0.03em; }

.textualSummaryItem strong span {
  padding-left: 0.1em;
  letter-spacing: 0.04em;
  font-size: 1.1em; }

.textualSummaryItem.negative strong span {
  color: red; }

.accountsSummary .sectionTitle {
  margin-bottom: 0.4em; }

.accountFetchLoading {
  color: #999; }

.accountSummaryItem {
  padding: 0.75rem;
  border-radius: 4px;
  background: #474747;
  margin-bottom: 0.5em;
  color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 1.1em;
  font-size: 0.98rem;
  font-weight: bold;
  letter-spacing: 0.03em; }

.accountSummaryItem svg {
  fill: #b6b6b6;
  width: 22px;
  height: 22px;
  margin-top: -0.1em;
  margin-right: 0.7em; }

.accountSummaryItem div {
  flex: 1 1; }

.accountSummaryItem div span {
  color: #dfdfdf;
  display: block;
  font-size: 0.9em;
  font-weight: normal; }

.accountSummaryItem div small {
  display: block;
  margin-top: 0.3em;
  color: #dfdede;
  font-size: 0.85em;
  font-weight: 300;
  text-transform: capitalize; }

.accountSummaryItem strong {
  color: #d3d3d3;
  font-weight: normal;
  font-size: 0.95em;
  letter-spacing: 0.04em;
  text-transform: uppercase; }

.accountSummaryItem strong span {
  letter-spacing: 0.04em; }

.accountSummaryItem + button {
  color: #2db87e;
  letter-spacing: 0.12em !important;
  display: block;
  margin: auto;
  left: 0;
  right: 0; }

@media all and (max-width: 400px) {
  .savingsSummaryPlaceholder .image-carousel .handle .slide > div {
    height: 180px;
    position: relative; }
  .savingsSummaryPlaceholder .image-carousel .handle .slide-text {
    padding: 0 1.4rem; }
  .savingsSummaryPlaceholder .image-carousel .handle .slide-text > span {
    font-size: 1.3rem; }
  .savingsSummaryPlaceholder .image-carousel .handle .slide-text .finlink-btn {
    font-size: 12px; } }

@media all and (max-width: 330px) {
  .savingsSummaryPlaceholder .image-carousel .handle .slide > div {
    height: 170px; }
  .savingsSummaryPlaceholder .image-carousel .handle .slide-text {
    padding: 0 1.5em; }
  .savingsSummaryPlaceholder .image-carousel .handle .slide-text > span {
    font-size: 1.3em;
    margin-bottom: 0.7em; }
  .savingsSummaryPlaceholder .image-carousel .handle .slide-text .finlink-btn {
    font-size: 11px;
    letter-spacing: 0.06em;
    padding: 6px 12px; }
  .savingsSummary:before {
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 2.5px; }
  .ct-chart {
    display: flex;
    justify-content: center; }
  .savingsSummaryKey {
    position: relative;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto 0;
    margin-top: 0.5em;
    flex: 1 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap; }
  .savingsSummaryKeyItem {
    padding: 0.8em 0 0.8em 1.4em;
    letter-spacing: 0.09em;
    font-size: 0.8em;
    color: #ddd;
    position: relative; }
  .savingsSummaryKeyItem:nth-child(odd) {
    margin-right: 1.8em; }
  .savingsSummaryPlaceholder .cta .finlink-btn {
    text-transform: none;
    padding: 0.6em 1.5em; }
  .sectionTitle {
    font-size: 0.85em;
    font-weight: bold;
    color: #b7b7b7;
    letter-spacing: 0.035em; }
  .sectionTitle button {
    text-transform: none; }
  .textualSummary {
    padding-top: 0.8em; }
  .textualSummaryItem {
    padding: 0.8em 0.65em;
    font-size: 0.95em;
    font-weight: normal; }
  .accountsSummary {
    padding-top: 0.45em; }
  .accountSummaryItem {
    padding: 0.8em 0.65em;
    border-radius: 2px;
    margin-bottom: 0.3em; }
  .accountSummaryItem svg {
    display: none; }
  .accountSummaryItem div span {
    font-size: 0.9em; }
  .accountSummaryItem div small {
    margin-top: 0.18em;
    font-size: 0.7em;
    font-weight: 300; }
  .accountSummaryItem strong {
    font-size: 0.83em;
    letter-spacing: 0.04em; } }

.flexbox, .flexbox.horizontal,
.flexbox.horizontal-reverse,
.flexbox.vertical,
.flexbox.vertical-reverse {
  display: flex; }

.flexbox.inline {
  display: inline-flex; }

.flexbox.horizontal {
  flex-direction: row; }

.flexbox.horizontal-reverse {
  flex-direction: row-reverse; }

.flexbox.vertical {
  flex-direction: column; }

.flexbox.vertical-reverse {
  flex-direction: column-reverse; }

.flexbox.wrap {
  flex-wrap: wrap; }

.flex,
.flex-1 {
  flex: 1 1; }

.flexbox.start {
  align-items: flex-start; }

.flexbox.align-center,
.flexbox.center {
  align-items: center; }

.flexbox.align-end {
  align-items: flex-end; }

.flexbox.justify-start {
  justify-content: flex-start; }

.flexbox.justify-center,
.flexbox.center {
  justify-content: center; }

.flexbox.justify-end {
  justify-content: flex-end; }

.flexbox.space-around {
  justify-content: space-around; }

.flexbox.space-between {
  justify-content: space-between; }

/*** Start Vanilla CSS 1.0.2 */ 
/* h1,h2,h3,h4,h5,h6,b,strong{font-weight:bold;}h1 {display: block;font-size: 2em;margin-block-start: 0.67em;margin-block-end: 0.67em;margin-inline-start: 0px;margin-inline-end: 0px;font-weight: bold;}em,i,dfn{font-style:italic;}dfn{font-weight:bold;}p,code,pre,kbd{margin:0 0 1.5em 0;}blockquote{margin:0 1.5em 1.5em 1.5em;}cite{font-style:italic;}li ul,li ol{margin:0 1.5em;}ul,ol{margin:0 1.5em 1.5em 1.5em;}ul{list-style-type:disc;}ol{list-style-type:decimal;}ol ol{list-style:upper-alpha;}ol ol ol{list-style:lower-roman;}ol ol ol ol{list-style:lower-alpha;}dl{margin:0 0 1.5em 0;}dl dt{font-weight:bold;}dd{margin-left:1.5em;}table{margin-bottom:1.4em;width:100%;}th{font-weight:bold;}th,td,caption{padding:4px 10px 4px 5px;}tfoot{font-style:italic;}sup,sub{line-height:0;}abbr,acronym{border-bottom:1px dotted;}address{margin:0 0 1.5em;font-style:italic;}del{text-decoration:line-through;}pre{margin:1.5em 0;white-space:pre;}img.centered,.aligncenter,div.aligncenter{display:block;margin-left:auto;margin-right:auto;}img.alignright{display:inline;}img.alignleft{display:inline;}.alignright{float:right;margin-left:10px;}.alignleft{float:left;margin-right:10px;}img { max-width: 100%; }* html .clearfix{height:1%;}*+html .clearfix{display:inline-block;}.clearfix:after{content:”.”;display:block;height:0;clear:both;visibility:hidden;}* html .group{height:1%;}*+html .group{display:inline-block;}.group:after{content:”.”;display:block;height:0;clear:both;visibility:hidden;} */

#viewUpdate{
    padding-bottom: 60px;
}

#viewUpdate h1, 
#viewUpdate h2, 
#viewUpdate #releaseNotes{
    color: #999;
}

#viewUpdate h1, 
#viewUpdate h2{
    font-weight: 600;
    letter-spacing: 0.02em;
}

#viewUpdate h1{
    color: #ccc;
    font-size: 1.6em;
}

#viewUpdate > h1{
    margin-top: 0.1em;
    margin-bottom: 0.15em;
}

#viewUpdate > h1:nth-of-type(2),
#releaseNotes h1{
    margin-top: 1.2em;
    margin-bottom: 0.2em;
}

#viewUpdate > h1:nth-of-type(2){
    margin-bottom: 0;
}

#releaseNotes p, #releaseNotes li{
    font-size: 1.1em;
    font-weight: 500;
    letter-spacing: 0.03em;
}

#viewUpdate h2{
    font-size: 1.2em;
}

#viewUpdate ul li{
    list-style-type: disc;
}

#viewUpdate ol li{
    list-style-type: decimal;
}

#viewUpdate .finlink-btn{
    position: fixed;
    left: 0;
    top: auto;
    bottom: 0;
    width: 100%;
    border-radius: 0;
    height: 55px;
    line-height: 25px;
    font-size: 1.15em;
    z-index: 1;
}
.accountSwitcher {
  position: relative;
  height: 220px;
  border-radius: 0;
  overflow: hidden;
  width: 100%; }

.accountSwitcher,
.accountSwitcher * {
  -webkit-tap-highlight-color: transparent; }

.accountSwitcherButtons {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0.7em;
  margin: auto 0;
  pointer-events: none; }

.accountSwitcherButtons button {
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  color: #597373;
  background: #b4d8d8;
  box-shadow: 0;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  outline: none;
  pointer-events: auto; }

.accountSwitcherButtons button svg {
  fill: none;
  width: 40px;
  height: 40px; }

.accountSwitcherButtons button:active {
  -webkit-transform: scale(0.96);
          transform: scale(0.96); }

.accountSwitcherButtons button:disabled {
  background-color: #6ca1a1;
  box-shadow: -1px 0px 3px rgba(0, 0, 0, 0.3);
  color: #577b7b;
  pointer-events: none; }

.accountSwitcher .handle {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  border-radius: 0;
  display: flex;
  will-change: transform; }

.accountSwitcher.animate-switch .handle {
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out; }

.accountSwitcher .handle .card {
  width: 100vw;
  flex-shrink: 0;
  position: relative;
  height: 100%;
  padding: 0 4vw; }

.accountSwitcher .handle .cardContent {
  position: relative;
  padding: 2rem 2.4rem;
  height: 100%;
  border-radius: 15px;
  box-shadow: 3px 3px 23px rgba(0, 0, 0, 0.2);
  background: #eeeeee;
  background: linear-gradient(172deg, #eeeeee 0%, #cbc9c9 44%, #aaaaaa 68%);
  background: #2da0a1;
  background: linear-gradient(137deg, #2da0a1 0%, #64b2b3 44%, #3f9798 68%);
  overflow: hidden;
  -webkit-transform-origin: right;
          transform-origin: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out; }

.accountSwitcher .cardContent:before {
  content: '';
  position: absolute;
  bottom: -20%;
  right: -20%;
  border: 20px solid #54a2a3;
  width: 120px;
  height: 120px;
  border-radius: 50%; }

.accountSwitcher .handle .card:not(.active) .cardContent {
  -webkit-transform: scale(0.9);
          transform: scale(0.9); }

.accountSwitcher .handle .card:not(.active) .cardContent > * {
  opacity: 0; }

.accountSwitcher .handle .card.active .cardContent {
  background: #2da0a1;
  background: linear-gradient(137deg, #2da0a1 0%, #64b2b3 44%, #3f9798 68%); }

.accountSwitcher .accountName {
  margin-bottom: 0.2rem;
  font-size: 1.4rem;
  font-weight: 100;
  letter-spacing: 0.03em;
  color: #d1feff;
  font-weight: bold;
  text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.1); }

.accountSwitcher .accountInterest {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #0d2d2d;
  color: #2e5c5d;
  letter-spacing: 0.03em;
  margin-bottom: 1rem; }

.accountBalanceInfo.total .for-available,
.accountBalanceInfo.available .for-total {
  display: none; }

.accountSwitcher .accountBalance {
  display: block;
  width: 100%;
  font-size: 1.5rem;
  color: #d1feff;
  padding: 0 0.3rem;
  letter-spacing: 0.02em;
  text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.1); }

.accountBalance + .accountBalance {
  margin-top: 0.6rem;
  margin-bottom: -0.3rem; }

.accountSwitcher .accountBalance span {
  color: #2e5c5d;
  display: inline-block;
  margin-left: 0.5rem;
  -webkit-transform: translateY(-0.2rem);
          transform: translateY(-0.2rem);
  font-size: 0.93rem;
  text-shadow: none; }

.accountSwitcher .accountBalance small {
  margin-bottom: 0.85rem;
  display: block;
  color: #a6f8f9;
  font-weight: bold;
  font-size: 1.03rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-shadow: none; }

.accountBalanceTypeSwitcher {
  display: block;
  margin-top: 1.2rem;
  font-size: 0.68rem;
  line-height: 1.5em;
  letter-spacing: 0.04em;
  text-shadow: none; }

.accountBalanceTypeSwitcher button {
  background: transparent;
  border: none; }

@media all and (max-width: 400px) {
  .accountSwitcher {
    height: 175px; }
  .accountSwitcher .handle .cardContent {
    padding: 1.8rem 2.1rem; }
  .accountSwitcher .cardContent:before {
    border-width: 12px;
    right: -30%;
    bottom: -30%; }
  .accountSwitcher .accountName {
    font-size: 1em; }
  .accountSwitcher .accountInterest {
    font-size: 0.8rem; }
  .accountSwitcher .accountBalance {
    font-size: 1.1rem; }
  .accountSwitcher .accountBalance small {
    font-size: 0.8rem;
    margin-bottom: 0.7rem; }
  .accountSwitcher .accountBalance span {
    font-size: 0.7rem; } }

.sectionTitle {
  font-size: 1.24em;
  font-weight: 100;
  color: #b7b7b7;
  letter-spacing: 0.035em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.9em; }

.transactionsLoading {
  display: block;
  color: #777;
  letter-spacing: 0.03em;
  margin-top: 1rem; }

.transactionItem {
  padding: 1em 0;
  padding-top: 1.2em;
  border-radius: 4px;
  border-top: 1px solid #565656;
  color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 1.1em;
  font-weight: bold;
  letter-spacing: 0.03em; }

.transactionItem:first-child {
  border-bottom: 1px solid #4a4a4a; }

.transactionItem > svg {
  stroke: #b6b6b6;
  margin-top: -0.1em;
  margin-right: 0.7em; }

.transactionItem.deposit > svg {
  stroke: #49ae49; }

.transactionItem.withdrawal > svg,
.transactionItem.transfer-out > svg {
  stroke: #da4d4d; }

.transactionItem > div {
  flex: 1 1;
  padding-left: 0.5em; }

.transactionItem > div div {
  font-size: 1.1em; }

.transactionItem > div div div {
  color: #9e9e9e;
  font-size: 1em;
  text-transform: lowercase; }

.transactionItem div h3 {
  margin-bottom: 0.5em;
  color: #e1e1e1;
  font-weight: 500;
  font-size: 1.05em; }

.transactionItem div span {
  color: #d2d2d2;
  display: block;
  font-size: 0.85em;
  font-weight: normal;
  text-transform: capitalize;
  letter-spacing: 0.05em; }

.transactionItem div h3 + span {
  margin-bottom: 0.5em; }

.transactionItem small {
  color: #a0a0a0;
  font-size: 0.92em;
  font-weight: 400;
  letter-spacing: 0.05em;
  position: absolute;
  right: 0; }

.transactionItem strong {
  color: #9e9c9c;
  font-weight: normal;
  font-size: 0.95em;
  letter-spacing: 0.04em; }

.transactionItem strong span {
  letter-spacing: 0.04em; }

@media all and (max-width: 400px) {
  .accountDetailSection .sectionTitle {
    margin: 0 0.4rem;
    font-size: 0.85em;
    font-weight: bold;
    color: #b7b7b7;
    letter-spacing: 0.035em; }
  .transactionItem {
    padding: 0.8em 0;
    border-radius: 2px;
    margin-bottom: 0;
    padding-top: 1.2em; }
  .transactionItem > div div {
    font-size: 0.9em; }
  .transactionItem > svg {
    display: none; }
  .transactionItem div h3 {
    font-size: 0.9em;
    margin-bottom: 0.7rem; }
  .transactionItem div span {
    font-size: 0.9rem;
    opacity: 0.85; }
  .transactionItem div h3 + span {
    margin-bottom: 0.2em; }
  .transactionItem small {
    margin-top: 0.18em;
    font-size: 0.85rem; }
  .transactionItem strong {
    font-size: 0.83em;
    letter-spacing: 0.04em; } }

.date-filter-wrapper{
    /* position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #444;
    box-shadow: 0 -25px 50px rgba(0, 0, 0, 0.08);
    max-width: 408px;
    margin: auto;
    will-change: opacity, transform;
    transition: transform 0.25s ease-out, opacity 0.1s ease-out; */
}

.transaction-filter:not(.visible) .date-filter-wrapper{
    /* transform: translateY(60%);
    opacity: 0;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out; */
}

.date-filter-wrapper #title {
    padding: 0 1em;
    min-height: 33px;
    font-size: 1.15em;
    color: #9b9b9b;
    letter-spacing: 0.01em;
    margin-top: 0.35em;
    margin-bottom: -0.3em;
}

.date-filter-wrapper #title.not-empty{
    margin-bottom: 1.7em;
    padding-top: 1.4em;
}

.date-filter-wrapper #title > span:first-child{
    position: absolute;
    top: 0.25em;
    -webkit-transition: -webkit-transform 0.2s ease-out;
    transition: -webkit-transform 0.2s ease-out;
    transition: transform 0.2s ease-out;
    transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
    color: #9e9d9d;
}

.date-filter-wrapper #title.not-empty > span:first-child{
    /* position: relative; */
    /* margin-bottom: 2em; */
    /* transform-origin: left;
    transform: scale(0.8) translateY(-1.1em);
    color: inherit; */
}

.date-filter-wrapper #title svg{
    stroke: #959595;
    width: 30px;
    height: 30px;
    margin: 0 0.4em;
}

.date-filter-wrapper #title strong{
    font-size: 0.85em;
    padding: 0.5em 1.1em;
    border-radius: 1.5em;
    background: #4f4f4f;
    border: 1px solid #646363;
}

.date-filter-wrapper #title strong,
.date-filter-wrapper #title svg{
    opacity: 0;
    top: 0.85em;
}

.date-filter-wrapper #title.not-empty strong,
.date-filter-wrapper #title.not-empty svg{
    opacity: 1;
    -webkit-transition: opacity 0.35s ease-out 0.12s;
    transition: opacity 0.35s ease-out 0.12s;
}

.date-filter-wrapper #input {
    font-size: 2.3em;
    width: 100vw;
    padding: 0 0.8em;
    margin-bottom: 0.5em;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #4e4e4e;
    color: #8d8d8d;
    letter-spacing: 0.02em;
    position: relative;
    min-height: 66px;
    display: none;
}

#deleteBtn {
    position: absolute;
    right: 1em;
    top: 0;
    bottom: 0;
    margin: auto 0;
    padding: 0.5em;
    background: rgba(0, 0, 0, 0.05);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

#deleteBtn svg{
    width: 28px;
    height: 28px;
}

.date-filter-wrapper #numbers{
    margin-top: 1.2em;
    display: flex;
    flex-wrap: wrap;
}

.date-filter-wrapper #title + #numbers{
    margin-top: 0;
}

.date-filter-wrapper #numbers button{
    width: 33vw;
    max-width: 136px;
    height: 20vw;
    max-height: 83px;
    font-size: 1.2em;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: transparent;
    outline: none;
    color: #959595;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.date-filter-wrapper #numbers button.inactive{
    color: #5a5a5a;
    pointer-events: none;
}

.date-filter-wrapper #numbers button.active{
    background: #4b4b4b;
    border-color: #535353 !important;
}

.date-filter-wrapper #numbers button:not(:nth-child(3n)){
    border-right: 1px solid #4c4b4b;
}

.date-filter-wrapper #numbers button:nth-child(-n + 9){
    border-bottom: 1px solid #4c4b4b;
}

#submitButton{
    margin: 1em 0;
    background: #535353;
    border-color: #686868;
    color: #b0b0b0;
}
.transaction-filters{
    z-index: 5;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
}

.transaction-filters-backdrop{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(56, 56, 56, 0.94);
    will-change: opacity;
    -webkit-transition: opacity 0.15s ease-out;
    transition: opacity 0.15s ease-out;
}

.transaction-filters:not(.visible) .transaction-filters-backdrop{
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-out;
    transition: opacity 0.2s ease-out;
}

.transaction-filters-content{
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    background: #444;
    box-shadow: 0 -25px 50px rgba(0, 0, 0, 0.08);
    max-width: 408px;
    max-height: 100vh;
    margin: auto;
    will-change: opacity, transform;
    -webkit-transition: opacity 0.1s ease-out, -webkit-transform 0.25s ease-out;
    transition: opacity 0.1s ease-out, -webkit-transform 0.25s ease-out;
    transition: transform 0.25s ease-out, opacity 0.1s ease-out;
    transition: transform 0.25s ease-out, opacity 0.1s ease-out, -webkit-transform 0.25s ease-out;
}

.transaction-filters:not(.visible) .transaction-filters-content{
    -webkit-transform: translateY(60%);
            transform: translateY(60%);
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
    transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

.transaction-filters .transaction-filters-title{
    padding: 1em 1.2em;
    font-size: 1.4em;
    color: #a6a6a6;
    text-align: center;
    letter-spacing: 0.03em;
    border-bottom: 1px solid #616161;
    z-index: 20;
    flex-shrink: 0;
}

.transaction-filters .transaction-filters-body{
    min-height: calc(100vh - 130px);
    overflow-y: auto;
    flex: 1 1;
    padding: 2em 0;
}

.transaction-filters h5{
    padding: 0.3em 1em;
    font-size: 1.2em;
    font-weight: 600;
    color: #7d7d7d;
    letter-spacing: 0.04em;
    text-transform: uppercase;
}

.transaction-filters h5 small{
    text-transform: lowercase;
}

.transaction-filters-content #accounts{
    margin-bottom: 1em;
}

.transaction-filters-content .account-choice{
    padding: 1em 0.9em;
    padding-right: 0em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.3em;
    letter-spacing: 0.08em;
    text-transform: capitalize;
    border: none;
    background: transparent;
    outline: none;
    color: #aeaeae;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%;
    -moz-text-align-last: left;
         text-align-last: left;
    border-bottom: 1px solid #4e4e4e;
}

.transaction-filters-content .account-choice:last-of-type{
    border-bottom-color: transparent;
}

.account-choice .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border: 2px solid #919191;
    border-radius: 50%;
    color: #545454;
    margin-right: 0.6em;
    font-style: normal;
    background: transparent;
}

.account-choice .icon svg{
    width: 16px;
    height: 16px;
    stroke: #dbdbdb;
    opacity: 0;
}

.transaction-filters-content .account-choice.selected .icon{
    background: rgba(126, 126, 126, 0.57);
}

.transaction-filters-content .account-choice.selected .icon svg{
    opacity: 1;
}

.transaction-filters-actions{
    padding: 0.7em 0;
    padding-left: 0.3em;
    background: #444444;
    border-top: 1px solid #616161;
    flex-shrink: 0;
}

.transaction-filters-actions .finlink-btn.rounded{
    background: #535353;
    background: transparent;
    border-color: #686868;
    border: none;
    color: #b0b0b0;
    font-size: 1em;
    padding: 0.5em 0.8em;
    letter-spacing: 0.1em;
    color: #cfcfcf;
}

.transaction-filters-actions .finlink-btn.rounded:last-child{
    color: #00f0f3;
}
.finlink-dialpad{
    z-index: 1;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.finlink-dialpad-backdrop{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(56, 56, 56, 0.94);
    will-change: opacity;
    -webkit-transition: opacity 0.15s ease-out;
    transition: opacity 0.15s ease-out;
}

.finlink-dialpad:not(.visible) .finlink-dialpad-backdrop{
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-out;
    transition: opacity 0.2s ease-out;
}

.finlink-dialpad-content{
    /* position: fixed; */
    /* bottom: 0; */
    /* top: 0; */
    left: 0;
    right: 0;
    background: #444;
    box-shadow: 0 -25px 50px rgba(0, 0, 0, 0.08);
    width: 100%;
    max-width: 408px;
    margin: auto;
    padding: 1.2rem;
    will-change: opacity, transform;
    -webkit-transition: opacity 0.1s ease-out, -webkit-transform 0.25s ease-out;
    transition: opacity 0.1s ease-out, -webkit-transform 0.25s ease-out;
    transition: transform 0.25s ease-out, opacity 0.1s ease-out;
    transition: transform 0.25s ease-out, opacity 0.1s ease-out, -webkit-transform 0.25s ease-out;
}

.finlink-dialpad:not(.visible) .finlink-dialpad-content{
    -webkit-transform: translateY(60%);
            transform: translateY(60%);
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
    transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

.finlink-dialpad-content #title {
    /* padding: 0 1.3em; */
    margin-top: 0.6em;
    margin-bottom: 0.1em;
    font-size: 1.35em;
    color: #9b9b9b;
    letter-spacing: 0.01em;
}

.finlink-dialpad-content .finlink-btn {
    margin-top: 1rem;
}

.finlink-dialpad-content #input {
    font-size: 2.3em;
    width: 100vw;
    padding: 0 0.8em;
    margin-bottom: 0.5em;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #4e4e4e;
    color: #8d8d8d;
    letter-spacing: 0.02em;
    position: relative;
    min-height: 66px;
}

#deleteBtn {
    position: absolute;
    right: 1em;
    top: 0;
    bottom: 0;
    margin: auto 0;
    padding: 0.5em;
    background: rgba(0, 0, 0, 0.05);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    touch-action: none;
}

#deleteBtn svg{
    width: 28px;
    height: 28px;
}

.finlink-dialpad-content #numbers{
    display: flex;
    flex-wrap: wrap;
}

.finlink-dialpad-content #numbers button{
    width: 33vw;
    max-width: 136px;
    height: 20vw;
    max-height: 83px;
    font-size: 1.3em;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: transparent;
    outline: none;
    color: #959595;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    touch-action: none;
}

.finlink-dialpad-content #numbers button:not(:nth-child(10)):not(:nth-child(12)){
    font-size: 2em;
}
#DepositMoney {
  padding-top: 20px; }

#DepositMoney > .finlink-modal {
  padding-top: 0; }

#DepositMoney > .finlink-modal > .finlink-modal-header {
  background: transparent;
  border-bottom-color: transparent; }

#DepositMoney > .finlink-modal > .finlink-modal-header .finlink-modal-title {
  color: transparent; }

#AddMobileWallet .finlink-modal-content {
  padding-top: 3.5em; }

.DepositModal .logoImage {
  display: block;
  height: 70px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 1.5em; }

.DepositModal.center .logoImage {
  margin-top: 0 !important; }

.DepositModal .logoImage.circle {
  width: 70px; }

.DepositModal .logoImage {
  height: 70px;
  border-radius: 50%;
  background: #bbb;
  color: #3c3c3d;
  font-size: 1.5em;
  letter-spacing: 0.04em;
  display: flex; }

.DepositModal .logoImage svg {
  width: 35px;
  height: 35px;
  fill: unset; }

.DepositModal .form {
  margin: 0 auto;
  margin-bottom: 20px; }

.DepositModal .formTitle {
  font-size: 1.4em;
  margin-top: 1em;
  margin-bottom: 1.3em;
  color: #c5c5c5;
  letter-spacing: 0.1em;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase; }

.DepositModal .formTitle small {
  font-weight: normal;
  font-size: 0.75em;
  color: #a5a5a5;
  margin-right: 0.25em; }

.DepositModal .formTitle strong {
  font-weight: normal;
  font-size: 0.75em;
  color: #2db87e;
  display: inline-block;
  margin-right: 0.2em;
  text-transform: none; }

#pickPaymentMethodType label {
  text-align: center; }

.DepositModal .accountChoiceItem {
  width: 100%;
  padding: 1em 0.85em;
  padding: 1em 0.85em;
  padding-left: 1.1em;
  border-radius: 4px;
  background: #474747;
  color: #bababa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2em;
  letter-spacing: 0.04em;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin: 1em 0;
  border: none; }

.accountChoiceItem:disabled {
  opacity: 0.45; }

.accountChoiceItem:active {
  -webkit-transform: scale(0.99);
          transform: scale(0.99); }

.accountChoiceItem svg {
  width: 24px;
  height: 24px; }

.accountChoiceItem svg.circle circle {
  cx: 12;
  cy: 12;
  r: 10;
  fill: transparent;
  stroke: #aaa;
  stroke-width: 2; }

.accountChoiceItem.selected svg.circle,
.accountChoiceItem:not(.selected) svg:not(.circle) {
  display: none; }

.accountChoiceItem .currency {
  font-size: 0.75em;
  margin-top: 0.1em; }

.accountChoiceItem .amount {
  font-size: 0.85em; }

.DepositModal .accountChoiceTitle {
  margin: 0;
  font-size: 1.25em;
  font-weight: normal;
  letter-spacing: 0.03em;
  margin-bottom: 0.4em; }

.DepositModal .accountChoice {
  box-sizing: border-box;
  font-size: 1.3em;
  letter-spacing: 0.05em;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 0.6em 0;
  text-align: left;
  color: #b5b3b3; }

.DepositModal .accountChoice .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #7e7e7e;
  color: #545454;
  margin-right: 0.6em;
  font-style: normal; }

.DepositModal .accountChoice:first-of-type .icon {
  border: 1px solid #919191;
  background-color: #5d5d5d;
  color: #b6b6b6; }

.DepositModal .accountChoice .icon svg {
  width: 24px;
  height: 24px; }

.DepositModal .accountChoice.selected .icon {
  background: #999;
  color: #272626; }

.DepositModal .accountChoice small {
  display: block;
  font-size: 0.75em;
  color: #8b8b8b; }

.DepositModal form > .accountChoice small {
  margin-top: -0.2em; }

.DepositModal form > label {
  color: #8a8a8a; }

.DepositModal form > .accountChoice {
  padding: 0.2em 0.5em;
  background: #4a4a4a;
  margin-bottom: 0.7em; }

.DepositModal form > .accountChoice:last-of-type {
  padding: 0.8em 0.5em; }

.DepositModal form > .accountChoice:last-of-type span {
  font-size: 1.1em; }

.DepositModal form > .accountChoice button {
  padding: 0.1em 0.3em;
  border: none;
  background: transparent;
  color: silver;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: 0.65em; }

.DepositModal form > .finlink-btn {
  font-size: 0.93em !important;
  margin-top: 1.3em !important; }

#addPaymentMethod .finlink-form-control-wrapper {
  margin-bottom: 45px !important; }

#addPaymentMethod .finlink-form-control-wrapper + button {
  margin-top: -30px; }

#addPaymentMethod .finlink-radio-button-group-wrapper {
  margin-bottom: 34px !important; }

@media all and (max-width: 400px) {
  .DepositModal {
    padding: 2em 0; }
  #DepositMoney .finlink-modal-content {
    padding: 1.5em 1em; }
  .DepositModal .logoImage {
    height: 50px; }
  .DepositModal .formTitle {
    font-size: 1.1em; }
  .DepositModal .accountChoiceItem {
    font-size: 1em; }
  .DepositModal .accountChoiceTitle {
    font-size: 0.95em; }
  .DepositModal .accountChoice {
    font-size: 1.05em; }
  .DepositModal .accountChoice .icon {
    width: 33px;
    height: 33px; }
  .DepositModal .accountChoice .icon svg {
    width: 20px;
    height: 20px; } }

@media (max-width: 330px) {
  .DepositModal {
    padding: 0.5em 0; }
  .DepositModal .formTitle {
    font-size: 1.1em;
    margin-bottom: 0.95em; } }

.recepient-type-picker{
    display: flex;
    justify-content: center;
    margin-top: -1em;
    margin-bottom: 1.6em;
}

.recepient-types{
    width: 90vw;
    max-width: 330px;
    margin: 0 auto;
    display: inline-flex;
    align-items: center;
    border-radius: 50px;
    overflow: hidden;
    position: relative;
}

.recepient-types:after{
    content: '';
    position: absolute;
    left: 50%;
    top: 25%;
    bottom: 25%;
    width: 1px;
    background: #6b6b6b;
}

.recepient-type-choice{
    margin-top: 0 !important;
    padding: 0 1.6em;
    height: 53px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    border: none;
    background: #313030;
    width: 50%;
    flex-shrink: 0;
    text-align: center;
    color: #b2b1b1;
    font-weight: bold;
}

.recepient-type-choice span{
    margin-bottom: 0.2em;
}

.recepient-type-choice.selected{
    color: #9dd5d5;
}

@media all and (max-width: 400px){
    .recepient-type-picker {
        margin-top: -0.5em;
        margin-bottom: 1em;
    }

    .recepient-type-choice {
        font-size: 0.8em;
        height: 45px;
    }
}
#TransferMoney {
  padding-top: 20px; }

#TransferMoney .finlink-modal {
  padding-top: 0; }

#TransferMoney .finlink-modal-header {
  background: transparent;
  border-bottom-color: transparent; }

#TransferMoney .finlink-modal-title {
  color: transparent; }

.TransferModal .logoImage {
  display: block;
  height: 70px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 1.5em; }

.TransferModal.center .logoImage {
  margin-top: 0 !important; }

.TransferModal .logoImage.circle {
  width: 70px; }

.TransferModal .logoImage {
  height: 70px;
  border-radius: 50%;
  background: #bbb;
  color: #3c3c3d;
  font-size: 1.5em;
  letter-spacing: 0.04em;
  display: flex; }

.TransferModal .logoImage svg {
  width: 35px;
  height: 35px;
  fill: unset; }

.TransferModal .form {
  margin: 0 auto;
  margin-bottom: 20px; }

.TransferModal .formTitle {
  font-size: 1.65em;
  margin-top: 1em;
  margin-bottom: 1.3em;
  color: #c5c5c5;
  letter-spacing: 0.06em;
  text-align: center;
  font-weight: bold;
  text-transform: none; }

.TransferModal .formTitle small {
  font-weight: normal;
  font-size: 0.75em;
  color: #a5a5a5;
  margin-right: 0.25em; }

.TransferModal .formTitle strong {
  font-weight: normal;
  font-size: 0.75em;
  color: #2db87e;
  display: inline-block;
  margin-right: 0.2em;
  text-transform: none; }

.transferToAccounts .form label {
  font-size: 1.2em;
  margin-bottom: 1.4em;
  display: block;
  text-align: center;
  letter-spacing: 0.03em; }

.TransferModal .form > .finlink-btn {
  margin-top: 1.1em !important;
  outline: none;
  font-size: 1.1em; }

@media all and (max-width: 400px) {
  .TransferModal {
    padding: 2em 0;
    margin-top: -2em; }
  .TransferModal .logoImage {
    height: 60px !important;
    width: 60px !important; }
  .TransferModal .logoImage svg {
    width: 28px;
    height: 28px; }
  .TransferModal .formTitle {
    font-size: 1.1em; } }

@media (max-width: 330px) {
  .TransferModal {
    padding: 0.5em 0; }
  .TransferModal .formTitle {
    font-size: 1.3em;
    margin-bottom: 0.95em; }
  .finlink-modal-content {
    padding: 1.5em 0.7em; }
  .transferToAccounts .form label {
    font-size: 0.9em;
    margin-bottom: 0.9em; }
  .TransferModal .finlink-btn.rounded {
    padding: 10px 30px; } }

.account-picker{
    z-index: 5;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.account-picker-backdrop{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(56, 56, 56, 0.94);
    will-change: opacity;
    -webkit-transition: opacity 0.15s ease-out;
    transition: opacity 0.15s ease-out;
}

.account-picker:not(.visible) .account-picker-backdrop{
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-out;
    transition: opacity 0.2s ease-out;
}

.account-picker-content{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #444;
    box-shadow: 0 -25px 50px rgba(0, 0, 0, 0.08);
    max-width: 408px;
    margin: auto;
    padding-bottom: 5em;
    max-height: 100vh;
    overflow-y: auto;
    will-change: opacity, transform;
    -webkit-transition: opacity 0.1s ease-out, -webkit-transform 0.25s ease-out;
    transition: opacity 0.1s ease-out, -webkit-transform 0.25s ease-out;
    transition: transform 0.25s ease-out, opacity 0.1s ease-out;
    transition: transform 0.25s ease-out, opacity 0.1s ease-out, -webkit-transform 0.25s ease-out;
}

.account-picker:not(.visible) .account-picker-content{
    -webkit-transform: translateY(60%);
            transform: translateY(60%);
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
    transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

.account-picker h3{
    padding: 1em 1.2em;
    font-size: 1.4em;
    color: #a6a6a6;
    text-align: center;
    letter-spacing: 0.03em;
    border-bottom: 1px solid #4e4e4e;
}

.account-picker h5{
    padding: 0.3em 1em;
    font-size: 1.2em;
    font-weight: 500;
    color: #7f7f7f;
    letter-spacing: 0.04em;
    text-transform: uppercase;
}

.account-picker-content #accounts{
    
}

.account-picker-content .account-choice{
    padding: 1em 0.9em;
    padding-right: 0em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.3em;
    letter-spacing: 0.08em;
    text-transform: capitalize;
    border: none;
    background: transparent;
    outline: none;
    color: #aeaeae;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    touch-action: none;
    width: 100%;
    -moz-text-align-last: left;
         text-align-last: left;
    border-bottom: 1px solid #4e4e4e;
}

.account-picker-content .account-choice:last-of-type{
    border-bottom-color: transparent;
}

.account-choice .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border: 2px solid #919191;
    border-radius: 50%;
    color: #545454;
    margin-right: 0.6em;
    font-style: normal;
    background: transparent;
}

.account-choice .icon svg{
    width: 16px;
    height: 16px;
    stroke: #dbdbdb;
    opacity: 0;
}

.account-picker-content .account-choice.selected .icon{
    background: rgba(126, 126, 126, 0.57);
}

.account-picker-content .account-choice.selected .icon svg{
    opacity: 1;
}

.account-picker-actions{
    padding: 0.7em 0;
    padding-left: 0.3em;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #444444;
    border-top: 1px solid #4e4e4e;
}

.account-picker-actions .finlink-btn.rounded{
    background: #535353;
    background: transparent;
    border-color: #686868;
    border: none;
    color: #b0b0b0;
    font-size: 1em;
    padding: 0.5em 0.8em;
    letter-spacing: 0.1em;
    color: #cfcfcf;
}

.account-picker-actions .finlink-btn.rounded:last-child{
    color: #00f0f3;
}
.accountChoiceItem {
  width: 100%;
  padding: 1em 0.85em;
  padding: 0.75em 0.85em;
  padding-left: 1.1em;
  border-radius: 4px;
  background: #474747;
  color: #cecece;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2em;
  letter-spacing: 0.04em;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin: 1em 0;
  border: none; }

.accountChoiceItem:active {
  -webkit-transform: scale(0.99);
          transform: scale(0.99); }

.accountChoiceItem svg {
  width: 24px;
  height: 24px;
  fill: #636262;
  display: block !important; }

.accountChoiceItem.selected svg {
  fill: #2fb980; }

.accountChoiceItem .currency {
  font-size: 0.75em;
  margin-top: 0.1em; }

.accountChoiceItem .amount {
  font-size: 0.85em; }

@media all and (max-width: 400px) {
  .accountChoiceItem {
    padding: 0.8em 0.9em;
    border-radius: 2px;
    margin-bottom: 0.3em;
    font-size: 1.1em; } }

@media (max-width: 330px) {
  .accountChoiceItem {
    margin-bottom: 0.7em; }
  .TransferModal .finlink-btn.rounded {
    padding: 10px 30px; } }

#transferToInvestorForm .finlink-form-control,
#transferToInvestorForm .finlink-form-control input {
  background: transparent !important; }

#transferToInvestorForm .finlink-form-control,
#transferToInvestorForm .finlink-form-control input {
  padding-left: 0; }

#transferToInvestorForm .finlink-form-control label {
  font-size: 0.9rem;
  top: -17px !important; }

#transferToInvestorForm .finlink-form-control.filled label {
  opacity: 0.6; }

#transferToInvestorForm #mainAccountAvailableBalance {
  margin-top: -0.7rem;
  margin-bottom: 0.7rem;
  font-size: 1rem;
  letter-spacing: 0.04em;
  opacity: 0.85; }

#SetTransferInvestor form {
  margin: 0 -0.4rem;
  padding-top: 4.5rem; }

#SetTransferInvestor .finlink-modal-header .finlink-btn.action {
  margin-top: 0.2rem !important;
  outline: none; }

#SetTransferInvestor .finlink-modal-title {
  color: #ddd; }

#SetTransferInvestor .finlink-form-control,
#SetTransferInvestor .finlink-form-control input {
  padding-left: 0 !important;
  background: transparent !important;
  font-size: 1.2rem;
  letter-spacing: 0.02em; }

#SetTransferInvestor .finlink-form-control label {
  top: -18px !important;
  font-size: 0.95rem; }

#SetTransferInvestor .finlink-form-control .prepend {
  font-size: 1.2rem; }

#SetTransferInvestor .RequestLoanSection > .finlink-btn.primary {
  font-size: 1.2rem;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: auto;
  border-radius: 0; }

@media all and (max-width: 330px) {
  #SetTransferInvestor .finlink-form-control .prepend,
  #SetTransferInvestor .finlink-form-control input {
    font-size: 1.45em; }
  #SetTransferInvestor .RequestLoanSection > .finlink-btn.primary {
    font-size: 1rem; } }

.transfer-to{
    z-index: 5;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.transfer-to-backdrop{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(56, 56, 56, 0.94);
    will-change: opacity;
    -webkit-transition: opacity 0.15s ease-out;
    transition: opacity 0.15s ease-out;
}

.transfer-to:not(.visible) .transfer-to-backdrop{
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-out;
    transition: opacity 0.2s ease-out;
}

.transfer-to-content{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #444;
    box-shadow: 0 -25px 50px rgba(0, 0, 0, 0.08);
    max-width: 408px;
    margin: auto;
    max-height: 100vh;
    overflow-y: auto;
    will-change: opacity, transform;
    -webkit-transition: opacity 0.1s ease-out, -webkit-transform 0.25s ease-out;
    transition: opacity 0.1s ease-out, -webkit-transform 0.25s ease-out;
    transition: transform 0.25s ease-out, opacity 0.1s ease-out;
    transition: transform 0.25s ease-out, opacity 0.1s ease-out, -webkit-transform 0.25s ease-out;
}

.transfer-to:not(.visible) .transfer-to-content{
    -webkit-transform: translateY(60%);
            transform: translateY(60%);
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
    transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

.transfer-to h3{
    padding: 1rem 1.2rem;
    margin-bottom: -0.4rem;
    font-size: 1.1rem;
    color: #aaa;
    text-align: center;
    letter-spacing: 0.01em;
}

.transfer-to h5{
    padding: 0.3em 1em;
    font-size: 1.2em;
    font-weight: 500;
    color: #7f7f7f;
    letter-spacing: 0.04em;
    text-transform: uppercase;
}

.transfer-to-content .transfer-to-choice{
    padding: 0.8rem;
    padding-right: 0em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2rem;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    border: none;
    background: transparent;
    outline: none;
    color: #bbb;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    touch-action: none;
    width: 100%;
    -moz-text-align-last: left;
         text-align-last: left;
    border-bottom: 1px solid #4e4e4e;
}

.transfer-to-content .transfer-to-choice.selected{
    color: #efefef;
}

.transfer-to-content .transfer-to-choice:last-of-type{
    border-bottom-color: transparent;
}

.transfer-to-choice .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    border: 2px solid #919191;
    border-radius: 50%;
    color: #545454;
    margin-right: 0.6em;
    font-style: normal;
    background: transparent;
}

.transfer-to-choice .icon svg{
    width: 12px;
    height: 12px;
    stroke-width: 3;
    stroke: #dbdbdb;
    opacity: 0;
}

.transfer-to-content .transfer-to-choice.selected .icon{
    background: rgba(126, 126, 126, 0.35);
    border-color: #aaa;
}

.transfer-to-content .transfer-to-choice.selected .icon svg{
    opacity: 1;
    stroke: #eee;
}

.transfer-to-actions{
    padding: 0.6rem 0;
    background: #444444;
    border-top: 1px solid #4e4e4e;
}

.transfer-to-actions .finlink-btn{
    text-transform: none;
    font-size: 1.1rem;
}

.transfer-to-actions .finlink-btn.rounded{
    background: #535353;
    background: transparent;
    border-color: #686868;
    border: none;
    padding: 0.5em 0.8em;
    letter-spacing: 0.05em;
    color: #bbb;
}

.transfer-to-actions .finlink-btn.rounded:last-child{
    color: #00f0f3;
}
.TransferModal .form label {
  font-size: 1.2em;
  margin-bottom: 1.4em;
  display: block;
  letter-spacing: 0.03em; }

@media (max-width: 330px) {
  .TransferModal .form label {
    font-size: 0.9em;
    margin-bottom: 0.9em; } }

.accountDetailFragment {
  background-color: #3e3e3e;
  background: #272726;
  background-color: #424242 !important;
  padding-top: 275px; }

.account-detail-nav {
  top: 0;
  border-bottom: none;
  background: #383837; }

.stickyHeader {
  background: #383837;
  position: fixed;
  top: 59px;
  left: 0;
  right: 0;
  z-index: 1; }

.accountDetailSection {
  padding: 1.5em 1.3em; }

.accountDetailSection .transactionItem:first-of-type {
  border-top: none !important; }

.accountDetailSection .sectionTitle {
  font-size: 1.24em;
  font-weight: 100;
  color: #b7b7b7;
  letter-spacing: 0.035em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.4em; }

.accountActions {
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(0.5em);
          transform: translateX(-50%) translateY(0.5em);
  max-width: 330px;
  width: 100%;
  margin-top: 0.7em;
  margin-bottom: -1.2em;
  display: inline-block;
  border-radius: 60px;
  background-color: #505554;
  border: 1px solid #868c8c;
  padding: 0 0.6em;
  padding-right: 0.7em; }

.action-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.8em 0;
  flex-shrink: 0;
  color: #d0cece;
  text-align: center;
  letter-spacing: 0.06em;
  background: transparent;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  font-size: 0.9rem;
  position: relative;
  width: 50%;
  flex-shrink: 0; }

.accountActions.main .action-item {
  width: 33.333%; }

.action-item:not(:last-child):after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1px;
  width: 1px;
  background: #777777; }

.action-item:last-child {
  padding-right: 0.3em; }

.action-item .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 0.35em;
  border: 1px solid #767575;
  background: #2e2d2d; }

.action-item.deposit .icon {
  background-color: #7eaca9;
  border-color: #354848; }

.action-item .icon svg {
  stroke: #888888;
  width: 18px;
  height: 18px; }

.action-item.deposit svg {
  stroke: #404847; }

.action-item.withdrawal svg {
  stroke: #da4d4d; }

@media all and (max-width: 400px) {
  .stickyHeader {
    top: 52px; }
  .accountDetailFragment {
    padding-top: 240px; }
  .accountSwitcherButtons button {
    width: 40px;
    height: 40px; }
  .accountSwitcherButtons button svg {
    width: 26px;
    height: 26px; }
  .accountDetailSection {
    padding: 1.5em 0.7em; }
  .accountDetailSection .sectionTitle {
    margin-bottom: 0em; } }

@media all and (max-width: 330px) {
  .stickyHeader {
    top: 47px; }
  .accountActions {
    width: calc(100% - 2em); }
  .action-item {
    padding: 0.8em 0;
    font-size: 0.83em; } }

#LoanDetail {
  overflow: hidden; }

#LoanDetailContent {
  overflow-y: auto; }

.loan-request-details {
  padding: 1rem 1.2rem;
  margin-bottom: 1.1rem; }

.loan-request-details h3 {
  font-weight: normal;
  font-size: 1.6rem;
  color: #fff;
  line-height: 1.7em;
  letter-spacing: 0.03em;
  margin-bottom: 0.7rem; }

.loan-request-details h5 {
  font-size: 1.5rem;
  line-height: 1.55em;
  color: #c6c6c6;
  letter-spacing: 0.015em;
  margin-bottom: 1rem; }

.loan-request-details h5 small {
  font-weight: normal;
  font-size: 0.75em;
  margin-right: 0.4rem;
  margin-bottom: 0.13rem; }

.loan-request-details span {
  display: inline-block;
  font-size: 0.9rem;
  font-weight: bold;
  letter-spacing: 0.12em;
  padding: 0.5rem 0.7rem;
  padding-top: 0.283rem;
  color: #e5e5e5;
  border: 1px solid #999;
  border-radius: 20px;
  margin-bottom: 1.25rem; }

.loan-request-details.rejected span {
  border-color: #ffc2c2;
  color: #ffc2c2; }

.loan-request-details.approved span {
  background-color: #99e3c4;
  border-color: #95e6c4;
  color: #042819; }

.loan-request-details span small {
  font-size: 2.3rem;
  line-height: 0;
  margin-right: 0.1rem;
  margin-top: 0.07rem; }

.detail-text {
  color: #e3e3e3;
  font-size: 1.2rem; }

.detail-text label {
  color: #c9c9c9;
  font-size: 0.9em;
  display: inline-block;
  margin-right: 0.8rem;
  letter-spacing: 0.02em; }

#LoanProgressTitle {
  color: #ddd;
  font-size: 1.3rem;
  padding-left: 1.2rem;
  margin-bottom: 1.3rem;
  font-weight: normal;
  letter-spacing: 0.03rem; }

#LoanTimeLine {
  border-top: 1px solid #797877;
  position: relative;
  padding-bottom: 0.1rem;
  overflow: hidden; }

.loan-timeline-step {
  position: relative;
  padding-left: 3rem;
  padding-right: 0.8rem;
  margin: 2.5rem 0;
  color: #5d5a58; }

.loan-timeline-step.complete {
  color: rgba(138, 226, 189, 0.949); }

.loan-timeline-step.complete.error {
  color: #ffc2c2; }

.loan-timeline-step:before {
  content: "";
  position: absolute;
  top: -120px;
  height: 100%;
  left: 1.2rem;
  border-left: 3px dashed currentColor; }

.loan-timeline-step:after {
  content: "";
  position: absolute;
  top: 4px;
  left: 14px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: currentColor;
  z-index: 1; }

.loan-timeline-step .ListItem {
  border-width: 1px;
  background: #474645; }

.loan-timeline-step .ListItem h5 {
  text-transform: capitalize; }

@media all and (max-width: 330px) {
  .loan-request-details {
    padding: 1rem 1.2rem;
    margin-bottom: 0.3rem; }
  .loan-request-details h3 {
    font-size: 1.2rem;
    margin-bottom: 0.4rem; }
  .loan-request-details h5 {
    font-size: 1.2rem;
    line-height: 1.55em;
    margin-bottom: 0.7rem; }
  .loan-request-details h5 small {
    font-size: 0.75em; }
  .loan-request-details span {
    font-size: 0.7rem;
    padding: 0.35rem 0.7rem;
    padding-top: 0.2rem;
    margin-bottom: 0.9rem; }
  .detail-text {
    font-size: 0.9rem; }
  #LoanProgressTitle {
    font-size: 1rem; }
  #LoanTimeLine {
    border-color: #5f5f5f; }
  .loan-timeline-step {
    padding-left: 2.6rem;
    padding-right: 0.7rem;
    margin: 1.5rem 0; }
  .loan-timeline-step:before {
    content: "";
    position: absolute;
    top: -97px;
    height: 100%;
    left: 1.2rem;
    border-left: 3px dashed currentColor; }
  .loan-timeline-step:after {
    top: 4px;
    left: 14px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: currentColor; }
  .loan-timeline-step .ListItem {
    border-width: 1px;
    background: #474645; } }

.ListItem {
  text-align: left;
  display: flex;
  padding: 0.8rem 1.1rem;
  min-height: 65px;
  width: 100%;
  border: 2px solid #7b7b7b;
  border-radius: 4px;
  background: transparent;
  outline: none;
  -webkit-tap-highlight-color: transparent; }

.ListItem:not(.two-line) {
  align-items: center; }

.ListItem:active {
  background: #4a4c4c;
  border-color: #788382; }

.ListItem.selected {
  background: #4a4c4c;
  border-color: #788382; }

.ListItem .icon,
.ListItem .image {
  width: 39px;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-right: 0.9rem;
  margin-left: -0.15rem;
  color: #e0e0e0;
  flex-shrink: 0; }

.ListItem .icon svg {
  width: 39px;
  height: 39px; }

.ListItem .image {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.25); }

.ListItem .image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; }

.ListItem .text {
  flex: 1 1;
  min-width: 0; }

.ListItem h5 {
  color: #e6e6e6;
  font-weight: 500;
  font-size: 1.35rem;
  line-height: 1.4;
  margin: 0; }

.ListItem.selected h5 {
  color: #bfeae5; }

.ListItem p {
  color: #b8b8b8;
  font-size: 1.1rem;
  line-height: 1.5em;
  margin: 0; }

.ListItem .choice-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  flex-shrink: 0;
  align-self: center;
  margin-top: 0.2rem;
  margin-left: 1rem;
  border: 2px solid currentColor;
  color: #dedede; }

.ListItem.selected .choice-indicator {
  color: #b8dad6; }

.ListItem .choice-indicator svg {
  width: 13px;
  height: 13px;
  stroke-width: 5px;
  margin-top: 1px; }

.ListItem:not(.selected) .choice-indicator svg {
  opacity: 0; }

.OptionPicker .action-buttons {
  padding: 0 1em;
  margin-top: 0.8rem;
  margin-bottom: -0.5rem;
  overflow: hidden;
  justify-content: space-around; }

.ListItem .finlink-btn.action {
  background: #686868;
  color: #dedede; }

@media screen and (max-width: 400px) {
  .ListItem .image,
  .ListItem .icon {
    width: 32px;
    height: 32px;
    margin-right: 1.2rem; }
  .ListItem h5 {
    font-size: 1.3rem; }
  .ListItem:not(.two-line) h5 {
    margin-top: 0.15rem; }
  .ListItem p {
    font-size: 1rem; }
  .ListItem .choice-indicator {
    width: 25px;
    height: 25px; }
  .ListItem .choice-indicator svg {
    width: 15px;
    height: 15px; }
  .OptionPicker .action-buttons {
    margin-top: 1.3rem;
    margin-bottom: 0;
    line-height: 1; }
  .OptionPicker .action-button {
    font-size: 1.25rem;
    padding: 0.6rem 1rem;
    min-width: 110px; } }

@media screen and (max-width: 330px) {
  .OptionPicker h3 {
    font-size: 1.5rem;
    padding: 0 0.7rem; }
  .ListItem {
    min-height: 50px;
    padding: 0.3rem 0.8rem; }
  .ListItem .icon,
  .ListItem .image {
    width: 26px;
    height: 26px;
    margin-right: 1.2rem; }
  .ListItem .icon svg {
    width: 26px;
    height: 26px; }
  .ListItem h5 {
    font-size: 1rem; }
  .ListItem:not(.two-line) h5 {
    margin-top: 0.15rem; }
  .ListItem p {
    font-size: 1rem; }
  .ListItem .choice-indicator,
  .ListItem .finlink-btn.action {
    width: 20px;
    height: 20px; }
  .ListItem .choice-indicator svg {
    width: 10px;
    height: 10px;
    margin-top: 0; }
  .ListItem .finlink-btn.action svg {
    width: 14px;
    height: 14px; } }

#Notifications {
  overflow: hidden; }

#NotificationsContent {
  overflow-y: auto; }

.notification-item {
  padding: 1.2rem; }

.notification-item.unread {
  background: #424242; }

.notification-item + .notification-item {
  border-top: 1px solid #4d4d4d;
  border-top: 1px solid #565656; }

.notification-item .icon {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  flex-shrink: 0;
  background: #555;
  margin-top: 0.3rem;
  margin-right: 1.2rem;
  color: #fff; }

.notification-item .icon svg {
  width: 22px;
  height: 22px;
  fill: currentColor; }

.notification-item h5 {
  font-size: 1.15rem;
  line-height: 1.55em;
  color: #bfbfbf; }

.notification-item span {
  top: 1.57rem;
  right: 0.9rem;
  font-size: 0.8rem;
  letter-spacing: 0.12em;
  padding: 0.3rem 0.7rem;
  padding-bottom: 0.5rem;
  color: #c8c8c8;
  border: 1px solid #555;
  border: 1px solid #999;
  border-radius: 20px; }

.notification-item.rejected span {
  color: #ffafaf; }

.notification-item.approved span {
  color: #95e6c4; }

.notification-item span small {
  font-size: 2.3rem;
  line-height: 0;
  margin-right: 0.1rem;
  margin-top: 0.07rem; }

@media all and (max-width: 330px) {
  #NotificationsContent {
    padding: 0.8rem; }
  .notification-item {
    padding: 1rem 0; }
  .notification-item + .notification-item {
    border-top: 1px solid #4d4d4d; }
  .notification-item .icon {
    width: 40px;
    height: 40px;
    margin-top: 0.15rem;
    margin-right: 0.8rem; }
  .notification-item .icon svg {
    width: 19px;
    height: 19px; }
  .notification-item h5 {
    font-size: 1rem;
    line-height: 1.45em; }
  .notification-item span {
    top: 0.9rem;
    right: 0.6rem;
    font-size: 0.6rem;
    color: #aaa;
    border: 1px solid #777; }
  .notification-item span small {
    display: none; } }

#pageTitle {
  padding: 1.1rem;
  padding-top: 1.3rem;
  border-bottom: 1px solid #555; }

#pageTitle h2 {
  color: #cecece;
  color: #fff;
  font-size: 1.8em;
  font-weight: normal;
  margin-left: 0.3rem;
  margin-bottom: 1rem; }

#pageTitle small {
  color: #cecece;
  font-size: 1.4rem;
  display: block;
  margin-bottom: 0.5rem; }

#pageTitle .finlink-btn {
  color: #add; }

#dp {
  width: 95px;
  height: 95px;
  border-radius: 50%;
  background: #555;
  border: 2px solid #888;
  margin-left: auto;
  margin-right: -0.2rem;
  margin-top: -1.1rem;
  color: #fff;
  font-size: 1.8rem;
  letter-spacing: 0.1em; }

.profile-section {
  padding: 1.1rem;
  border-bottom: 1px solid #555; }

.profile-section-title {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: normal;
  color: #aaa;
  letter-spacing: 0.02em;
  margin-bottom: 0.75rem; }

.profile-detail {
  color: #777;
  padding: 0.7em 0; }

.profile-detail small {
  font-size: 1.32rem;
  font-weight: 300;
  color: #fff;
  letter-spacing: 0.03em; }

#preferences small {
  line-height: 1.4em;
  font-weight: 300;
  margin: 0.2rem 0; }

.profile-detail small + h2 {
  margin-top: 0.4rem; }

.profile-detail h2 {
  color: #cecece;
  font-size: 1.3rem;
  font-weight: 300; }

.profile-section .muno-switch {
  margin-left: 1rem; }

.profile-section .finlink-btn {
  font-size: 16px;
  color: #add;
  padding-left: 0; }

.profile-section .payment-method-icon {
  width: 50px;
  height: 50px;
  background-color: #515151;
  border-radius: 50%;
  margin-right: 0.5rem;
  color: #ccc;
  font-size: 24px; }

#logoutBtn {
  font-size: 19px;
  border-color: #4c4b4a;
  padding: 0.7em 30px; }

.muno-switch input{
    display: none;
}

.muno-switch.loading{
    opacity: 0.8;
    pointer-events: none;
}

.muno-switch label {
    position: relative;
    display: inline-flex;
    align-items: center;
    border-radius: 50px;
    overflow: hidden;
    outline: none;
    padding: 1px;
    width: 46px;
}

.muno-switch label:before,
.muno-switch label:after{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #a7a7a7;
    background: #676767;
    border-radius: inherit;
}

.muno-switch label:after{
    z-index: 1;
    background: #79afaf;
    -webkit-transition: opacity 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: opacity 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.muno-switch:not(.checked) label:after{
    opacity: 0;
}

.muno-switch .muno-switch-knob {
    position: relative;
    z-index: 2;
    color: #fff;
    width: 25px;
    height: 25px;
    -webkit-transition: -webkit-transform 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: -webkit-transform 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: transform 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: transform 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86), -webkit-transform 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.muno-switch .muno-switch-knob svg:first-child{
    position: absolute;
    width: 100%;
    height: 100%;
}

.muno-switch svg:last-child{
    position: absolute;
    top: 6px;
    left: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    fill: #222;
    opacity: 0;
    pointer-events: none;
}

.muno-switch.loading .muno-switch-knob svg:last-child{
    opacity: 1;
    -webkit-animation: spin 0.6s linear infinite;
            animation: spin 0.6s linear infinite;
}

@-webkit-keyframes spin{
    from{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to{
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes spin{
    from{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to{
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.muno-switch.checked .muno-switch-knob{
    -webkit-transform: translateX(calc(100% - 6px));
            transform: translateX(calc(100% - 6px));
}

@media all and (max-width: 330px){
    .muno-switch label {
        font-size: 0.97em;
        width: 34px;
    }

    .muno-switch .muno-switch-knob {
        width: 18px;
        height: 18px;
    }

    .muno-switch.checked .muno-switch-knob {
        -webkit-transform: translateX(calc(100% - 5px));
                transform: translateX(calc(100% - 5px));
    }
    
    .muno-switch svg {
        width: 18px;
        height: 18px;
        margin-right: 0.3em;
    }
}
.editProfile {
  padding: 2em;
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 4em; }

.editProfile .form {
  margin: 0 auto;
  margin-bottom: 20px; }

.editProfile .form p {
  font-size: 1.13em;
  line-height: 1.5em;
  margin-bottom: 2.9em;
  color: #bbbbbb;
  text-align: center;
  letter-spacing: 0.05em; }

.editProfile .form button:not(.flat) {
  margin-top: -0.6em;
  outline: none; }

.editProfile .logoImage {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin-top: 0 !important;
  margin-bottom: 1.3em;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background: #bbb;
  color: #3c3c3d;
  font-size: 1.5em;
  letter-spacing: 0.04em;
  padding-top: 0.2em; }

.editProfile .form {
  margin: 0 auto;
  margin-bottom: 20px; }

.editProfile .formTitle {
  font-size: 1.4em;
  margin-top: 1em;
  margin-bottom: 2.5em;
  color: #9e9e9e;
  letter-spacing: 0.1em;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase; }

@media all and (max-width: 400px) {
  .editProfile .editProfile {
    padding: 2em 1.5em; } }

@media (max-width: 330px) {
  .editProfile .form p {
    margin-bottom: 1.8em; } }

.finlink-fab{
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00f0f3;
    box-shadow: none;
    padding: 0;
    outline: none;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    position: fixed;
    right: 16px;
    bottom: 16px;
    z-index: 50;
    border: none;
    -webkit-transition: opacity 0.2s ease-out, -webkit-transform 0.35s ease-out;
    transition: opacity 0.2s ease-out, -webkit-transform 0.35s ease-out;
    transition: transform 0.35s ease-out, opacity 0.2s ease-out;
    transition: transform 0.35s ease-out, opacity 0.2s ease-out, -webkit-transform 0.35s ease-out;
}

.finlink-fab.centered{
    left: 0;
    right: 0;
    margin: 0 auto;
}

.finlink-fab.hidden{
    pointer-events: none;
    opacity: 0;
    -webkit-transform: scale(0) rotate(20deg);
            transform: scale(0) rotate(20deg);
}

.finlink-fab svg{
    width: 24px;
    height: 24px;
    fill: #133334;
}
.transactionsFragment{
    /* padding: 2.1em 1.3em; */
    padding: 0.5em 1em;
}

.transactionsFragment .transactionItem:first-child{
    border-top: none;
    border-bottom: none;
}

#filterFab{
    border-radius: 8px;
    width: auto;
    /* width: 136px; */
    height: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    padding: 0.8em 1em;
    -webkit-transition: opacity 0.15s ease-out, -webkit-transform 0.2s ease-out;
    transition: opacity 0.15s ease-out, -webkit-transform 0.2s ease-out;
    transition: transform 0.2s ease-out, opacity 0.15s ease-out;
    transition: transform 0.2s ease-out, opacity 0.15s ease-out, -webkit-transform 0.2s ease-out;
}

#filterFab.hidden{
    -webkit-transform: translateX(-50%) scaleX(0.6);
            transform: translateX(-50%) scaleX(0.6);
}

#filterFab span{
    margin-left: 0.5em;
    font-size: 1.1em;
    text-transform: uppercase;
    margin-right: 0.2em;
    letter-spacing: 0.12em;
    font-weight: 300;
}

@media all and (max-width: 400px){
    #filterFab{
        border-radius: 8px;
        padding: 0.5em 0.7em;
    }

    #filterFab svg{
        width: 23px;
        height: 23px;
    }
    
    #filterFab span{
        font-size: 1.15em;
    }
}
.ot-mobile-nav{
    position: fixed;
    bottom: 0;
    background: #4e4d4d;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    width: 100vw;
    z-index: 10;
    padding: 0.5rem 0;
    padding-top: 0.65rem;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
}

.bottom-nav-wrapper:not(.visible) .ot-mobile-nav{
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    opacity: 0;
}

.ot-mobile-nav button{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1;
    flex-shrink: 0;
    color: #c3c3c3;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    margin-right: 0.1em;
    border: none;
    background: transparent;
    letter-spacing: 0.04em;
    font-size: 0.8rem;
}

.ot-mobile-nav button .icon{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.ot-mobile-nav button svg{
    width:24px;
    height:24px;
    fill:none;
    stroke-width:2;
    stroke-linecap:round;
    stroke-linejoin:round;
    margin-bottom: 0.3em;
    position: relative;
}

@media all and (max-width: 400px){
    .ot-mobile-nav button{
        font-size: 12px;
    }
    
    .ot-mobile-nav button svg{
        width:20px;
        height:20px;
        margin-bottom: 0.2em;
    }
}

@media all and (max-width: 330px){
    .ot-mobile-nav button{
        font-size: 11px;
    }
    
    .ot-mobile-nav button svg{
        width:17px;
        height:17px;
    }
}
#WithdrawMoney {
  padding-top: 20px; }

#WithdrawMoney > .finlink-modal {
  padding-top: 0; }

#WithdrawMoney > .finlink-modal > .finlink-modal-header {
  background: transparent;
  border-bottom-color: transparent; }

#WithdrawMoney > .finlink-modal > .finlink-modal-header .finlink-modal-title {
  color: transparent; }

#AddMobileWallet .finlink-modal-content {
  padding-top: 3.5em; }

.WithdrawModal .logoImage {
  display: block;
  height: 70px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 1.5em; }

.WithdrawModal.center .logoImage {
  margin-top: 0 !important; }

.WithdrawModal .logoImage.circle {
  width: 70px; }

.WithdrawModal .logoImage {
  height: 70px;
  border-radius: 50%;
  background: #bbb;
  color: #3c3c3d;
  font-size: 1.5em;
  letter-spacing: 0.04em;
  display: flex; }

.WithdrawModal .logoImage svg {
  width: 35px;
  height: 35px;
  fill: unset; }

.WithdrawModal .form {
  margin: 0 auto;
  margin-bottom: 20px; }

.WithdrawModal .formTitle {
  font-size: 1.4em;
  margin-top: 1em;
  margin-bottom: 1.3em;
  color: #c5c5c5;
  letter-spacing: 0.1em;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase; }

.WithdrawModal .formTitle small {
  font-weight: normal;
  font-size: 0.75em;
  color: #a5a5a5;
  margin-right: 0.25em; }

.WithdrawModal .formTitle strong {
  font-weight: normal;
  font-size: 0.75em;
  color: #2db87e;
  display: inline-block;
  margin-right: 0.2em;
  text-transform: none; }

.WithdrawModal .form label {
  font-size: 1.2em;
  display: block;
  letter-spacing: 0.03em; }

#pickPaymentMethodType label {
  font-size: 1.2em;
  margin-bottom: 1em;
  display: block;
  text-align: center; }

.WithdrawModal .accountChoiceItem {
  width: 100%;
  padding: 1em 0.85em;
  padding: 1em 0.85em;
  padding-left: 1.1em;
  border-radius: 4px;
  background: #474747;
  color: #bababa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2em;
  letter-spacing: 0.04em;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin: 1em 0;
  border: none; }

.accountChoiceItem:disabled {
  opacity: 0.45; }

.accountChoiceItem:active {
  -webkit-transform: scale(0.99);
          transform: scale(0.99); }

.accountChoiceItem svg {
  width: 24px;
  height: 24px; }

.accountChoiceItem svg.circle circle {
  cx: 12;
  cy: 12;
  r: 10;
  fill: transparent;
  stroke: #aaa;
  stroke-width: 2; }

.accountChoiceItem.selected svg.circle,
.accountChoiceItem:not(.selected) svg:not(.circle) {
  display: none; }

.accountChoiceItem .currency {
  font-size: 0.75em;
  margin-top: 0.1em; }

.accountChoiceItem .amount {
  font-size: 0.85em; }

.WithdrawModal .accountChoiceTitle {
  margin: 0;
  font-size: 1.25em;
  font-weight: normal;
  letter-spacing: 0.03em;
  margin-bottom: 0.4em; }

.WithdrawModal .accountChoice {
  box-sizing: border-box;
  font-size: 1.3em;
  letter-spacing: 0.05em;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 0.6em 0;
  text-align: left;
  color: #b5b3b3; }

.WithdrawModal .accountChoice .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #7e7e7e;
  color: #545454;
  margin-right: 0.6em;
  font-style: normal; }

.WithdrawModal .accountChoice:first-of-type .icon {
  border: 1px solid #919191;
  background-color: #5d5d5d;
  color: #b6b6b6; }

.WithdrawModal .accountChoice .icon svg {
  width: 24px;
  height: 24px; }

.WithdrawModal .accountChoice.selected .icon {
  background: #999;
  color: #272626; }

.WithdrawModal .accountChoice small {
  display: block;
  font-size: 0.75em;
  color: #8b8b8b; }

.WithdrawModal form > .accountChoice small {
  margin-top: -0.2em; }

.WithdrawModal form > label {
  margin-top: 0.8em;
  margin-bottom: 0.1em;
  color: #8a8a8a; }

.WithdrawModal form > .accountChoice {
  padding: 0.2em 0.5em;
  background: #4a4a4a;
  margin-bottom: 0.7em; }

.WithdrawModal form > .accountChoice:last-of-type {
  padding: 0.8em 0.5em; }

.WithdrawModal form > .accountChoice:last-of-type span {
  font-size: 1.1em; }

.WithdrawModal form > .accountChoice button {
  padding: 0.1em 0.3em;
  border: none;
  background: transparent;
  color: silver;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: 0.65em; }

.WithdrawModal form > .finlink-btn {
  font-size: 0.93em !important;
  margin-top: 1.3em !important; }

#addPaymentMethod .finlink-form-control-wrapper,
#addPaymentMethod .finlink-form-control.focused label,
#addPaymentMethod .finlink-form-control.filled label,
#addPaymentMethod .finlink-form-control.has-prepend label,
#addPaymentMethod .finlink-form-control input[placeholder] ~ label {
  top: -22px !important; }

#addPaymentMethod .finlink-form-control-wrapper {
  margin-bottom: 45px !important; }

#addPaymentMethod .finlink-form-control-wrapper + button {
  margin-top: -30px; }

#addPaymentMethod .finlink-radio-button-group-wrapper {
  margin-bottom: 34px !important; }

#addPaymentMethod .finlink-radio-button-group > label {
  margin-bottom: 0px; }

@media all and (max-width: 400px) {
  .WithdrawModal {
    padding: 2em 0; }
  #WithdrawMoney .finlink-modal-content {
    padding: 1.5em 1em; }
  .WithdrawModal .logoImage {
    height: 50px; }
  .WithdrawModal .formTitle {
    font-size: 1.1em; }
  .WithdrawModal .form label {
    font-size: 0.98em; }
  #pickPaymentMethodType label {
    font-size: 0.98em; }
  .WithdrawModal .accountChoiceItem {
    font-size: 1em; }
  .WithdrawModal .accountChoiceTitle {
    font-size: 0.95em; }
  .WithdrawModal .accountChoice {
    font-size: 1.05em; }
  .WithdrawModal .accountChoice .icon {
    width: 33px;
    height: 33px; }
  .WithdrawModal .accountChoice .icon svg {
    width: 20px;
    height: 20px; } }

@media (max-width: 330px) {
  .WithdrawModal {
    padding: 0.5em 0; }
  .WithdrawModal .formTitle {
    font-size: 1.1em;
    margin-bottom: 0.95em; } }

#Slides {
  height: 100vh;
  width: 100vw; }

#SlidesProgress {
  top: 0;
  left: 0;
  height: 2px;
  background: #000; }

#SlidesProgress:before,
#SlidesProgress:after {
  content: '';
  position: absolute;
  height: inherit;
  background: inherit; }

#SlidesProgress:before {
  width: 100%;
  background: #ccc; }

#SlidesProgress:after {
  z-index: 1;
  left: 0;
  right: 0;
  -webkit-transition: width 0.35s ease-out;
  transition: width 0.35s ease-out; }

#SlidesCurrentStepLabel {
  text-transform: uppercase;
  top: 0;
  left: 0;
  right: 0;
  font-size: 1rem;
  letter-spacing: 0.05em;
  color: #ccc;
  color: #b3dcd9;
  background: #3e3e3d;
  padding: 0.85rem 1.4rem;
  z-index: 10;
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in; }

#SlidesCurrentStepLabel:not(.visible) {
  opacity: 0;
  -webkit-transition: opacity 0.1s ease-out;
  transition: opacity 0.1s ease-out; }

#SlidesCurrentStepLabel.centered {
  text-align: center; }

#SlidesContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  bottom: 106px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 20px; }

#Slides.no-actions:not(.show-submit-button) #SlidesContent {
  bottom: 50px; }

#Slides:not(.no-slide-animation) #SlidesContent > div {
  -webkit-transition: -webkit-transform 0.35s ease-out;
  transition: -webkit-transform 0.35s ease-out;
  transition: transform 0.35s ease-out;
  transition: transform 0.35s ease-out, -webkit-transform 0.35s ease-out; }

#SlidesContent > div > * {
  flex-shrink: 0;
  width: 100vw; }

#SlidesActions {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 56px;
  padding: 0.4em 1em;
  background: #484846;
  will-change: transform;
  -webkit-transition: -webkit-transform 0.35s ease-out;
  transition: -webkit-transform 0.35s ease-out;
  transition: transform 0.35s ease-out;
  transition: transform 0.35s ease-out, -webkit-transform 0.35s ease-out; }

#Slides.no-actions #SlidesActions {
  -webkit-transform: translateY(110%);
          transform: translateY(110%); }

.SlideBackButton {
  color: #ebebeb !important; }

.SlideBackButton span {
  display: flex;
  align-items: center; }

.SlideBackButton svg {
  position: relative;
  fill: currentColor; }

#SlidesActions button.disabled {
  opacity: 0.6;
  pointer-events: none; }

#Slides .SubmitButton {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 56px; }

#Slides .SubmitButton button {
  font-size: 1.2rem;
  border-radius: 0;
  width: 100%;
  height: 100%; }

@media screen and (max-width: 330px) {
  #SlidesCurrentStepLabel {
    font-size: 0.9rem;
    padding: 0.5rem 1.1rem; }
  #SlidesContent {
    bottom: 96px; }
  #SlidesActions {
    height: 46px;
    padding: 0 0.4em; } }

.ListItem + .ListItem {
  margin-top: 1.3em; }

.loan-preview-section:first-of-type {
  margin-top: -1rem; }

.loan-preview-section h3 {
  text-transform: uppercase;
  font-size: 1.14rem;
  margin-bottom: 0.4rem;
  letter-spacing: 0.07rem;
  color: #a4dfdb;
  color: #a8a8a8;
  font-weight: 100; }

.loan-preview-section ul {
  margin: 0;
  padding: 0; }

.loan-preview-section:not(:last-of-type) ul {
  margin-bottom: 2em; }

.loan-preview-section ul.loanInterestRate {
  margin-bottom: 1.3em; }

.loan-preview-section li {
  list-style: none;
  padding: 0.75rem 0;
  margin-bottom: 0.3rem; }

.loan-preview-section h5 {
  color: #e4e4e4;
  font-size: 1.45rem;
  line-height: 1.45rem;
  font-weight: normal;
  margin-bottom: 0.1rem; }

.loan-preview-section p {
  font-size: 1.25rem;
  line-height: 1.8rem;
  font-weight: 300;
  margin-bottom: 0;
  color: #bababa;
  margin-top: 0.3rem;
  letter-spacing: 0.02rem; }

.loan-preview-section .OutlineButton {
  text-transform: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.75rem;
  padding-right: 0.8rem;
  padding-bottom: 0.1rem;
  height: 35px;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.07rem;
  background: transparent;
  margin-right: -0.4rem;
  color: #aad5d2 !important;
  /* border: none !important; */
  background: transparent !important;
  border-color: #828d8c5e !important; }

.loan-preview-section ul + .OutlineButton {
  margin-top: 0.6rem;
  margin-bottom: 1.6rem; }

.loan-preview-section .OutlineButton svg {
  width: 14px;
  height: 14px;
  margin-right: 0.45rem;
  fill: currentColor;
  position: relative; }

#Profile .OutlineButtony {
  margin: 0.3rem 0;
  margin-right: 0.5rem;
  padding: 0.35rrem 0.8rrem;
  height: auto;
  border-width: 1px;
  font-size: 0.95rrem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.03rem;
  border-radius: 6px;
  border-width: 2px; }

@media all and (max-width: 330px) {
  .loan-preview-section {
    padding: 1.5rem 1.3rem;
    padding-bottom: 1rem; }
  .loan-preview-section h3 {
    font-size: 0.95rem; }
  .loan-preview-section h5 {
    font-size: 1.3rem;
    line-height: 1.45rem; }
  .loan-preview-section:first-of-type h5 {
    font-size: 1.65rem; }
  .loan-preview-section p {
    font-size: 1.15rem; }
  .loan-preview-section .OutlineButtony {
    padding: 0 0.85rem;
    padding-right: 1rem;
    height: 28px;
    min-width: 0;
    font-size: 12px;
    line-height: 1;
    margin-top: 0.5rem;
    margin-bottom: 0.8rem; }
  .loan-preview-section .OutlineButtony svg {
    width: 12px;
    height: 12px; } }

.RangeInput {
  position: relative;
  padding: 0 22px; }

.RangeInput [data-reach-slider-input][data-orientation="horizontal"] {
  width: 350px;
  max-width: 100%;
  height: 4px;
  padding-top: 0;
  padding-bottom: 20px; }

.RangeInput [data-reach-slider-track][data-orientation="horizontal"] {
  background: #444;
  box-shadow: inset -1px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 60px;
  height: 16px; }

.RangeInput [data-reach-slider-handle][aria-orientation="horizontal"] {
  margin-top: -1px;
  height: 22px;
  width: 22px;
  background: #d5d5d5;
  border-radius: 50%;
  border: none;
  box-shadow: 3px 3px 1px rgba(0, 0, 0, 0.1);
  outline: none; }

.RangeInput.animate-handle [data-reach-slider-handle][aria-orientation="horizontal"] {
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out; }

@media all and (max-width: 330px) {
  .RangeInput {
    padding: 0 18px; }
  .RangeInput [data-reach-slider-input][data-orientation="horizontal"] {
    height: 4px;
    padding-top: 0;
    padding-bottom: 16px; }
  .RangeInput [data-reach-slider-track][data-orientation="horizontal"] {
    border-radius: 60px;
    height: 10px; }
  .RangeInput [data-reach-slider-handle][aria-orientation="horizontal"] {
    margin-top: -1px;
    height: 16px;
    width: 16px; }
  .RangeInput.animate-handle [data-reach-slider-handle][aria-orientation="horizontal"] {
    -webkit-transition: 0.2s ease-out;
    transition: 0.2s ease-out; } }

#RequestLoan .finlink-modal-content {
  height: calc(100vh - 60px);
  padding: 0;
  overflow: hidden; }

#RequestLoan #RequestLoanContent {
  height: 100%; }

#RequestLoan .SaveFab {
  position: fixed;
  z-index: 90;
  bottom: 20px;
  right: 20px;
  top: auto; }

#RequestLoan .SaveFab svg {
  fill: none; }

#RequestLoan #RequestLoanContent .RequestLoanSection {
  padding: 1.2em;
  padding-top: 2.5em; }

#RequestLoan .loan-request-section-title {
  font-size: 1.86em;
  line-height: 1.5;
  letter-spacing: 0.01rem;
  font-weight: normal;
  margin-bottom: 2.5rem;
  color: #e8e8e8; }

#RequestLoan .loan-request-section-title small {
  font-size: 0.7em;
  color: #9d9d9d;
  letter-spacing: 0.07em; }

#loanGuarantorsSection .loan-request-section-title small {
  letter-spacing: 0.01em;
  display: block;
  margin-top: 0.3em;
  line-height: 1.7;
  color: #acacac;
  font-size: 0.7em; }

#RequestLoan .finlink-form-control:not(.focused).has-errors {
  border-bottom-color: #eda1a1; }

#RequestLoan .finlink-form-control.has-errors .bar:before,
#RequestLoan .finlink-form-control.has-errors .bar:after {
  background: #fd9c9c; }

#RequestLoan .finlink-form-control.focused label,
#RequestLoan .finlink-form-control.filled label,
#RequestLoan .finlink-form-control.has-prepend label,
#RequestLoan .finlink-form-control input[placeholder] ~ label {
  top: -24px; }

#loanAmountSection .finlink-form-control input {
  background: transparent !important;
  font-size: 2.6em;
  letter-spacing: 0.02em; }

#loanAmountSection .finlink-form-control input::-webkit-input-placeholder {
  font-size: 0.7em; }

#loanAmountSection .finlink-form-control input::-moz-placeholder {
  font-size: 0.7em; }

#loanAmountSection .finlink-form-control input::-ms-input-placeholder {
  font-size: 0.7em; }

#loanAmountSection .finlink-form-control input::placeholder {
  font-size: 0.7em; }

#loanAmountSection .finlink-form-control input:focus::-webkit-input-placeholder {
  opacity: 0; }

#loanAmountSection .finlink-form-control input:focus::-moz-placeholder {
  opacity: 0; }

#loanAmountSection .finlink-form-control input:focus::-ms-input-placeholder {
  opacity: 0; }

#loanAmountSection .finlink-form-control input:focus::placeholder {
  opacity: 0; }

#RequestLoan .finlink-radio-button-group-wrapper {
  margin-top: -10px; }

#RequestLoan .finlink-radio-button-group > label {
  margin-bottom: 8px; }

#RequestLoan .finlink-form-control-wrapper .form-control-error {
  font-size: 1rem;
  color: #fba4a4;
  letter-spacing: 0.01em;
  font-weight: 100;
  font-family: verdana; }

#loanGuarantorsSection .ListItem {
  margin-top: -0.7rem;
  border: 2px solid #5d5d5d;
  background: #4e4e4e; }

#loanGuarantorsSection .ListItem + .ListItem {
  margin-top: 1.3em;
  margin-bottom: -1em; }

#loanGuarantorsSection > .OutlineButton {
  margin-top: 1.5em;
  color: #aad5d2 !important;
  border-color: #828d8c !important; }

#RequestLoan #AddLoanGuarantor .loan-request-section-title {
  margin-top: -0.6rem;
  margin-bottom: 1.1rem; }

#AddLoanGuarantor .finlink-form-control-wrapper {
  margin-top: 0.8em;
  margin-bottom: 2em !important; }

#AddLoanGuarantor .finlink-form-control input {
  background: transparent !important;
  font-size: 2em;
  letter-spacing: 0.02em; }

#AddLoanGuarantor .finlink-form-control .prepend {
  font-size: 2em; }

#AddLoanGuarantor .finlink-form-control.has-prepend.filled {
  background: transparent;
  padding-left: 0; }

#AddLoanGuarantor .finlink-form-control input::-webkit-input-placeholder {
  opacity: 0.5; }

#AddLoanGuarantor .finlink-form-control input::-moz-placeholder {
  opacity: 0.5; }

#AddLoanGuarantor .finlink-form-control input::-ms-input-placeholder {
  opacity: 0.5; }

#AddLoanGuarantor .finlink-form-control input::placeholder {
  opacity: 0.5; }

#AddLoanGuarantor .RequestLoanSection > .finlink-btn.primary {
  font-size: 1.2rem;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: auto;
  border-radius: 0; }

.MoreGuarantorsAlert p {
  text-align: center;
  font-size: 1.3em;
  line-height: 1.6em;
  color: #fff;
  margin-bottom: 1em;
  letter-spacing: 0.03em; }

.MoreGuarantorsAlert .finlink-btn {
  margin: auto;
  font-size: 1.2em;
  text-transform: none;
  letter-spacing: 0.03em;
  padding: 0.8em 1.2em;
  border-radius: 5px; }

.MoreGuarantorsAlert .finlink-btn.primary {
  border: 2px solid #6a7878;
  background: #5c6161;
  color: #abfeff;
  text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.14); }

.MoreGuarantorsAlert .finlink-btn:not(.primary) {
  color: #d2d5d5; }

.MoreGuarantorsAlert .finlink-btn:not(.primary) span:after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: -2px;
  right: -2px;
  height: 1px;
  background: currentColor;
  opacity: 0.7; }

#LoanRequestRepaymentPlan .finlink-radio-button-group-wrapper {
  margin-top: -15px;
  margin-bottom: 2rem; }

#LoanRequestRepaymentPlan .finlink-radio-button {
  margin-right: 1rem; }

#LoanRequestRepaymentPlan .finlink-radio-button-group > label {
  text-transform: none;
  font-size: 1.4rem;
  line-height: 1.65em;
  letter-spacing: 0.03em;
  margin-bottom: 1rem; }

#installmentCount > div {
  position: relative;
  background: #4e4d4d;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 1rem 0;
  padding-top: 0.5rem; }

#installmentCount > div:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.07);
  z-index: -1; }

#installmentCount > * {
  position: relative;
  z-index: 1; }

#installmentCount > h3 {
  max-width: 90%;
  text-align: center;
  color: #d0d0d0;
  font-size: 1.6em;
  line-height: 1.6;
  letter-spacing: 0.01rem;
  font-weight: normal;
  margin: 0 auto;
  margin-bottom: 1.1rem; }

#installmentCount .RangeInput {
  margin-top: 1.3rem;
  margin-bottom: 0.4rem; }

#installmentCount .RangeInput [data-reach-slider-handle][aria-orientation="horizontal"] {
  background: #aaa; }

#installmentCount .RangeInput [data-reach-slider-track][data-orientation="horizontal"] {
  background: rgba(0, 0, 0, 0.1); }

#installmentCount #installmentCountNumber {
  margin-right: 1rem;
  margin-top: 0.7rem; }

#installmentCount #installmentCountNumber h3 {
  text-align: center;
  color: #ccc;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  line-height: 1.65em;
  letter-spacing: 0.01em;
  font-size: 1.85rem;
  margin: 0 3rem; }

#installmentCount #installmentCountNumber button {
  font-size: 1.85rem;
  color: #888;
  width: 50px;
  height: 36px;
  padding: 0;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none; }

#installmentCount #installmentCountNumber button:active {
  -webkit-transform: scale(0.8);
          transform: scale(0.8); }

#installmentCount #installmentCountNumber button:disabled {
  opacity: 0.25;
  pointer-events: none; }

#installmentCount #installmentCountNumber button svg {
  height: 36px;
  width: 36px; }

#LoanRequestRepaymentPlan .finlink-radio-button-group.has-errors > label,
#LoanRequestRepaymentPlan .finlink-radio-button-group-wrapper .form-control-error {
  color: #fba4a4; }

@media all and (max-width: 330px) {
  #RequestLoan #RequestLoanContent .RequestLoanSection {
    padding-top: 1.8em; }
  #RequestLoan .loan-request-section-title {
    font-size: 1.4em;
    margin-bottom: 1.8rem; }
  #LoanRequestRepaymentPlan .finlink-radio-button-group-wrapper {
    margin-bottom: 1rem; }
  #LoanRequestRepaymentPlan .finlink-radio-button-group > label {
    font-size: 1.15rem;
    line-height: 1.4em;
    letter-spacing: 0.01em;
    margin-bottom: 0.7rem; }
  #LoanRequestRepaymentPlan .finlink-radio-button-group-wrapper:last-of-type .finlink-radio-button label {
    font-size: 1em;
    padding: 0.3em 0.6em;
    padding-right: 0.7em; }
  #loanAmountSection .finlink-form-control input {
    font-size: 2.2em; }
  #AddLoanGuarantor .finlink-form-control .prepend,
  #AddLoanGuarantor .finlink-form-control input {
    font-size: 1.45em; }
  #AddLoanGuarantor .RequestLoanSection > .finlink-btn.primary {
    font-size: 1rem; }
  .OutlineButton span svg {
    height: 20px;
    width: 20px; }
  #installmentCount > h3 {
    font-size: 1.2rem;
    line-height: 1.65em;
    margin-bottom: 0.8rem; }
  #installmentCount > div {
    background: #4e4d4d;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    padding: 1rem 0;
    padding-top: 0.4rem; }
  #installmentCount #installmentCountNumber {
    margin-right: 0.8rem;
    margin-top: 0.5rem; }
  #installmentCount #installmentCountNumber h3 {
    letter-spacing: 0.01em;
    font-size: 1.3rem;
    margin: 0 1.7rem; }
  #installmentCount #installmentCountNumber button {
    width: 45px;
    height: 26px; }
  #installmentCount #installmentCountNumber button svg {
    height: 26px;
    width: 26px; } }

#LoanRequests {
  overflow: hidden; }

#LoanRequestContent {
  padding: 1rem 1.2rem;
  overflow-y: auto; }

.loanRequestsScreenMessage {
  color: #aaa;
  font-size: 1.3rem;
  padding: 0.5rem 0;
  text-align: center; }

.loan-request-item {
  background: #424241;
  border: 2px solid #717070;
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 0.85rem; }

.loan-request-item h3 {
  font-size: 1.36rem;
  color: #fff;
  letter-spacing: 0.03em;
  margin-bottom: 0.5rem; }

.loan-request-item h3 small {
  font-size: 0.7em;
  margin-right: 0.4rem;
  margin-bottom: 0.13rem; }

.loan-request-item h5 {
  font-size: 1.15rem;
  line-height: 1.55em;
  color: #bfbfbf;
  letter-spacing: 0.015em; }

.loan-request-item span {
  top: 1.57rem;
  right: 0.9rem;
  font-size: 0.8rem;
  letter-spacing: 0.12em;
  padding: 0.3rem 0.7rem;
  padding-bottom: 0.5rem;
  color: #c8c8c8;
  border: 1px solid #555;
  border: 1px solid #999;
  border-radius: 20px; }

.loan-request-item.rejected span {
  color: #ffafaf; }

.loan-request-item.approved span {
  color: #95e6c4; }

.loan-request-item span small {
  font-size: 2.3rem;
  line-height: 0;
  margin-right: 0.1rem;
  margin-top: 0.07rem; }

@media all and (max-width: 330px) {
  #LoanRequestContent {
    padding: 0.8rem; }
  .loan-request-item {
    padding: 1rem;
    margin-bottom: 0.85rem; }
  .loan-request-item h3 {
    font-size: 1.2rem;
    margin-bottom: 0.45rem; }
  .loan-request-item h3 small {
    font-size: 0.7em;
    margin-bottom: 0.05rem; }
  .loan-request-item h5 {
    font-size: 1rem;
    line-height: 1.45em; }
  .loan-request-item span {
    top: 0.9rem;
    right: 0.6rem;
    font-size: 0.6rem;
    color: #aaa;
    border: 1px solid #777; }
  .loan-request-item span small {
    display: none; } }

#GuaranteeDetail {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: #383837;
  overflow: hidden; }

#GuaranteeDetailContent {
  overflow-y: auto; }

.guarantee-request-details {
  padding: 0.8rem 1.2rem; }

#guaranteeSummary {
  background: #4a4a49;
  padding: 1.6rem;
  margin-top: 1rem;
  margin-bottom: 1.4rem;
  border-radius: 8px; }

.detail-text {
  margin-bottom: 1.6rem; }

#guaranteeSummary .detail-text:last-of-type {
  margin-bottom: 0; }

.detail-text label {
  color: #bbb;
  font-size: 0.86em;
  display: block;
  margin-bottom: 0.35rem;
  letter-spacing: 0.03em;
  text-transform: uppercase; }

.detail-text strong {
  font-size: 1.1rem;
  line-height: 1.55em;
  color: #eee;
  letter-spacing: 0.05em; }

#guaranteeSummary .detail-text strong {
  display: block;
  margin-top: 0.4rem;
  font-size: 1.35rem; }

.detail-text strong.pending-amount {
  font-size: 2rem;
  font-size: 1.45rem; }

.detail-text strong small {
  font-weight: normal;
  font-size: 0.75em;
  margin-right: 0.4rem;
  margin-bottom: 0.13rem; }

#noGuarantorsMessage {
  font-size: 1rem;
  margin-top: 1rem;
  padding: 1.2rem;
  background: #404040;
  color: #afafaf; }

.guarantor-item:first-of-type {
  margin-top: 1.3rem; }

.guarantor-item {
  margin-bottom: 0.8rem;
  color: #5d5a58; }

.guarantor-item .ListItem {
  text-align: left;
  display: flex;
  padding: 0.8rem;
  width: 100%;
  border: 1px solid #5d5d5a;
  border-radius: 6px;
  background: #444443;
  outline: none;
  -webkit-tap-highlight-color: transparent; }

.ListItem .icon, .ListItem .image {
  width: 36px;
  height: 36px;
  margin-right: 0.6rem; }

.guarantor-item .ListItem h5 {
  color: #c6c6c6;
  font-size: 1.15rem; }

.guarantor-item .ListItem h5 span {
  color: #d6d6d6;
  margin-left: auto; }

#loanGuaranteeActions {
  padding: 1rem 1.2rem;
  background-color: #4d4d4d; }

#loanGuaranteeActions .finlink-btn.rounded {
  min-width: 150px;
  width: 48%;
  padding-top: 8px;
  padding-bottom: 10px;
  letter-spacing: 0.06rem;
  font-weight: bold; }

#loanGuaranteeActions .finlink-btn.rounded:not(.primary):not(.reject) {
  color: #d9d9d9;
  border-color: #757575; }

#loanGuaranteeActions .finlink-btn.rounded.reject {
  color: #ffa2a2;
  border-color: rgba(255, 157, 157, 0.61);
  background-color: rgba(232, 153, 153, 0.1); }

#loanGuaranteeActions .finlink-btn.rounded.primary {
  color: #000; }

#loanGuaranteeActions .finlink-btn.disabled {
  opacity: 0.85; }

@media all and (max-width: 330px) {
  .guarantee-request-details {
    padding: 1rem 1.2rem;
    margin-bottom: 0.3rem; }
  .guarantee-request-details h3 {
    font-size: 1.2rem;
    margin-bottom: 0.4rem; }
  .guarantee-request-details h5 {
    font-size: 1.2rem;
    line-height: 1.55em;
    margin-bottom: 0.7rem; }
  .guarantee-request-details h5 small {
    font-size: 0.75em; }
  .guarantee-request-details span {
    font-size: 0.7rem;
    padding: 0.35rem 0.7rem;
    padding-top: 0.2rem;
    margin-bottom: 0.9rem; }
  .detail-text {
    font-size: 0.9rem; }
  #GuaranteeProgressTitle {
    font-size: 1rem; }
  #GuaranteeTimeLine {
    border-color: #5f5f5f; }
  .guarantor-item {
    padding-left: 2.6rem;
    padding-right: 0.7rem;
    margin: 1.5rem 0; }
  .guarantor-item:before {
    content: "";
    position: absolute;
    top: -97px;
    height: 100%;
    left: 1.2rem;
    border-left: 3px dashed currentColor; }
  .guarantor-item:after {
    top: 4px;
    left: 14px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: currentColor; }
  .guarantor-item .ListItem {
    border-width: 1px;
    background: #474645; } }

#GuarantorPool {
  overflow: hidden; }

#GuarantorPool.balance-set .mainFragment {
  top: 65px; }

#GuarantorPoolContent {
  padding: 1.3em 1em;
  overflow-y: auto; }

#GuarantorPoolContent > h5 {
  color: #ddd;
  margin-top: 0.6rem;
  margin-bottom: 1.2rem;
  font-size: 1.13rem;
  letter-spacing: 0.05em;
  font-weight: normal; }

.guaranteeRequestsScreenMessage {
  color: #aaa;
  font-size: 1.3rem;
  padding: 0.5rem 0;
  text-align: center; }

#GuarantorPoolContent > h5:not(:first-child) {
  margin-top: 2.3rem; }

#GuarantorPool.balance-set > .ot-header .top-bar {
  padding-bottom: 28px; }

#GuarantorPool.balance-set > .ot-header,
#GuarantorPool.balance-set > .ot-header .top-bar {
  min-height: 66px; }

#GuarantorPool > .ot-header .top-bar .pos-a {
  font-size: 0.88rem;
  font-weight: 100;
  bottom: -23px; }

#GuarantorPoolContent .loan-request-item {
  width: 100%;
  padding-top: 1.3rem;
  margin-bottom: 1.1rem;
  border-radius: 6px; }

#GuarantorPoolContent .loan-request-item span.pos-a {
  border: none;
  padding: 0; }

.guarantor-request-item {
  padding: 1.6rem; }

.guarantor-request-item:not(:last-child) {
  border-bottom: 1px solid #565555; }

.guarantor-request-item h3 {
  font-size: 1.36rem;
  color: #dfdfdf;
  letter-spacing: 0.03em;
  margin-bottom: 0.8rem; }

.guarantor-request-item h3 small {
  font-size: 0.7em;
  margin-right: 0.4rem; }

.guarantor-request-item h5 {
  font-size: 1.15rem;
  line-height: 1.55em;
  color: #bbb;
  font-weight: normal;
  letter-spacing: 0.015em; }

.guarantor-request-item .pos-a {
  top: .45rem;
  right: 0.6rem; }

.guarantor-request-item .finlink-btn.flat.rounded {
  font-size: 0.9rem;
  letter-spacing: 0.1em;
  padding: 0.5rem 0.85rem;
  padding-top: 0.4rem;
  color: #95e6c4;
  border: 1px solid #6e8f7f;
  background: #404340;
  margin-top: 1.1rem;
  text-transform: none; }

.guarantor-request-item .finlink-btn.flat.rounded small {
  font-size: 2.3rem;
  line-height: 0;
  margin-right: 0.1rem;
  margin-top: 0.07rem; }

#EnterGuaranteeAmount .loan-request-section-title {
  text-align: center;
  font-size: 1.5em;
  line-height: 1.6em;
  letter-spacing: 0.01rem;
  font-weight: normal;
  margin-bottom: 1.2rem;
  color: #e8e8e8; }

#EnterGuaranteeAmount .finlink-form-control input {
  background: transparent !important;
  font-size: 2.3em;
  letter-spacing: 0.02em; }

#EnterGuaranteeAmount .finlink-form-control input::-webkit-input-placeholder {
  font-size: 0.7em; }

#EnterGuaranteeAmount .finlink-form-control input::-moz-placeholder {
  font-size: 0.7em; }

#EnterGuaranteeAmount .finlink-form-control input::-ms-input-placeholder {
  font-size: 0.7em; }

#EnterGuaranteeAmount .finlink-form-control input::placeholder {
  font-size: 0.7em; }

#EnterGuaranteeAmount .finlink-form-control input:focus::-webkit-input-placeholder {
  opacity: 0; }

#EnterGuaranteeAmount .finlink-form-control input:focus::-moz-placeholder {
  opacity: 0; }

#EnterGuaranteeAmount .finlink-form-control input:focus::-ms-input-placeholder {
  opacity: 0; }

#EnterGuaranteeAmount .finlink-form-control input:focus::placeholder {
  opacity: 0; }

#EnterGuaranteeAmount .finlink-form-control-wrapper .form-control-error {
  text-align: center;
  list-style: none;
  padding: 0.4rem 0; }

#EnterGuaranteeAmount form > p {
  font-size: 1.25rem;
  margin-top: -1.3rem;
  letter-spacing: 0.02em;
  color: #999; }

#EnterGuaranteeAmount form > p#availableBalance {
  margin-top: 0.2rem;
  margin-bottom: 1rem; }

#EnterGuaranteeAmount .finlink-btn.primary {
  font-size: 1.1rem;
  border-radius: 0; }

@media all and (max-width: 330px) {
  .guarantor-request-item {
    padding: 1.3rem 1rem;
    border-color: #4a4a4a !important; }
  .guarantor-request-item h3 {
    font-size: 1.1rem;
    margin-bottom: 0.45rem; }
  .guarantor-request-item h3 small {
    font-size: 0.7em;
    margin-bottom: 0.05rem; }
  .guarantor-request-item h5 {
    font-size: 0.85rem;
    line-height: 1.45em; }
  .guarantor-request-item .pos-a {
    top: 0.17rem;
    right: 0.6rem; }
  .guarantor-request-item .finlink-btn.flat.rounded {
    font-size: 0.7rem;
    padding: 0.4rem 0.65rem;
    padding-top: 0.25rem; } }

.mainScene {
  background: #393837;
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; }

.mainFragment {
  position: fixed;
  top: 59px;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto; }

.homeScene {
  padding-bottom: 60px;
  top: 50px; }

.mainFragment:not(.homeScene),
.mainScene:not(.at-home) .ot-header {
  z-index: 20; }

.mainFragment:not(.homeScene) {
  background-color: #393837; }

@media all and (max-width: 400px) {
  .mainFragment {
    top: 52px; } }

@media all and (max-width: 330px) {
  .mainFragment {
    top: 47px; } }

